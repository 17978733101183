import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

import store, { persistor } from "modules/store";
import history from "./modules/history";
import App from "App";
import * as serviceWorker from "serviceWorker";

import { PersistGate } from "redux-persist/integration/react";
import { app } from "modules/notification";

import { checkVersion } from "helpers/version-control";
import "suneditor/dist/css/suneditor.min.css";
import "suneditor/src/assets/css/suneditor-contents.css";

checkVersion(store.getState().userReducer.token);

try {
  if (!process.env.REACT_APP_SUPPORT_ANALYTICS || process.env.REACT_APP_SUPPORT_ANALYTICS.toUpperCase() === "NO") {
    app.analytics().setAnalyticsCollectionEnabled(false);
  }
} catch (_) {
  console.log(app);
}

history.listen((...args) => {
  window.scrollTo(0, 0);

  try {
    if (process.env.REACT_APP_SUPPORT_ANALYTICS && process.env.REACT_APP_SUPPORT_ANALYTICS.toUpperCase() === "YES") {
      const {
        userReducer: {
          userInfo: { username, fullName, email, roleName },
        },
      } = store.getState();

      app.analytics().logEvent("CHANGE_ROUTE", {
        pathname: args[0].pathname,
        username: username || "none",
        fullName: fullName || "none",
        roleName,
        email,
      });

      const page_path = window.location.pathname + window.location.search;
      app.analytics().setCurrentScreen(page_path);
      app.analytics().logEvent("page_view", { page_path });
    }
  } catch (error) {
    console.error(error);
  }
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<div className="lazy-loader" />}>
        <Router history={history}>
          <App />
        </Router>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
export default store;
