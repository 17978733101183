import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Check } from "../../assets/check.svg";
import { UserIcon } from "components/common/icons-primary";
import { textToDot, muliFont, flexCenter, borderBox, tooltipStyle } from "components/common/mixins";

import Api from "../../helpers/api";

export const DealTypeCard = styled.div`
  width: 100%;
  left: 352px;
  top: 137px;
  padding: 25px 35px 45px 45px;
  background: #fafafa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-bottom: 30px;
  box-sizing: border-box;
  @media (max-width: 1600px) {
    padding: 25px 20px 30px 20px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin = "0 0 55px 0" }) => margin};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
`;

export const Table = styled.table`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  border: none;
  table-layout: fixed;
`;

export const TableHeadingRow = styled.tr`
  border-bottom: 1px solid #747474;
`;

export const TdHeading = styled.td`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  font-family: Roboto;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #747474;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: ${({ noBorder }) => (noBorder ? "none" : "1px solid #747474")};
  padding: ${({ padding = "10px 0" }) => padding};
  text-align: ${({ left, right }) => (left ? "left" : right ? "right" : "center")};
  @media (max-width: 1600px) {
    font-size: 12px;
  }
`;

export const TableRow = styled.tr`
  border-bottom: 0.5px solid #747474;
  height: 62px !important;
  background: ${({ background = "transparent" }) => background};
`;

export const TD = styled.td`
  height: 62px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  padding: ${({ noPadding, first }) => {
    if (noPadding) {
      return "0";
    } else {
      return first ? "0 20px 0 8px" : "0 20px 0 0";
    }
  }};
  overflow: hidden;
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  color: #747474;
  background: ${({ background = "transparent" }) => background};
  text-align: ${({ left, right }) => {
    if (left) return "left";
    else if (right) return "right";
    else return "center";
  }};
  a {
    text-decoration: none;
  }
  @media (max-width: 1600px) {
    font-size: 12px;
  }
`;

export const Tbody = styled.tbody``;

export const LogoContainer = styled.div`
  width: 80px;
  height: 50px;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  color: ${({ theme: { primary } }) => primary};
  > div {
    margin: auto;
  }
`;

export const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TableHeader = styled.div`
  height: 60px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
`;

export const TransactionsHeader = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "50px")};
  background: ${({ grey, theme: { adminTableRowGrey } }) => (grey ? adminTableRowGrey : "#ffffff")};
  justify-content: space-between;
  display: flex;
  align-items: center;
  transform: ${({ translate }) => (translate ? `translateX(${translate}px)` : "none")};
  padding-right: ${({ paddingR }) => paddingR};
  width: ${({ width }) => width};
  box-sizing: border-box;
  border-radius: 2px;
  ${({ hasHover = false, theme: { primary } }) =>
    hasHover
      ? `border: 1px solid transparent;
  transition: border-color 0.1s linear;
  &:hover {
    border-color: ${primary};
  }`
      : ""};
`;

export const ScrollContainer = styled.div`
  height: ${({ fullHeight }) => (fullHeight ? "fit-content" : "250px")};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 0)};
  padding-right: ${({ noMargin, isScroll, pr }) => (noMargin ? "10px" : isScroll ? "25px" : pr ? pr : "")};
  box-sizing: border-box;
  margin-left: ${({ noTranslate, noMargin }) => (noTranslate || noMargin ? 0 : "-15px")};
  width: ${({ noMargin = false }) => (noMargin ? "100%" : "calc(100% + 15px)")};
  overflow-y: ${({ isScroll = true }) => (isScroll ? "scroll" : "hidden")};
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const HeaderTD = styled.div`
  transform: ${({ translate }) => (translate ? `translateX(${translate}px)` : "none")};
  display: flex;
  align-items: center;
  justify-content: ${({ justify = "flex-start" }) => justify};

  font-style: normal;
  font-weight: bold;
  font-size: ${({ fz = "10px" }) => fz};
  line-height: 135%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  box-sizing: ${({ boxSizing }) => boxSizing};
  padding: ${({ padding }) => (padding ? padding : "0")};
  color: ${({ blue, theme: { primary } }) => (blue ? primary : "rgba(24, 24, 24, 0.4)")};
  cursor: ${({ blue, pointer }) => (blue || pointer ? "pointer" : "")};
  width: ${({ width }) => (width ? width : "10%")};
  white-space: nowrap;
  > svg {
    transition: transform 0.5s ease;
    transform: ${({ turn }) => (turn ? "rotate(0.5turn)" : "none")};
    path {
      stroke: ${({ theme: { primary }, blue }) => (blue ? primary : "rgba(24,24,24,0.4)")};
    }
  }
`;

export const TransactionsTD = styled.div`
  text-overflow: ellipsis;
  box-sizing: ${({ boxSizing }) => boxSizing};

  font-style: normal;
  font-weight: ${({ fWeight }) => (fWeight ? fWeight : "normal")};
  font-size: 12px;
  line-height: 150%;
  letter-spacing: ${({ blue }) => (blue ? "-0.02" : "0.01em")};
  white-space: nowrap;
  overflow: hidden;
  overflow: ${({ overflow }) => (overflow ? overflow : "hidden")};
  cursor: ${({ cursor }) => cursor};
  text-transform: ${({ lower }) => (lower ? "none" : "uppercase")};
  padding: ${({ padding }) => (padding ? padding : "0 5px 0 5px")};
  color: ${({ blue, theme: { primary } }) => (blue ? primary : "#333333")};
  width: ${({ width }) => (width ? width : "11%")};
  border-bottom: ${({ borderBottom }) => (borderBottom ? "0.5px solid rgba(98, 128, 218, 0.2); " : "none")};
`;

export const EmailTD = styled.div`
  text-overflow: ellipsis;
  box-sizing: ${({ boxSizing }) => boxSizing};

  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
  white-space: nowrap;
  overflow: hidden;
  padding: ${({ padding = false }) => (padding ? padding : "0 25px 0 0")};
  width: ${({ width }) => (width ? width : "11%")};
`;

export const Stage = styled.div`
  display: flex;
  ${flexCenter}
  ${borderBox}
  height: 24px;
  border-radius: 2px;
  padding: 0 10px;
  > span {
    display: block;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    color: #ffffff;
    width: 100%;
    ${textToDot}
  }

  max-width: 100px;
  background: ${({ theme: { secondary } }) => secondary};
`;

export const DealTD = styled.div`
  display: ${({ block }) => (block ? "block" : "flex")};
  align-items: ${({ center }) => (center ? "center" : "flex-start")};
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};

  font-style: normal;
  flex-direction: column;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  font-weight: ${({ bold, medium }) => (bold ? "bold" : medium ? "600" : "normal")};
  color: ${({ bold, medium }) => (bold ? "#333333" : medium ? "#181818" : "#494D5B")};
  text-transform: ${({ lower }) => (lower ? "none" : "uppercase")};
  cursor: ${({ blue }) => (blue ? "pointer" : "")};
  width: ${({ width }) => (width ? width : "10%")};
  ${textToDot}
  box-sizing: border-box;
  padding-right: ${({ block }) => (block ? "20px" : "0")};
  cursor: ${({ pointer = false }) => (pointer ? "pointer" : "normal")};
  a {
    text-decoration: none;
  }
`;

export const DealBody = styled.div`
  height: 90px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  border-left: ${({ publish }) => (publish ? "1px solid #1F263E" : "1px solid transparent")};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  width: ${({ isUnfinished = false }) => (isUnfinished ? "243px" : "343px")};
  box-sizing: border-box;
  > a {
    text-decoration: none;
    margin-left: 15px;
  }
`;

export const AssetsTabsWrapper = styled.div`
  transform: translateY(-30px);
  border-top: 1px solid #f6f7fc;
  height: ${({ h = "83" }) => `${h}px`};
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 48px 0 48px;
  width: 100%;
  a:last-child {
    text-decoration: none;
    margin-left: auto;
  }

  position: relative;
`;

export const BottomHr = styled.div`
  width: ${({ w = 0 }) => w}px;
  height: 1px;
  background: ${({ w, left, theme: { primary } }) => (w && left ? primary : "transparent")};
  bottom: 0px;
  left: ${({ left = 0 }) => left}px;
  position: absolute;
  transition: all 0.2s linear;
`;

export const AssetsTabs = (props) => {
  const wrapperRef = useRef(null);
  const [toLeft, setToLeft] = useState(0);
  const [toWidth, setToWidth] = useState(0);

  const onChangeTab = async () => {
    try {
      await new Promise((r) => setTimeout(r, 0));
      const mainWrapper = wrapperRef.current.getBoundingClientRect();

      const { width, left } = document.querySelector("[user-tabs][active='1']").getBoundingClientRect();

      setToWidth(width);
      setToLeft(left - mainWrapper.left);
    } catch (_) {
      console.log(_);
    }
  };

  useEffect(() => {
    onChangeTab();
  }, [window.location.pathname]);

  return (
    <AssetsTabsWrapper ref={wrapperRef} onClick={onChangeTab} h={props.h}>
      {props.children}
      <BottomHr left={toLeft} w={toWidth} />
    </AssetsTabsWrapper>
  );
};

export const AssetTypeTab = styled(Link).attrs(({ active }) => ({
  active,
  "user-tabs": 1,
}))`
  width: auto;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  ${muliFont}
  cursor: pointer;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.4)")};
  > svg {
    width: 28px;
    height: 28px;
    margin-right: 15px;
    path {
      stroke: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.4)")};
    }
  }
  transition: all 0.3s linear;
  margin-right: ${({ mr = "10" }) => `${mr}px`};
  &:last-child {
    margin-right: 0;
  }
`;

const Doc = styled.span`
  font-style: normal;
  font-weight: ${({ all }) => (all ? "bold" : "normal")};
  font-size: 12px;
  text-transform: none;
  color: #333333;
`;

const DottedDoc = styled(Doc)`
  display: block;
  width: 175px;
  ${textToDot}
`;

const DocumentsBlock = styled.div`
  position: relative;
  width: 175px;
  ${tooltipStyle}
`;

export const Circle = styled.div`
  width: ${({ w = "16px" }) => w};
  height: ${({ h = "16px" }) => h};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bg, theme: { primary } }) => (primary ? primary : bg)};
  margin-left: ${({ ml = "8px" }) => ml};
  margin-right: ${({ mr = 0 }) => mr};
`;

const DocsRow = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ link = false }) => (link ? "pointer" : "normal")};
  > svg {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 6px;
    transform: translateY(1px);
  }
`;

export const SignedDocuments = ({ docs = [], typesList = [] }) => {
  const types = typesList.filter((t) => t !== '"All Types"');
  if ((docs.length === 1 && docs[0] === "ALL_TYPES") || types.every((d) => docs.includes(d))) {
    return (
      <DocsRow>
        <Doc all>All Documents Signed</Doc>
        <Circle bg="#019D52">
          <Check />
        </Circle>
      </DocsRow>
    );
  } else {
    return <Doc>{[...new Set(docs)].join(", ")}</Doc>;
  }
};

export const UserName = styled.div`
  font-weight: ${({ fw = "bold" }) => fw};
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: ${({ theme: { primary } }) => primary};
  text-transform: none;
  border-bottom: ${({ border }) => (border ? "0.5px solid rgba(98, 128, 218, 0.2)" : "none")};
  padding-bottom: ${({ border }) => (border ? "2px" : "0")};
  margin: ${({ margin }) => margin};
`;

const Avatar = styled.img`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
`;

export const User = ({ token, userName, logo, border, onClick, staticLogo }) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && logo && token) {
      const { fileNameWithExtension, secureHash } = logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => setAvatar(blob || Api.defaultAvatar));
    }
  });
  return (
    <DocsRow link onClick={onClick}>
      {staticLogo ? (
        <Avatar src={staticLogo} alt="staticLogo" />
      ) : avatar && userName ? (
        <Avatar src={avatar} alt="avatar" />
      ) : !logo && userName ? (
        <UserIcon />
      ) : null}
      <UserName border={border}>{userName}</UserName>
    </DocsRow>
  );
};

export const DealStatus = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  margin-top: 6px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ draft }) => (draft ? "#3F4B78" : "rgba(24, 24, 24, 0.6)")};
`;
