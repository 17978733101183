import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { shadeColor } from "helpers/increseBrightnes";
import { valkButton } from "components/common/mixins";

export const Button = styled.button`
  line-height: normal;
  background: ${({ bg = "#2b56f5" }) => bg};
  ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "")}
  &:focus {
    background: ${({ bg = "#2b56f5" }) => bg};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid ${({ bg = "#2b56f5", borderColor = "" }) => borderColor || bg};
  color: ${({ color = "#fff" }) => color};
  border-radius: 4px;
  line-height: 18px;
  font-size: ${({ fontSize = "14px" }) => fontSize};
  ${({ noShadow = false }) => {
    return noShadow ? "" : "box-shadow: 0px 2px 18px 2px rgba(43, 86, 245, 0.25);";
  }}
  min-width: 115px;
  height: 30px;
  ${({ margin = "" }) => (margin ? `margin: ${margin};` : "")}
`;

export const ButtonImg = styled.img`
  width: ${({ width = "100%" }) => width};
  ${({ height }) => (height ? `height: ${height};` : "")};
  display: block;
  object-fit: cover;
`;

export const UploadImg = ({ load, ...props }) => {
  const [base64, setBase64] = useState("");
  useEffect(() => {
    (async () => {
      if (load) setBase64(await load());
    })();
  }, []);

  return <ButtonImg src={base64} {...props} />;
};

export const ButtonFitWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  user-select: none;
`;

export const Btn = styled.button`
  ${valkButton}
  text-transform: ${({ low }) => (low ? "none" : "uppercase")};
  &:disabled {
    background: #e3e3e3 !important;
    border: 1px solid #e3e3e3;
    color: white !important;
    &::before {
      background: #e3e3e3 !important;
    }
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  ${({ extraInCard = false, theme: { primary, navLinkActive } }) => {
    if (extraInCard) {
      return `
      background: transparent;
      color:${primary};
      :hover{
        background:${primary};
        color:${navLinkActive};
      }
      `;
    } else return "";
  }}
  //states
  &:hover {
    &::before {
      display: ${({ hideBorder = false }) => (hideBorder ? "none" : "block")};
    }
  }
  &:active {
    ${({ black, hide, error, green, red, fullGreen, grey, theme: { primary } }) => {
      const activeColor = black
        ? shadeColor("#20263C", -15)
        : grey
        ? shadeColor("#8A8A8A", -15)
        : fullGreen
        ? shadeColor("#019D52", -15)
        : hide
        ? shadeColor("#CACACA", -15)
        : red
        ? shadeColor("#FF4343", -15)
        : error
        ? shadeColor("#FF4343", -15)
        : green
        ? shadeColor("#55B17C", -15)
        : shadeColor(primary, -15);
      return `background: ${activeColor};border: 1px solid ${activeColor};color:#fff;`;
    }};
    &::before {
      display: none;
    }
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &::before {
    transition: all 0.3s linear;
    content: "";
    position: absolute;
    display: none;
    bottom: -1px;
    left: -1px;
    height: 3px;
    background: ${({ black, hide, error, green, red, fullGreen, grey, theme: { primary } }) =>
      black
        ? "#20263C"
        : grey
        ? "#8A8A8A"
        : fullGreen
        ? "#019D52"
        : hide
        ? "#CACACA"
        : red
        ? "#FF4343"
        : error
        ? "#FF4343"
        : green
        ? "#55B17C"
        : primary};
    width: calc(100% + 2px);
    filter: brightness(0.85);
    border-radius: 0 0 4px 4px;
  }
`;

export const VarButton = styled.div`
  width: 120px;
  height: 42px;
  background: ${({ theme: { disabledField } }) => disabledField};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  box-sizing: border-box;
  margin-right: 12px;
`;

export const SwitcherButton = styled.button`
  position: relative;
  white-space: nowrap;
  width: ${({ width }) => width};
  border: none;
  height: ${({ height }) => height};
  border-radius: ${({ br = "2px" }) => br};
  background: ${({ active, theme: { primary, emptyCardBg } }) => (active ? primary : emptyCardBg)};
  color: ${({ active }) => (active ? "#fff" : "#9EA2A9")};
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: ${({ jc = "center" }) => jc};
  cursor: pointer;
  margin: ${({ margin }) => margin};
  transition: all 0.2s linear;
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
`;

export const SmallButton = styled(SwitcherButton)`
  width: ${({ width = "28px" }) => width};
  height: ${({ height = "28px" }) => height};
  background: ${({ transparent, theme: { primary } }) => (transparent ? "transparent" : primary)};
  border: 1px solid ${({ theme: { primary } }) => primary};
  > svg {
    width: ${({ svgWidth = "14px" }) => svgWidth};
    height: ${({ svgHeight = "14px" }) => svgHeight};
    transform: ${({ scaleSvg }) => (scaleSvg ? "scale(2)" : "none")};
    path {
      stroke: ${({ transparent, theme: { primary } }) => (transparent ? primary : "#fff")};
    }
  }
  &:disabled {
    background: #e3e3e3 !important;
    border: 1px solid #e3e3e3;
    color: white !important;
    &::before {
      background: #e3e3e3 !important;
    }
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  @media (max-width: 768px) {
    > svg {
      min-height: 14px;
      min-width: 14px;
    }
  }
`;

export const CircleBtn = styled.button`
  width: ${({ size = 32 }) => `${size}px`};
  height: ${({ size = 32 }) => `${size}px`};
  background: transparent;
  border: 1px solid ${({ theme: { primary } }) => primary};
  margin: ${({ margin = 0 }) => margin};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: all 0.1s linear;
  cursor: pointer;
  > svg {
    width: ${({ size = 32 }) => `${size / 2}px`};
    height: ${({ size = 32 }) => `${size / 2}px`};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  &:hover {
    background: ${({ theme: { primary } }) => primary};
    > svg {
      transition: inherit;
      path {
        stroke: #fff;
      }
    }
  }
`;

export const AccessBtn = styled(Btn)`
  border: 1px solid #fff;
  background: inherit;
`;
