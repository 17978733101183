import * as data from "./dataAction";
import { handleActions } from "../../helpers/immer";

function initState() {
  return {
    assets: [],
    trades: [],
    waitData: false,
    soldAsset: null,
    soldTrade: null,
    orderHistory: null,
    redirectInfo: null,
    kycInfo: null,
    signData: null,
    investData: null,
    balance: null,
    documents: null,
    viewQA: null,
    viewQAInv: null,
    dataQA: {},
    dataQAInv: {},
    events: [],
    todayEventsCount: 0,
    eventsLoaded: false,
    AMSettings: {},
    adminNotifications: {
      Dashboard: 0,
      Assets: 0,
      Users: 0,
      KYC: 0,
      Connect: null,
      "Q&A": 0,
      Workflows: null,
    },
    investorNotifications: {
      Offerings: 0,
      Transactions: 0,
      Documents: 0,
      Wallet: 0,
      DataRoom: 0,
      "Q&A": 0,
    },
    QACount: 0,
    sponsorsDetails: {},
    dealsSectors: ["All Sectors"],
    dealsLocations: ["Any Location"],
    fileInfo: null,
    dashboardTxs: [],
    waitTxs: true,
    qiuckViewList: null,
    qiuckViewSettings: null,
  };
}

export const dataReducer = handleActions(
  {
    [data.setAssets]: (draftState, { payload }) => {
      draftState.assets = payload;
    },
    [data.setWaitData]: (draftState, { payload }) => {
      draftState.waitData = !!payload;
    },
    [data.setSoldAsset]: (draftState, { payload }) => {
      draftState.soldAsset = payload;
    },
    [data.setTrades]: (draftState, { payload }) => {
      draftState.trades = payload;
    },
    [data.setSoldTrade]: (draftState, { payload }) => {
      draftState.soldTrade = payload;
    },
    [data.setOrderHistory]: (draftState, { payload }) => {
      draftState.orderHistory = payload;
    },
    [data.setOrderHistory]: (draftState, { payload }) => {
      draftState.orderHistory = payload;
    },
    [data.setRedirectInfo]: (draftState, { payload }) => {
      draftState.redirectInfo = payload;
    },
    [data.setKycInfo]: (draftState, { payload }) => {
      draftState.kycInfo = payload;
    },
    [data.updateAssetActionButton]: (draftState, { payload }) => {
      const { linearId, dealWorkflowActionButtonText, intentionToBuy } = payload;
      const index = draftState.assets.findIndex((e) => e.linearId === linearId);
      draftState.assets[index].dealWorkflowActionButtonText = dealWorkflowActionButtonText;
      draftState.assets[index].intentionToBuy = intentionToBuy;
    },

    [data.updateAssetButton]: (draftState, { payload }) => {
      const { assetId, dealWorkflowActionButtonText, actionType, intendedQuantityToBuy, message } = payload;
      const index = draftState.assets.findIndex((e) => e.linearId === assetId);
      draftState.assets[index].dealWorkflowActionButtonText = dealWorkflowActionButtonText;
      draftState.assets[index].actionType = actionType;
      draftState.assets[index].intentionToBuy = intendedQuantityToBuy;
      if (message) draftState.assets[index].message = message;
    },
    [data.updateDealRaised]: (draftState, { payload }) => {
      const { assetId, toBeRaised, currency, raised } = payload;
      const index = draftState.assets.findIndex((e) => e.linearId === assetId);
      draftState.assets[index].raisedVsToBeRaised = {
        raised,
        toBeRaised,
        currency,
      };
    },
    [data.setSignData]: (draftState, { payload }) => {
      draftState.signData = payload;
    },
    [data.setInvestData]: (draftState, { payload }) => {
      draftState.investData = payload;
    },
    [data.setWallet]: (draftState, { payload }) => {
      draftState.balance = payload;
    },
    [data.setDocuments]: (draftState, { payload }) => {
      draftState.documents = payload;
    },
    [data.setQuestionAnswer]: (draftState, { payload }) => {
      draftState.viewQA = payload;
    },
    [data.setQuestionAnswerAsset]: (draftState, { payload }) => {
      const { assetId, info } = payload;
      draftState.dataQA[assetId] = info;
    },
    [data.setQuestionAnswerInv]: (draftState, { payload }) => {
      draftState.viewQAInv = payload;
    },
    [data.setQuestionAnswerAssetInv]: (draftState, { payload }) => {
      const { assetId, info } = payload;
      draftState.dataQAInv[assetId] = info;
    },
    [data.setEvents]: (draftState, { payload }) => {
      draftState.events = payload;
      draftState.eventsLoaded = true;
    },
    [data.setEventsCount]: (draftState, { payload }) => {
      draftState.todayEventsCount = payload;
    },
    [data.setAMSettings]: (draftState, { payload }) => {
      draftState.AMSettings = payload;
    },
    [data.setAdminNotifications]: (draftState, { payload }) => {
      const { data, field } = payload;
      if (field) draftState.adminNotifications[field] = data;
      else draftState.adminNotifications = data;
    },
    [data.setInvestorNotifications]: (draftState, { payload }) => {
      const { data, field } = payload;
      if (field) draftState.investorNotifications[field] = data;
      else draftState.investorNotifications = data;
    },
    [data.setQACount]: (draftState, { payload }) => {
      draftState.QACount = payload;
    },
    [data.setSponsorDetails]: (draftState, { payload }) => {
      const { sponsorName, data } = payload;
      draftState.sponsorsDetails[sponsorName] = data;
    },
    [data.setDealsSectors]: (draftState, { payload }) => {
      draftState.dealsSectors = payload;
    },
    [data.setDealsLocations]: (draftState, { payload }) => {
      draftState.dealsLocations = payload;
    },
    [data.setFileInfo]: (draftState, { payload }) => {
      draftState.fileInfo = payload;
    },
    [data.closeStartModal]: (draftState) => {
      draftState.AMSettings.startUpModalWindow = false;
    },
    [data.setDashboardTxs]: (draftState, { payload }) => {
      draftState.dashboardTxs = payload;
      draftState.waitTxs = false;
    },
    [data.setQuickViewList]: (draftState, { payload }) => {
      draftState.qiuckViewList = payload;
    },
    [data.setQuickViewSettings]: (draftState, { payload }) => {
      draftState.qiuckViewSettings = payload;
    },
  },
  initState()
);
