import logo from "assets/logos/glafka.png";

export default {
  origin: "https://glafka-ib.com",
  url: "https://valk-api-prod.cf",
  logo: logo,
  logoStyles: {
    auth: `width: 100%;height: 120px;display: flex;justify-content: center;margin-bottom: 30px;> img { width: 170px;}`,
    admin: `width: 160px;height: 63px;
  `,
    investor: `> img {width: 80px;}`,
  },
  title: "Glafka Capital",
  favicon: "/glafka.ico",
};
