import styled, { css } from "styled-components";

import previewBg from "assets/static_images/dummy-asset.png";
import { borderBox, textToDot, column, flexCenter } from "components/common/mixins";
import { Flex } from "components/common/containers";

const fz10 = css`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const HeaderLabel = styled.p`
  ${fz10};
  color: rgba(24, 24, 24, 0.6);
  ${borderBox};
  width: ${({ w = "100%" }) => w};
  margin: 0 0 20px;
`;

export const SettingLabel = styled.p`
  ${fz10};
  color: ${({ theme: { primary } }) => primary};
  cursor: pointer;
  ${borderBox};
  padding-bottom: 5px;
  border-bottom: 0.5px solid rgba(98, 128, 218, 0.2);
  margin: ${({ margin = "0 0 20px" }) => margin};
  > svg {
    width: 9px;
    height: 9px;
    margin-right: 5px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const CompanyBlock = styled.div`
  ${column};
  ${({ controlled, w = "calc(100% - 130px)", mr = "20px" }) =>
    controlled
      ? `
    width: ${w};
    margin-right:${mr};
  `
      : ""}
`;

export const Company = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #181818;
  margin-bottom: 5px;
  ${textToDot};
`;

export const CompanyDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  color: rgba(24, 24, 24, 0.5);
  ${textToDot};
  ${({ profiles = false }) => (profiles ? `display:flex;align-items:center;` : "")};
  > img {
    margin-right: 5px;
  }
`;

export const Source = styled.div`
  display: flex;
  color: rgba(24, 24, 24, 0.4);
  font-size: 10px;
  align-items: center;
`;

export const SOpt = styled.div`
  ${fz10};
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.4)")};
  display: flex;
  cursor: pointer;
  height: 37px;
  margin: auto 25px 0 0;
  position: relative;
  > svg {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    path {
      stroke: ${({ active, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.4)")};
    }
  }
  ${({ active, theme: { primary } }) =>
    active
      ? `
    ::after{
      content: "";
      position: absolute; 
      height: 1px;
      background: ${primary};
      bottom: 0;
      left: 0;
      width: 100%;
    }
  `
      : ""}
  :last-child {
    margin-right: 0;
  }
`;

export const TOpt = styled.div`
  ${fz10};
  margin: 0 5px;
  color: ${({ active, theme: { primary } }) => (active ? primary : "")};
  cursor: pointer;
  height: ${({ h = "100%" }) => h};
  ${flexCenter};
  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
`;

export const FlexCont = styled.div`
  display: flex;
`;

export const DTime = styled.div`
  ${column};
`;

export const Date = styled.div`
  text-align: right;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #b9b9b9;
`;

export const Time = styled.div`
  text-align: right;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  color: #cacaca;
`;

export const FeedBlock = styled.div`
  ${flexCenter};
  margin-right: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #c2c6d3;
  cursor: pointer;
  :last-child {
    margin-right: 0;
  }
  > svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`;

export const AssetAvatar = styled.div`
  display: flex;
  width: ${({ w = "80px" }) => w};
  height: ${({ h = "54px" }) => h};
  background-image: url(${previewBg});
  background-size: 100% 100%;
  margin-right: ${({ mr = "12px" }) => mr};
`;

export const AssetBlock = styled.div`
  display: flex;
`;

export const DscBlock = styled.div`
  ${column};
  width: calc(100% - 92px);
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  color: rgba(24, 24, 24, 0.5);
  > span {
    font-weight: bold;
    color: #192036;
  }
`;

export const Name = styled.div`
  margin-bottom: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #192036;
`;

export const Type = styled.p`
  display: inline;
  margin: 0;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: ${({ type = "EQUITY", theme: { primary, primaryDark } }) =>
    type === "EQUITY" ? "#31ADBF" : type === "DEBT" ? primaryDark : primary};
  margin-left: 5px;
`;

export const Loc = styled.div`
  ${flexCenter};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  color: #333333;
  margin-right: 10px;
  > svg {
    margin-right: 5px;
  }
`;

export const Types = styled.div`
  ${flexCenter};
  > svg {
    width: 9px;
    height: 9px;
    margin-right: 5px;
    :last-child {
      margin-right: 0;
    }
  }
`;

export const DealDsc = styled.div`
  ${column};
  width: calc(100% - 138px);
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  color: #333333;
  height: ${({ h = "auto" }) => h};
  > span {
    font-size: 12px;
  }
`;

export const DealTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #333333;
  margin-bottom: 15px;
`;

export const Expand = styled.div`
  ${flexCenter};
  margin-left: auto;
  cursor: pointer;
  > svg {
    width: 14px;
    height: 14px;
  }
`;

export const ExtraSectors = styled(Flex)`
  margin: auto 0 auto 8px;
  width: ${({ isLong }) => (isLong ? "16px" : "11px")};
  height: ${({ isLong }) => (isLong ? "16px" : "11px")};
  background: ${({ theme: { primary } }) => primary};
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 7px;
  ${flexCenter}
`;
