import Api from "helpers/api";
export default class DataRoomApiInvestor {
  static url = Api.url;

  static origin = Api.origin;

  static defaultAvatar = Api.defaultAvatar;

  static getAttachment = Api.getAttachment;

  static getDealsInfo(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApiInvestor.url}/api/dataroom/investor/assetsDataroom`, {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getDealDocuments(token, assetId, folderId = "", prefix = "") {
    return new Promise(async (res, rej) => {
      const folderIdParam = folderId ? `?folderId=${folderId}` : "";
      const prefixParam = prefix ? `&prefix=${prefix}` : "";
      const result = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/investor/foldersView/${assetId + folderIdParam + prefixParam}`,
        {
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static setReviewStatus(token, fileId, status = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/investor/changeReviewStatus/${fileId}?reviewStatus=${status}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static searchInDealDocuments(token, search, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/investor/searchFileAndFolder/${search}?assetId=${assetId}`,
        {
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static searchDeals(token, search) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApiInvestor.url}/api/dataroom/investor/searchAssetDataroom/${search}`, {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static downloadFile = async (token, folderName, fileName, assetId) => {
    const requestFile = await fetch(
      `${DataRoomApiInvestor.url}/api/dataroom/investor/fileLink/${folderName}/${fileName}?assetId=${assetId}`,
      {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return await requestFile.blob();
  };

  static getStarredFiles(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApiInvestor.url}/api/dataroom/investor/starredFiles/${assetId}`, {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getNegotiatedDocument(token, assetId, legalDocumentType) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/negotiationLD/${assetId}/${legalDocumentType}`,
        {
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getActiveNegotiation(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/negotiationLD/isActiveNegotiation/${assetId}`,
        {
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getNegotiable(token, assetId, legalDocumentType) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/negotiationLD/isNegotiable/${assetId}/${legalDocumentType}`,
        {
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static setNegotiatedFile(token, assetId, legalDocumentType, action) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/negotiationLD/${action}/${assetId}/${legalDocumentType}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static getS3Link = (token, fileId) => {
    return new Promise(async (res, rej) => {
      const requestFile = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/investor/fileLinkForDownload/${fileId}`,
        {
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (requestFile.status === 200) {
        res(await requestFile.text());
      } else rej(await requestFile.json());
    });
  };
}
