import store from "../index";

export const isStateUpdate = (reducerName, fieldName, state) => {
  const prevState = store.getState()[reducerName][fieldName];
  return !prevState || JSON.stringify(prevState) !== JSON.stringify(state);
};

export const isDeepStateUpdate = (reducerName, fieldName, key, nextValue) => {
  const prevState = store.getState()[reducerName][fieldName][key];
  return !prevState || JSON.stringify(prevState) !== JSON.stringify(nextValue);
};
