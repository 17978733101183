import { createAction } from "redux-act";

import Api from "helpers/api";

export const setAcceptedCurrency = createAction("SET_ACCEPTED_CURRENCY_LIST");
export const setSponsors = createAction("SET_SPONSOR_LIST");
export const setAccesses = createAction("SET_ACCESSES_LIST");
export const setDealTypes = createAction("SET_DEAL_TYPES_LIST");
export const setCategories = createAction("SET_CATEGORIES_LIST");
export const setRuleOptions = createAction("SET_RULE_OPTIONS");
export const setCountriesOption = createAction("SET_COUNTRIES_OPTION");
export const setInvestorWorkflowTypes = createAction("SET_INVESTOR_WORK_FLOW_TYPES");
export const setLockUpTimes = createAction("SET_LOCK_UP_TIME");
export const setSponsorsFull = createAction("SET_SPONSOR_FULL_LIST");
export const setFinancialTypes = createAction("SET_FINANCIAL_DEAL_TYPES");
export const setFinancialTemplates = createAction("SET_FINANCIAL_TEMPLATES");
export const setWaitAsyncData = createAction("SET_WAIT_ASYNC_DATA");
export const addNewSponsor = createAction("ADD_NEW_SPONSOR");
export const updateSponsor = createAction("UPDATE_EXISTED_SPONSOR");

export const getCountriesOption = () => async (dispatch) => {
  const res = await Api.getCountriesOption().catch(console.log);
  if (res) dispatch(setCountriesOption(res));
};

// export const addNewSponsor = (obj) => async (dispatch) => {
//   // const sponsors = await Api.getSponsors(token);
//   // dispatch(setSponsors(sponsors.map(({ username }) => username)));
//   // dispatch(setSponsorsFull(sponsors));
// };
