import styled from "styled-components";
import { Link } from "react-router-dom";

import { valkButton, borderBox } from "components/common/mixins";

export const ButtonLink = styled(Link)`
  ${valkButton}
  ${borderBox}
  text-decoration:none;
`;
