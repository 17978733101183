import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { textToDot } from "../mixins";
import { InputLabel } from "./label";
export { InputLabel };

const linkStyle = css`
  font-size: ${({ fs = 14 }) => `${fs}px`};
  line-height: 150%;
  color: ${({ theme: { primary }, color }) => color || primary};
  border-bottom: ${({ nb = false }) => (nb ? "none" : "0.5px solid rgba(98, 128, 218, 0.2)")};
  width: ${({ w = "fit-content" }) => w};
  text-decoration: none;
  cursor: pointer;
`;

export const Label = styled.h6`
  font-size: ${({ fs = 10 }) => `${fs}px`};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ lower = false }) => (lower ? "none" : "uppercase")};
  color: rgba(24, 24, 24, 0.6);
  margin: ${({ m = "0 0 5px" }) => m};
  padding: ${({ p }) => p};
`;

export const Text = styled.div`
  font-size: ${({ fs = 14 }) => `${fs}px`};
  font-weight: ${({ fw = "normal" }) => fw};
  line-height: 150%;
  letter-spacing: -0.02em;
  color: ${({ light = false, color }) => (light ? "rgba(51, 51, 51, 0.6)" : color ? color : "#333333")};
  margin: ${({ m = 0 }) => m};
  white-space: ${({ noWrap = false }) => (noWrap ? "nowrap" : "normal")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  img {
    width: 100%;
  }
`;

export const Heading = styled.h6`
  font-size: ${({ fs = 14 }) => `${fs}px`};
  line-height: 135%;
  color: ${({ color = "#181818" }) => color};
  margin: ${({ m = 0 }) => m};
  font-weight: ${({ fw = "bold" }) => fw};
  text-align: ${({ center }) => (center ? "center" : "left")};
  ${({ isDots = false }) => (isDots ? `${textToDot} ` : "")}
`;

export const PointerHeading = styled(Heading)`
  display: flex;
  align-items: center;
  border-bottom: 1px dashed ${({ active = false, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.3)")};
  color: ${({ active = false, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.3)")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  ${({ disabled = false }) => (disabled ? `pointer-events: none; cursor: auto;` : "")}
  user-select: none;
`;

export const TextAsLink = styled.div`
  ${linkStyle}
  font-weight: ${({ fw = "bold" }) => fw};
  margin: ${({ margin }) => margin};
`;

export const TextLink = styled.a.attrs(() => ({ target: "_blank" }))`
  ${linkStyle}
  ${textToDot}
  font-weight: ${({ fw = "normal" }) => fw};
  svg {
    width: ${({ fs = 14 }) => `${fs}px`};
    height: ${({ fs = 14 }) => `${fs}px`};
    margin-right: ${({ fs = 14 }) => `${fs / 2}px`};
    path {
      stroke: ${({ theme: { primary }, color }) => color || primary};
    }
  }
`;

export const NavLink = styled(Link)`
  ${linkStyle}
  font-weight: ${({ fw = "bold" }) => fw};
  margin: ${({ m = 0 }) => m};
`;

export const LegendLabel = styled.div`
  ${linkStyle}
  ${textToDot}
  display: flex;
  margin: ${({ m }) => m};
  border: none;
  text-decoration: ${({ td = "underline" }) => td};
  justify-content: ${({ justify }) => justify};
  padding: 0 0 0 30px;
`;
