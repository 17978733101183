import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { ReactComponent as Offerins } from "assets/dashboard-icon.svg";
import { ReactComponent as Documents } from "assets/file.svg";
import { ReactComponent as Transactions } from "assets/trending-up.svg";
import { ReactComponent as Wallet } from "assets/credit-card2.svg";
import { ReactComponent as Bell } from "assets/bell-icon.svg";
import { ReactComponent as Basket } from "assets/shopping-cart.svg";
import { ReactComponent as FolderIcon } from "assets/folder.svg";
import { ReactComponent as QAIcon } from "assets/help-circle-inv.svg";
import { ReactComponent as Valk } from "assets/valk-logo.svg";
import { ReactComponent as Chevron } from "assets/chevron.svg";
import { ExternalSocialLink } from "components/auht-layout/styled";

import Api from "helpers/api";
import { column, borderBox, flexCenter } from "components/common/mixins";
import { useSocialLinks } from "helpers/customHook";

const Icons = {
  Offerings: <Offerins />,
  Transactions: <Transactions />,
  Documents: <Documents />,
  Wallet: <Wallet />,
  ["Data Room"]: <FolderIcon />,
  ["Q&A"]: <QAIcon />,
};

export const Header = styled.header`
  display: flex;
  align-items: center;
  height: 70px;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  ${borderBox}
  padding-right: 20px;
  z-index: 1;
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderMobile = styled.header`
  @media (max-width: 768px) {
    display: flex;
    height: 64px;
    width: 100%;
    background: ${({ theme: { headerInvestor } }) => headerInvestor};
    position: fixed;
    z-index: 3;
  }
`;

export const LogoContainer = styled.div`
  width: 237px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  height: 100%;
  > img {
    width: 177px;
  }
  ${({ styles = "" }) => styles};
`;

export const MobileLogoContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    ${({ styles = "" }) => styles};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  padding: 0 20px;
  height: 100%;
`;

export const MobileLinkWrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    margin-top: 64px;
    flex-direction: column;
    height: ${({ open }) => (open ? "calc(100vh - 114px)" : "0")};
    transition: all 0.3s linear;
    background: ${({ theme: { headerAdmin } }) => headerAdmin};
    width: 100%;
    padding: ${({ open }) => (open ? "30px 20px" : "0 20px")};
  }
`;

export const HeaderLink = styled(Link)`
  height: 100%;
  display: flex;
  ${flexCenter}
  border-bottom: 2px solid ${({ active, theme: { navLinkBorder } }) => (active ? navLinkBorder : "transparent")};
  color: ${({ active, theme: { navLinkActive, navLink } }) => (active ? navLinkActive : navLink)};

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  margin-right: 30px;
  text-decoration: none;
  ${borderBox}
  white-space: nowrap;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    path {
      stroke: ${({ active, theme: { navLinkActive, navLink } }) => (active ? navLinkActive : navLink)};
    }
    @media (max-width: 1400px) {
      margin-right: ${({ active }) => (active ? "10px" : 0)};
    }
  }
  > span {
    @media (max-width: 1400px) {
      display: ${({ active }) => (active ? "inline" : "none")};
    }
  }
  &:hover {
    @media (max-width: 1400px) {
      .nav-tooltip {
        display: ${({ active }) => (active ? "none" : "block")};
      }
    }
  }
`;

const MobileHeaderLink = styled(Link)`
  display: none;
  @media (max-width: 768px) {
    height: 56px;
    display: ${({ open }) => (open ? "flex" : "none")};
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 135%;
    color: #ffffff;
    justify-content: space-between;
    text-decoration: none;
    svg {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
`;

const NavTooltip = styled.div.attrs(() => ({ "data-lvl-tooltip": true }))`
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #efefef;
  ${borderBox}
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 10px 35px;
  position: absolute;
  display: none;
  bottom: -52px;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: #333333;
  /* z-index: 100; */
  @media (max-width: 768px) {
    display: none;
  }
  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 12.5px 0px 12.5px;
    border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
    position: absolute;
    right: calc(50% - 10px);
    top: -15px;
    z-index: 101;
    transform: rotate(60deg);
  }
`;

const Flex = styled.div`
  display: flex;
`;

const CountCircle = styled.div`
  height: 16px;
  width: 16px;
  font-size: 10px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 50%;
  color: #fff;
  align-items: center;
  justify-content: center;

  font-style: normal;
  display: flex;
  margin-left: 7px;
`;

const NotificationCircle = styled(CountCircle)`
  position: absolute;
  top: 17px;
  right: -13px;
  margin-left: 0;
`;

export const NavLink = ({ link, text, active, countNotifications }) => {
  return (
    <HeaderLink active={active ? 1 : 0} to={link}>
      {Icons[text]}
      <span>{text}</span>
      <NavTooltip className="nav-tooltip">{text}</NavTooltip>
      {countNotifications ? <NotificationCircle>{countNotifications}</NotificationCircle> : null}
    </HeaderLink>
  );
};

export const MobileNavLink = ({ link, text, hasCheck = false, onCloseMenu, open }) => {
  return (
    <MobileHeaderLink to={link} onClick={onCloseMenu} open={open}>
      <Flex>
        {Icons[text]}
        {text}
      </Flex>
      {hasCheck && <Chevron />}
    </MobileHeaderLink>
  );
};

const NotificationLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  border-bottom: 1px solid #f6f7fc;
  text-decoration: none;
  position: relative;
  z-index: 81;
  svg {
    margin-right: 12px;
  }
`;

const NotificationCounter = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: ${({ theme: { primary } }) => primary};
`;

export const ProfileLink = ({ icon, text, link, newNotifications, newMessages, onChangeOpenProfile }) => {
  return (
    <NotificationLink to={link} onClick={onChangeOpenProfile}>
      <Flex>
        {icon} {text}
      </Flex>
      <NotificationCounter>
        {newNotifications ? newNotifications : newMessages ? newMessages : null}
      </NotificationCounter>
    </NotificationLink>
  );
};

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  height: 70px;
  background: ${({ theme: { footerInvestor } }) => footerInvestor};
  ${borderBox}
  justify-content: space-between;
  padding: 0 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    transform: translateY(64px);
    height: 110px;
    padding: 10px 20px;
  }
`;

export const CopyRightBlock = styled.div`
  font-style: normal;
  font-weight: ${({ fw = 500 }) => fw};
  font-size: 12px;
  line-height: 160%;
  max-width: ${({ mw }) => mw};
  color: ${({ color }) => (color ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.6)")};
  > span.client-name {
    margin-right: 25px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const Wrapper = styled.section`
  min-height: ${({ isGeneral }) => (isGeneral ? "calc(100vh - 245px)" : "calc(100vh - 140px)")};
  padding: 25px 0 60px 0;
  ${borderBox}
  background: #f4f5f6;
  position: relative;
  @media (max-width: 768px) {
    transform: translateY(64px);
    z-index: ${({ open }) => (open ? 0 : 2)};
    padding: 16px 0 30px 0;
  }
`;

const NotificationsBlock = styled.div`
  margin-left: auto;
  margin-right: ${({ noMargin }) => (noMargin ? 0 : "19px")};
  height: 100%;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(255, 255, 255, 0.07);
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  position: relative;
  > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    path {
      stroke: #fff;
      opacity: ${({ active, unreadCount }) => (active || unreadCount ? 1 : 0.4)};
    }
  }
  &::after {
    content: "${({ unreadCount }) => unreadCount}";
    display: ${({ unreadCount }) => (unreadCount ? "flex" : "none")};
    height: 16px;
    width: 16px;
    font-size: 10px;
    background: ${({ theme: { primary } }) => primary};
    position: absolute;
    border-radius: 50%;
    color: #fff;
    align-items: center;
    justify-content: center;

    font-style: normal;
    right: ${({ circleRight }) => (circleRight ? `${circleRight}px` : "27px")};
    top: 20px;
  }
  &::before {
    content: "";
    display: ${({ active }) => (active ? "block" : "none")};
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ theme: { navLinkBorder } }) => navLinkBorder};
  }
`;

const NotifInfo = styled.div.attrs(() => ({ "data-lvl-tooltip": true }))`
  position: absolute;
  right: 70px;
  top: ${({ tooltopTop }) => (tooltopTop ? `${tooltopTop}px` : "24px")};
  width: ${({ width }) => (width ? `${width}px` : "250px")};
  min-height: 43px;
  ${borderBox}
  padding: 10px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  /* z-index: 900; */
  ${column}
`;

const InfoTriangle = styled.div.attrs(() => ({
  "data-lvl-tooltip-frontend": true,
}))`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  right: 56px;
  z-index: 901;
`;

const LinkToUnread = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Notifications = ({ unread }) => {
  const [open, setOpen] = useState(false);

  const renderAssets = () => {
    return unread.assets.map((e) => {
      const { assetName, assetId, count } = e;
      return (
        <LinkToUnread key={assetId} to={`/investor/offerings/companies/${assetId}`}>
          {assetName}
          <CountCircle>{count}</CountCircle>
        </LinkToUnread>
      );
    });
  };
  return (
    <NotificationsBlock unreadCount={unread.total} onClick={() => setOpen(!open)}>
      <Bell />
      {open && unread && unread.assets && unread.total !== 0 ? (
        <Fragment>
          <NotifInfo>{renderAssets()}</NotifInfo>
          <InfoTriangle />
        </Fragment>
      ) : null}
    </NotificationsBlock>
  );
};

export const UnfinishedOrders = ({ unfinished, onClick, path }) => {
  return (
    <NotificationsBlock
      circleRight={22}
      noMargin
      unreadCount={unfinished}
      onClick={onClick}
      active={path === "/investor/unfinished-orders"}
    >
      <Basket />
    </NotificationsBlock>
  );
};

export const FooterLink = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ theme: { navLinkActive } }) => navLinkActive};
  margin-right: ${({ mr = "30px" }) => mr};
  text-decoration: none;
  &:last-child {
    margin-right: 0;
  }
`;

export const SocialLink = styled(Link)`
  margin-right: 24px;
  transition: opacity 0.3s linear;
  > svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme: { navLinkActive } }) => navLinkActive};
    }
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: auto;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ChatLink = styled(Link)`
  margin-left: auto;
  height: 100%;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(255, 255, 255, 0.07);
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  position: relative;
  > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    path {
      stroke: #fff;
      opacity: ${({ active }) => (active ? 1 : 0.4)};
    }
  }
  &::before {
    content: "";
    display: ${({ active }) => (active ? "block" : "none")};
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ theme: { navLinkBorder } }) => navLinkBorder};
  }
  &::after {
    content: "${({ newMess }) => newMess}";
    display: ${({ newMess }) => (newMess ? "flex" : "none")};
    height: 16px;
    width: 16px;
    font-size: 10px;
    background: ${({ theme: { primary } }) => primary};
    position: absolute;
    border-radius: 50%;
    color: #fff;
    align-items: center;
    justify-content: center;

    font-style: normal;
    right: 18px;
    top: 18px;
  }
`;

export const FooterGlobal = () => {
  const { companyInfo } = useSelector((state) => state.companyInfoReducer);

  return <FooterFreemium {...{ companyInfo }} />;
};

export const HeaderText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  color: #ffffff;
  margin-left: 25px;
`;

export const FreemiumFooter = styled.footer`
  @media (max-width: 768px) {
    display: none;
  }
  ${column}
  height: 175px;
  width: 100%;
  background: ${({ theme: { footerInvestor } }) => footerInvestor};
  ${borderBox}
  justify-content: space-between;
  padding: 0 60px;
`;

const MobileFooter = styled.footer`
  display: none;
  @media (max-width: 768px) {
    ${column}
    height: 360px;
    width: 100%;
    background: ${({ theme: { footerInvestor } }) => footerInvestor};
    padding: 25px;
    overflow-x: hidden;
    ${borderBox}
    position: relative;
    z-index: ${({ open }) => (open ? 0 : 1)};
  }
`;

export const FooterBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  ${borderBox}
  &:last-child {
    border: none;
    align-items: start;
    padding-top: 20px;
  }
`;

export const PoweredBlock = styled.div`
  ${column}
  font-weight: 600;
  font-size: 10px;
  line-height: 160%;
  text-align: right;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  > span {
    margin-bottom: 5px;
  }
`;

export const FooterDiscription = styled.div`
  font-size: 12px;
  line-height: 160%;
  color: rgba(255, 255, 255, 0.6);
  max-width: 50%;
`;

const MobileBox = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
    font-size: ${({ fz = "12px" }) => fz};
    line-height: 170%;
    color: rgba(255, 255, 255, 0.8);
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ mb = "32px" }) => mb};
    flex-direction: ${({ column }) => (column ? "column" : "row")};
    border-bottom: ${({ bb }) => (bb ? "1px solid rgba(255, 255, 255, 0.1)" : "none")};
    padding: ${({ padding }) => padding};
    text-align: center;
    > span {
      font-weight: 600;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.6);
    }
  }
`;

export const FooterFreemium = ({
  companyInfo: { companyName, footerText, privacyPolicyDoc, termsAndConditionsDoc, hidePoweredBy },
}) => {
  const links = useSocialLinks();
  const [wait, setWait] = useState(false);
  const token = useSelector((state) => state.userReducer.token);

  const renderLinks = () =>
    links.map(({ link, icon }, i) => (
      <ExternalSocialLink key={`${link}-${i}`} margin="0 10px" href={link} target="_blank">
        {icon}
      </ExternalSocialLink>
    ));

  const onDownload = (file) => async () => {
    setWait(true);
    const { fileNameWithExtension, secureHash } = file;
    const link = await Api.getAttachment(token, secureHash, fileNameWithExtension);
    try {
      if (link && wait === false) {
        const a = document.createElement("a");
        a.href = link;
        a.download = fileNameWithExtension;
        a.click();
      } else {
        return null;
      }
    } catch (e) {
      setWait(false);
    }
    setWait(false);
  };

  return (
    <Fragment>
      <FreemiumFooter>
        <FooterBox>
          <CopyRightBlock color fw="bold" mw="30%">
            {companyName} © 2020.
            <br /> All Rights Reserved
          </CopyRightBlock>
          {links.length ? <div>{renderLinks()}</div> : null}
          {!hidePoweredBy ? (
            <PoweredBlock>
              <span>Powered By</span>
              <Valk />
            </PoweredBlock>
          ) : null}
        </FooterBox>
        <FooterBox>
          <FooterDiscription>{footerText}</FooterDiscription>
          <div>
            {privacyPolicyDoc && (
              <FooterLink mr="32px" disabled={wait} onClick={onDownload(privacyPolicyDoc)}>
                Privacy Policy
              </FooterLink>
            )}
            {termsAndConditionsDoc && (
              <FooterLink mr="0px" disabled={wait} onClick={onDownload(termsAndConditionsDoc)}>
                Terms & Conditions
              </FooterLink>
            )}
          </div>
        </FooterBox>
      </FreemiumFooter>

      {/* mobile footer */}

      <MobileFooter>
        <MobileBox bold>{companyName} © 2020. All Rights Reserved</MobileBox>
        <MobileBox>{links.length ? <div>{renderLinks()}</div> : null}</MobileBox>
        {!hidePoweredBy ? (
          <MobileBox column bb padding="0 0 32px" mb="24px">
            <span>Powered By</span>
            <Valk style={{ marginTop: 6 }} />
          </MobileBox>
        ) : null}
        <MobileBox fz="10px" mb="20px">
          {footerText}
        </MobileBox>
        <MobileBox mb="0px">
          {privacyPolicyDoc && (
            <FooterLink mr="32px" disabled={wait} onClick={onDownload(privacyPolicyDoc)}>
              Privacy Policy
            </FooterLink>
          )}
          {termsAndConditionsDoc && (
            <FooterLink mr="0px" disabled={wait} onClick={onDownload(termsAndConditionsDoc)}>
              Terms & Conditions
            </FooterLink>
          )}
        </MobileBox>
      </MobileFooter>
    </Fragment>
  );
};

export const CustomLogo = styled.div`
  width: 237px;
  height: 70px;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  img {
    width: 100%;
    height: 70px;
    object-fit: contain;
  }
`;

export const CustomMobileLogo = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    height: 35px;
    background: ${({ theme: { headerInvestor } }) => headerInvestor};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const MobileMenuNotification = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 50%;
  position: absolute;
  top: 1px;
  right: -5px;
  font-weight: bold;
  font-size: 8px;
  line-height: 135%;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const StyledBurger = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    width: 20px;
    height: 20px;
    position: fixed;
    top: 22px;
    left: 16px;
    z-index: 81;
  }
  > div {
    width: 20px;
    height: 2px;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.2s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(-45deg) translate(-6px, 3px)" : "rotate(0)")};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => (open ? 0 : 1)};
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(45deg) translate(-7px, -4px)" : "rotate(0)")};
    }
  }
`;

export const Burger = ({ isOpen, onChangeOpen }) => {
  return (
    <StyledBurger open={isOpen} onClick={onChangeOpen}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const SocialRow = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  align-items: center;
  margin: auto 0px 55px;
  width: ${({ open }) => (open ? "100:" : "0px")};
  transition: all 1.5s ease;
`;

export const SocialLinksMenu = ({ isOpenMenu }) => {
  const links = useSocialLinks();

  return (
    <SocialRow open={isOpenMenu}>
      {links.map(({ icon, link }, index) => (
        <ExternalSocialLink key={`${link}-${index}`} margin="0 20px 0 0" href={link} target="_blank">
          {icon}
        </ExternalSocialLink>
      ))}
    </SocialRow>
  );
};
