import { createAction } from "redux-act";
import Api from "helpers/api";
import history from "modules/history";
import { app } from "modules/notification";

import { socketConnect } from "../socket";
import { mobileCheck } from "helpers/mobileCheck";

export const appReadyToWork = createAction("SET FLAG FOR SIGNALING ON READY APP FOR WORK");
export const loginSuccess = createAction("AUTH_USER_SUCCESS");
export const saveUserInfo = createAction("SAVE_USER_INFO");
export const logOut = createAction("USER_LOGOUT");
export const rememberUserToggle = createAction("REMEMBER_USER_TOGGLE");
export const set2Fa = createAction("SET 2 FA");
export const updateAvatar = createAction("UPDATE USER AVATAR");
export const updateUserName = createAction("UPDATE USER NAME");
export const setPaymentInfo = createAction("SET PAYMENT INFO");
export const setPaymentDetails = createAction("SET PAYMENT DETAILS");
export const setTradePaymentDetails = createAction("SET TRADE PAYMENT DETAILS");
export const setPaymentResponseData = createAction("SET PAYMENT RESPONSE DATA");
export const setPaymentResponseDataTrade = createAction("SET PAYMENT RESPONSE DATA TRADE");
export const setDefaultDealsView = createAction("SET DEFAULT DEALS VIEW");
export const setMailIntegration = createAction("SET MAILING INTEGRATION INFO");
export const setSetupStatus = createAction("SET SUTUP PASSED STATUS");
export const setAdminSettings = createAction("SET ADMIN SETTINGS");
export const toggleDemoMessageStatus = createAction("SET DEMO MESSAGE STATUS");
export const addNewBankAccount = createAction("ADD NEW BANK ACCOUNT");
export const addAddress = createAction("ADD USER ADDRESS");
export const setDeviceName = createAction("SET DEVICE NAME");
export const setOnboardingPassed = createAction("SET_ONBOARDING_PASSED");

export const is2FaEnabled = (authData, cb) => async (dispatch) => {
  const _2fa = await Api.is2faRequired(authData);
  if (_2fa["2faEnabled"]) {
    dispatch(set2Fa(_2fa["2faEnabled"]));
  } else if (cb) {
    cb();
  }
};

export const login =
  (authData, code = "", checkLogin = false, deviceError = console.log) =>
  async (dispatch) => {
    const { token, user } = await Api.login(authData, code);
    if (token) {
      if ((user.role === "AM" || user.role === "ADMIN" || user.role === "SPONSOR") && mobileCheck()) {
        deviceError("device");
        return;
      }
      dispatch(loginSuccess(token));
      dispatch(getUserInfo(token, true));

      if (user && user.userData) {
        fetch(`${Api.url}/api/userviewstatistics/count`, {
          headers: {
            "Content-type": "application/json",
            Authorization: token,
            OriginVoC: Api.origin,
          },
          method: "post",
          body: JSON.stringify({
            email: user.userData.username,
            name: user.userData.fullName,
            role: user.role,
            origin: Api.origin,
          }),
        }).catch(console.log);
      }

      if (checkLogin) return true;
    }
  };

export const reLogin = (authData, cb) => async (dispatch) => {
  const { token } = await Api.login(authData);

  if (token) dispatch(loginSuccess(token));
  if (cb) cb(token);
};

export const getUserInfo =
  (token, moveTo = false) =>
  async (dispatch, getState) => {
    try {
      const user = await Api.getUserInfo(token);

      const userInfo = await Api.getUserData(token);
      if (user && userInfo) {
        const {
          userData: { userLogo },
          username,
          role,
          am,
          setupPassed,
        } = user;
        const { gender } = userInfo;
        const { fileNameWithExtension, secureHash } = userLogo || {};

        const logo =
          fileNameWithExtension && secureHash
            ? await Api.getAttachment(token, secureHash, fileNameWithExtension)
            : gender === "FEMALE"
            ? Api.defaultFemaleAvatar
            : gender === "OTHER"
            ? Api.defaultNeitralAvatar
            : Api.defaultAvatar;
        dispatch(saveUserInfo({ ...user, logo, userInfo }));
        if ("setupPassed" in user) dispatch(setSetupStatus(user.setupPassed));
        socketConnect(token, role === "ADMIN" ? am : username, role, dispatch, getState);

        try {
          if (
            process.env.REACT_APP_SUPPORT_ANALYTICS &&
            process.env.REACT_APP_SUPPORT_ANALYTICS.toUpperCase() === "YES"
          ) {
            app.analytics().setUserProperties({
              email: user.userData.email,
              name: user.userData.fullName,
              role: user.role,

              username: user.username || "none",
              fullName: user.userData.fullName || "none",
              roleName: user.role,
            });
          }
        } catch (_) {}
        if (moveTo && (user.role === "AM" || user.role === "ADMIN" || user.role === "SPONSOR")) {
          if (setupPassed === false && user.role === "AM") {
            history.push("/setup");
          }
          history.push("/admin/full-dashboard");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        dispatch(appReadyToWork());
      }, 1000);
    }
  };

export const paymentReLogin = (token) => (dispatch) => {
  dispatch(loginSuccess(token));
  dispatch(getUserInfo(token, true));
};

export const updateUserInfo = (token) => (dispatch) => {
  dispatch(getUserInfo(token));
};

export const getMailIntegration = (token) => async (dispatch) => {
  const mailConnection = await Api.getMailConnection(token);
  const defaultMailIntegration = await Api.getDefaultMailSending(token);
  dispatch(
    setMailIntegration({
      defaultMailIntegration,
      mailIntegrationValid: mailConnection ? mailConnection.valid : null,
    })
  );
};

export const getAdminSettings =
  (token, isManual = false, status = false) =>
  async (dispatch) => {
    if (!isManual) {
      const settings = await Api.getAdminSettings(token);
      if (settings) dispatch(setAdminSettings(settings.demoMode));
    } else {
      dispatch(setAdminSettings(status));
    }
  };
