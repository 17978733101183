import React from "react";

import { ReactComponent as PlusCircle } from "../assets/plus-circle.svg";
import { ReactComponent as Remove } from "../assets/delete-file.svg";
import { ReactComponent as Close } from "../assets/x-circle.svg";
import { ReactComponent as Edit } from "../assets/edit-2.svg";
import { ReactComponent as Check } from "../assets/check.svg";
import { ReactComponent as Users } from "../assets/users.svg";
import { ReactComponent as User } from "../assets/user-1.svg";
import { ReactComponent as NewReqests } from "../assets/user-check.svg";
import { ReactComponent as Shares } from "../assets/briefcase.svg";
import { ReactComponent as CreditCard } from "../assets/credit-card.svg";
import { ReactComponent as Plus } from "../assets/plus-circle.svg";
import { ReactComponent as Search } from "../assets/search-icon.svg";
import { ReactComponent as Arrow } from "../assets/chevron-down.svg";
import { ReactComponent as CloseSmall } from "../assets/close.svg";
import { ReactComponent as ArrowLeft } from "../assets/arrowleft.svg";
import { ReactComponent as Paper } from "../assets/file-text-1.svg";
import { ReactComponent as ArrowUp } from "../assets/arrow-up-right.svg";
import { ReactComponent as Menu } from "../assets/menu.svg";
import { ReactComponent as Eye } from "../assets/eye.svg";
import { UserSmall } from "components/common/icons-primary";
import { ReactComponent as SearchBig } from "../assets/search-big.svg";
import { ReactComponent as More } from "../assets/more-vertical.svg";
import { ReactComponent as Copy } from "../assets/copy.svg";
import { ReactComponent as CrossRed } from "../assets/x-red-small.svg";
import { ReactComponent as CopyBlack } from "../assets/copy-black.svg";
import { ReactComponent as CrossGrey } from "../assets/x-grey.svg";
import { ReactComponent as CrossRemove } from "../assets/cross-red.svg";
import { ReactComponent as ArrowRight } from "../assets/Arrow-right 2.svg";
import { ReactComponent as Upload } from "../assets/upload.svg";
import { ReactComponent as File } from "../assets/kyc-icon.svg";
import { ReactComponent as Info } from "../assets/info.svg";
import { ReactComponent as Clock } from "../assets/clock-2.svg";
import { ReactComponent as Refresh } from "../assets/refresh-cw.svg";
import { ReactComponent as CheckCircle } from "../assets/check-circle.svg";
import { ReactComponent as PlusCircleSlim } from "../assets/plus-circle-slim.svg";
import { ReactComponent as UserCheck } from "../assets/user-check-m.svg";
import { ReactComponent as UserPlus } from "../assets/user-plus-m.svg";
import { ReactComponent as MessageThin } from "../assets/message-square-thin.svg";
import { ReactComponent as MessageIcon } from "assets/message-square.svg";
import { ReactComponent as LoginIcon } from "assets/log-in.svg";
import { ReactComponent as ResetIcon } from "assets/lock.svg";
import { ReactComponent as SuspendIcon } from "assets/slash.svg";
import { ReactComponent as CheckGreen } from "assets/check-green.svg";
import { ReactComponent as FolderIcon } from "assets/folder-white.svg";
import { ReactComponent as HelpIcon } from "assets/help-white.svg";
import { ReactComponent as PlusIcon } from "assets/plus-white.svg";
import { ReactComponent as MailIcon } from "assets/mail-white.svg";
import { ReactComponent as EditIcon } from "assets/edit-white.svg";
import { ReactComponent as ArrowUpIcon } from "assets/arrow-up-white.svg";
import { ReactComponent as SlidersIcon } from "assets/sliders-white.svg";
import { ReactComponent as FileIcon } from "assets/file-white.svg";
import { ReactComponent as PlayIcon } from "assets/play-circle.svg";
import { ReactComponent as Maximize } from "assets/maximize-white.svg";
import { ReactComponent as BlueDownLoad } from "assets/arrow-down-blue.svg";
import { ReactComponent as BlueFolder } from "assets/folder-blue.svg";
import { ReactComponent as ExternalLink } from "assets/external-link-white.svg";
import { ReactComponent as SettingsIcon } from "assets/settings-white.svg";
import { ReactComponent as Minimize } from "assets/collapse.svg";
import { ReactComponent as BlueDownLoadUp } from "assets/download-up.svg";
import { ReactComponent as MailBlue } from "assets/mail-blue.svg";
import { ReactComponent as MobileBell } from "assets/bell.svg";
import { ReactComponent as MobileMessage } from "assets/message-m.svg";
import { ReactComponent as MobileProfile } from "assets/user-m.svg";
import { ReactComponent as MobileLogout } from "assets/log-out-m.svg";
import { ReactComponent as Columns } from "assets/columns.svg";
import { ReactComponent as InvestorIcon } from "assets/investor.svg";
import { ReactComponent as SponsorIcon } from "assets/sponsor.svg";
import { ReactComponent as AdministratorIcon } from "assets/administrator.svg";
import { ReactComponent as ServerIcon } from "assets/server.svg";
import { ReactComponent as RssIcon } from "assets/rss.svg";
import { ReactComponent as BriefIcon } from "assets/briefcase-slim.svg";
import { ReactComponent as Pen } from "assets/edit-5.svg";
import { ReactComponent as ListIcon } from "assets/list.svg";
import { ReactComponent as UsersIcon } from "assets/users2.svg";
import { ReactComponent as PaperClipIcon2 } from "assets/paperclip2.svg";
import { ReactComponent as SendIcon } from "assets/send.svg";
import { ReactComponent as VideoIcon } from "assets/video.svg";
import { ReactComponent as ImgIcon } from "assets/image.svg";
import { ReactComponent as EditIcon2 } from "assets/edit-2.svg";
import { ReactComponent as ProfileIcon } from "assets/user-plus-fat.svg";
import { ReactComponent as Accept } from "assets/accept.svg";
import { ReactComponent as Decline } from "assets/decline.svg";
import { ReactComponent as LikeIcon } from "assets/like.svg";
import { ReactComponent as ShareIcon } from "assets/share-2.svg";
import { ReactComponent as DotsIcon } from "assets/dots.svg";
import { ReactComponent as MsgIcon } from "assets/message-circle-white.svg";
import { ReactComponent as MessageIconCircle } from "assets/message-circle-fat.svg";
import { ReactComponent as Link } from "assets/link.svg";
import { Globe } from "components/common/icons-primary";
import { ReactComponent as Equity } from "assets/folder-plus-equity.svg";
import { ReactComponent as Debt } from "assets/folder-minus-debt.svg";
import { ReactComponent as BlendedIcon } from "assets/blended.svg";
import { ReactComponent as Disc } from "assets/disc.svg";
import { ReactComponent as TrendUp } from "assets/trending-up-blue.svg";
import { ReactComponent as Pin } from "assets/map-pin-2.svg";
import { ReactComponent as AlarmClock } from "assets/clock-blue.svg";
import { ReactComponent as Repeat } from "assets/repeat.svg";
import { More as MoreHorisontal } from "components/common/icons-primary";
import { ReactComponent as HorizontalMore } from "assets/more-horizontal.svg";
import { ReactComponent as FullCheck } from "data-room-panel/asset/nego-check-investor.svg";
import { ReactComponent as XIcon } from "assets/x.svg";
import { ReactComponent as SaveCard } from "assets/save.svg";
import { ReactComponent as Calendar } from "assets/calendar-dr.svg";
import { ReactComponent as Pie } from "assets/pie-chart.svg";
import { ReactComponent as NoAvatar } from "assets/no-avatar.svg";
import { ReactComponent as Institution } from "assets/institution.svg";
import { ReactComponent as NoAvatarFemale } from "assets/no-avatar-female.svg";

export const icons = {
  add: <PlusCircle />,
  remove: <Remove />,
  close: <Close />,
  edit: <Edit />,
  check: <Check />,
  users: <Users />,
  user: <User />,
  newRequests: <NewReqests />,
  shares: <Shares />,
  creditCard: <CreditCard />,
  plusCircle: <Plus />,
  search: <Search />,
  arrow: <Arrow />,
  closeSmall: <CloseSmall />,
  left: <ArrowLeft />,
  paper: <Paper />,
  arrowUp: <ArrowUp />,
  menu: <Menu />,
  eye: <Eye />,
  userSmall: <UserSmall />,
  searchBig: <SearchBig />,
  more: <More />,
  copy: <Copy />,
  cross: <CrossRed />,
  copyBlack: <CopyBlack />,
  crossGrey: <CrossGrey />,
  crossRed: <CrossRemove />,
  right: <ArrowRight />,
  upload: <Upload />,
  file: <File />,
  info: <Info />,
  clock: <Clock />,
  refresh: <Refresh />,
  checkCircle: <CheckCircle />,
  plusCircleSlim: <PlusCircleSlim />,
  userCheck: <UserCheck />,
  userPlus: <UserPlus />,
  messageThin: <MessageThin />,
  message: <MessageIcon />,
  login: <LoginIcon />,
  reset: <ResetIcon />,
  suspend: <SuspendIcon />,
  checkGreen: <CheckGreen />,
  plusWhite: <PlusIcon />,
  folderWhite: <FolderIcon />,
  helpWhite: <HelpIcon />,
  mailWhite: <MailIcon />,
  editWhite: <EditIcon />,
  arrowUpWhite: <ArrowUpIcon />,
  slidersWhite: <SlidersIcon />,
  fileWhite: <FileIcon />,
  play: <PlayIcon />,
  maximize: <Maximize />,
  minimize: <Minimize />,
  download: <BlueDownLoad />,
  folderBlue: <BlueFolder />,
  externalLink: <ExternalLink />,
  settings: <SettingsIcon />,
  downloadUp: <BlueDownLoadUp />,
  mailBlue: <MailBlue />,
  bell: <MobileBell />,
  mobileMessage: <MobileMessage />,
  mobileProfile: <MobileProfile />,
  mobileLogout: <MobileLogout />,
  columns: <Columns />,
  investor: <InvestorIcon />,
  sponsor: <SponsorIcon />,
  administrator: <AdministratorIcon />,
  server: <ServerIcon />,
  rss: <RssIcon />,
  briefcase: <BriefIcon />,
  pen: <Pen />,
  people: <UsersIcon />,
  list: <ListIcon />,
  paperclip2: <PaperClipIcon2 />,
  send: <SendIcon />,
  video: <VideoIcon />,
  img: <ImgIcon />,
  edit: <EditIcon2 />,
  profile: <ProfileIcon />,
  decline: <Decline />,
  accept: <Accept />,
  like: <LikeIcon />,
  share: <ShareIcon />,
  dots: <DotsIcon />,
  msg: <MsgIcon />,
  msgCircle: <MessageIconCircle />,
  link: <Link />,
  globe: <Globe />,
  equity: <Equity />,
  debt: <Debt />,
  trendUp: <TrendUp />,
  pin: <Pin />,
  more: <MoreHorisontal />,
  alarmClock: <AlarmClock />,
  horizontalMore: <HorizontalMore />,
  fullCheck: <FullCheck />,
  repeat: <Repeat />,
  xIcon: <XIcon />,
  saveCard: <SaveCard />,
  calendar: <Calendar />,
  pie: <Pie/>,
  noAvatar: <NoAvatar />,
  noAvatarFemale: <NoAvatarFemale />,
  avatarInstitution: <Institution />,
  blended: <BlendedIcon />,
  disc: <Disc />,
};
