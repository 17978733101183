import Api from "helpers/api";
import { getFileAndName } from "helpers/getFileAndName";

export default class DataRoomApi {
  static url = Api.url;

  static origin = Api.origin;

  static defaultAvatar = Api.defaultAvatar;

  static getAllFiles(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/assetsDataroom`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static toggleAssetStatus(token, id, status) {
    return new Promise(async (res) => {
      const result = await fetch(`${DataRoomApi.url}/api/asset/${status ? "deactivate" : "activate"}/${id}`, {
        method: "put",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      res(result.status === 200);
      await result.text();
    });
  }

  static getAttachment = Api.getAttachment;

  static getDealsFiles(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/getFoldersView/${id}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static createNewFolder(token, folderName, assetId, parentFolderId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/createFolder`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          assetId,
          folderName,
          parentFolderId,
        }),
      });
      // response return new id
      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static deleteFolder(token, folderId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/deleteFolder/${folderId}`, {
        method: "delete",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static moveFile(token, fileName, fromFolderName, newFolderName, newSubsection, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/move/file/${fileName}/${fromFolderName}/${newFolderName}/${newSubsection}/?assetId=${assetId}`,
        {
          method: "put",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static uploadFiles(token, formData, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/uploadFileToUnsorted/?assetId=${assetId}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
          },
          body: formData,
        }
      );
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static uploadFilesToFolder(token, formData, assetId, folderName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/uploadFiles/${folderName}?assetId=${assetId}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
          },
          body: formData,
        }
      );
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static uploadFilesInFolder(token, formData, folderName, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/upload/file/${folderName}?assetId=${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static deleteFile(token, fileId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/deleteFile/${fileId}`, {
        method: "delete",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(true);
      else {
        rej(await result.json());
      }
    });
  }

  static renameFolder(token, folderId, parentFolderId, folderName, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/renameFolder/${folderId}`, {
        method: "put",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          assetId,
          folderName,
          parentFolderId,
        }),
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static renameFile(token, fileId, fileName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/renameFile/${fileId}`, {
        method: "put",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ fileName }),
      });

      if (result.status === 200) res(true);
      else {
        rej(await result.json());
      }
    });
  }

  static renameLegalDocument(token, legalDocumentFilename, newLegalDocumentFilename, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/workflow/documents/rename/${assetId}/${legalDocumentFilename}/${newLegalDocumentFilename}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static searchFileOrFolder(token, assetId, searchWord = "") {
    return new Promise(async (res, rej) => {
      console.log(searchWord);
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/searchFileAndFolder/${searchWord}?assetId=${assetId}`,
        {
          method: "get",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static downloadFile = async (token, folderName, fileName, assetId) => {
    const requestFile = await fetch(
      `${DataRoomApi.url}/api/dataroom/get/file/${folderName}/${fileName}?assetId=${assetId}`,
      {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return await requestFile.blob();
  };

  static downloadFileFromModal(token, folderName, fileName, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/get/file/${folderName}/${fileName}?assetId=${assetId}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.blob());
      else {
        rej(await result.json());
      }
    });
  }

  static getAssetFilesStatistic(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/statistics/getFileViewStatistics/${assetId}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getChartData(token, assetId, period, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/statistics/getViewGraph/${assetId}/${period}?type=${type}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getHeatMap(token, assetId, period, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/statistics/getHeatMap/${assetId}/${period}?type=${type}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getRulesList(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/rules/getFileList/${assetId}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getDocumentRules(token, assetId, docType) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/rules/getFile/${assetId}/${docType}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static setDocumentRules(token, assetId, docType, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/workflow/documents/automate/${assetId}/${docType}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static setRulesList(token, assetId, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/rules/setRules/${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static searchHeatMap(token, assetId, days, type, value) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/statistics/searchInHeatMap/${assetId}/${days}?type=${type}&query=${value}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getUserViewStatistic(token, assetId, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/statistics/getFileViewStatisticsForOneUser/${assetId}/${userId}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getDocVariables(token, isAfterSubscription) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/legalDocument/placeholders/${isAfterSubscription}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static uploadLegalDocument(token, assetId, formData, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/workflow/documents/uploadLegalDocument/${assetId}/${type}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
          },
          body: formData,
        }
      );
      if (result.status === 200) {
        await result.json();
        res("success");
      } else rej(await result.json());
    });
  }

  static removeLegalDocument(token, assetId, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/workflow/delete/${assetId}/${type}`, {
        method: "delete",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static replaceLegalDocument(token, assetId, formData, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/workflow/documents/replace/${assetId}/${type}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        res(true);
      } else rej(await result.json());
    });
  }

  static checkLegalDocResult(token, assetId, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/legalDocument/check/${assetId}/${type}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.blob());
      } else {
        rej(await result.json());
      }
    });
  }

  static approveLegalDoc(token, assetId, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/workflow/documents/approve/${assetId}/${type}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.json());
      } else {
        rej(await result.json());
      }
    });
  }

  static switchStaringDoc(token, fileId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/changeStarredFileStatus/${fileId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }

  static switchWatermarkDoc(token, fileId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/pdfProcessing/setWatermark/${fileId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }

  static switchWatermarkFolder(token, folderId, set = true) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/pdfProcessing/${
          set ? "setWatermarkForFolder" : "unSetWatermarkForFolder"
        }/${folderId}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }

  static getInvestorDocuments(token, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/investorDocuments/${userId}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static downloadDocumentBlob = async (token, assetId, fileName, userId, type) => {
    return new Promise(async (res, rej) => {
      const requestFile = await fetch(
        `${DataRoomApi.url}/api/dataroom/investorDocument/file2/${userId}/${assetId}/${fileName}?type=${type}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (requestFile.status === 200) res(await requestFile.blob());
      else rej(await requestFile.json());
    });
  };

  static downloadInvestorLD = async (token, assetId, fileName, userId, type) => {
    return new Promise(async (res, rej) => {
      const requestFile = await fetch(
        `${Api.url}/api/dataroom/investorDocument/fileLink2/${userId}/${assetId}/${fileName}?type=${type}`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (requestFile.status === 200) res(await requestFile.text());
      else rej(await requestFile.json());
    });
  };

  static uploadUserDocument(token, assetId, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/attachment/uploadToUploadedFiles/${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        res("success");
      } else rej(await result.json());
    });
  }

  static removeUserDocument(token, fileId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/investor/deleteFromUploadedFile/${fileId}`, {
        method: "delete",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
      });
      if (result.status === 200) {
        res("success");
      } else rej(await result.json());
    });
  }

  static unWatermarkingFolder(token, assetId, folderName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/pdfProcessing/unSetWatermarkForFolder/${folderName}?assetId=${assetId}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }

  static getFileViewStatistic(token, id, days) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/statistics/getFileViewStatisticsByDays/${id}/${days}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getNegotiationLd(token, assetId, userId, legalDocumentType) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/negotiationLD/${assetId}/${userId}/${legalDocumentType}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static setNegotiationFromAdmin(token, assetId, userId, legalDocumentType, action) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/negotiationLD/${action}/${assetId}/${userId}/${legalDocumentType}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }

  static approveLdAndSetRules(token, assetId, legalDocumentFilename, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/workflow/documents/approveAndAutomate/${assetId}/${legalDocumentFilename}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static moveFolder(token, folderId, up = true) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/moveFolder${up ? "Up" : "Down"}/${folderId}`,
        {
          method: "put",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(true);
      else {
        rej(await result.json());
      }
    });
  }

  static downloadZipProgress(token, assetId, isInvestor = false, controller, onProgress) {
    return new Promise(async (res, rej) => {
      let result;
      try {
        result = await fetch(
          `${DataRoomApi.url}/api/${
            isInvestor ? "dataroom" : "admin/dataroom/admin"
          }/transferDataroomContent/${assetId}`,
          {
            headers: {
              OriginVoC: DataRoomApi.origin,
              Authorization: token,
              "Content-Type": "application/json",
            },
            ...(controller ? { signal: controller.signal } : {}),
          }
        );

        const reader = result.body.getReader();
        const contentLength = +result.headers.get("Content-Length") || 235; // It size of Error (approximately)
        let receivedLength = 0;
        let chunks = [];
        let i = 0;

        while (true) {
          await new Promise((_) => setTimeout(_, 0));
          onProgress((100 * i) / contentLength);

          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          chunks.push(value);
          receivedLength += value.length;
          onProgress((100 * receivedLength) / (contentLength || 1));
        }

        let chunksAll = new Uint8Array(receivedLength);
        let position = 0;

        for (let chunk of chunks) {
          chunksAll.set(chunk, position);
          position += chunk.length;
        }

        if (result.status === 200) {
          res(JSON.parse(new TextDecoder("utf-8").decode(chunksAll)));
        } else {
          rej(JSON.parse(new TextDecoder("utf-8").decode(chunksAll)));
        }
      } catch (_) {
        res(null);
      }
    });
  }

  static downloadZIP(token, assetId, isInvestor = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/${isInvestor ? "dataroom" : "admin/dataroom/admin"}/transferDataroomContent/${assetId}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await getFileAndName(result));
      else {
        rej(await result.json());
      }
    });
  }

  static sendMailsToInvestors(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/message/permittedInvestors`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static makeNegotiable(token, assetId, legalDocumentType, isNegotiable) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/negotiationLD/setNegotiable/${assetId}/${legalDocumentType}/${isNegotiable}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) {
        res(await result.json());
      } else {
        rej(await result.json());
      }
    });
  }

  static getNegotiable(token, assetId, legalDocumentType) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/negotiationLD/isNegotiable/${assetId}/${legalDocumentType}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static replaceFile(token, body, fileId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/replaceFile/${fileId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
        body,
      });
      if (result.status === 200) {
        res(true);
      } else rej(await result.json());
    });
  }

  static dragFolder(token, folderId, targetFolderId, section = 0) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/moveFolder/${folderId}`, {
        method: "put",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          section,
          targetFolderId,
        }),
      });

      if (result.status === 200) res(true);
      else {
        rej(await result.json());
      }
    });
  }

  static dragFile(token, fileId, targetFolderId, newSubsection = 0) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/moveFile/${fileId}/${targetFolderId}/${newSubsection}`,
        {
          method: "put",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(true);
      else {
        rej(await result.json());
      }
    });
  }

  static moveFileToUnsorted(token, fileId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/moveFileToUnsorted/${fileId}`, {
        method: "put",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(true);
      else {
        rej(await result.json());
      }
    });
  }

  static getDownloadLink(token, fileId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/fileLinkForDownload/${fileId}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static getWorkflowFileLink(token, name, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/fileLinkForLD`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, assetId }),
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static getDownloadLinkInvestorDoc(token, fileId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/investorDocument/fileLink/${fileId}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static getAccess(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/accessRules/${assetId}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static setAccess(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dataroom/admin/accessRules`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getDownloadLinkInvestor(token, fileId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/dataroom/admin/investorDocument/fileLinkForDownload/${fileId}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static drSettings(token, assetId, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/dataroom/admin/settings?assetId=${assetId}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        ...(data ? { method: "post", body: JSON.stringify(data) } : {}),
      });
      if (result.status === 200) {
        res(await result.json());
      } else rej(await result.json());
    });
  }
}
