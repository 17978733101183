// dev config
export default {
  origin: `https://bard3.valk-freemium.cf`,
  url: "https://valk-api-dev.cf",
  domain: ".valk-freemium.cf",
  logoStyles: {},
};

//deploy config
// export default {
//   origin: window.location.origin,
//   url: window.location.origin,
//   domain: ".valk-freemium.cf",
//   logoStyles: {},
// };
