export const currenciesModel = {
  GBP: {
    symbol: "&#163;",
    icon: null,
  },
  EUR: {
    symbol: "&#128;",
    icon: null,
  },
  USD: {
    symbol: "&#36;",
    icon: null,
  },
  CHF: {
    symbol: "&#8355;",
    icon: null,
  },
};
