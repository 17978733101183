import styled from "styled-components";
import { muliFont } from "components/common/mixins/index";

export const InputLabel = styled.div`
  ${muliFont};
  white-space: nowrap;
  font-weight: bold;
  text-transform: uppercase;

  letter-spacing: ${({ isInvestor }) => (isInvestor ? "-0.03em" : "0.05em")};
  margin-bottom: ${({ isInvestor }) => (isInvestor ? "8px" : "12px")};
  color: ${({ isInvestor }) => `rgba(24, 24, 24, ${isInvestor ? 0.6 : 0.4})`};

  display: block;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};

  ${({ asSelect, arrowPosition, arrow, height, width, padding }) => {
    if (asSelect) {
      return `
      box-sizing: border-box;
      font-size: 12px;
      line-height: 135%;
      font-weight: normal;
      letter-spacing: -0.02em;
      padding: ${padding ? padding : "12px 20px"};
      color: #181818;
      border: 1px solid #efefef;
      background-position: right;
      background-size: 16px;
      background-repeat: no-repeat;
      background-image: url(${arrow});
      height: ${height ? `${height}px` : "42px"};
      width: ${width ? width : "100%"};
      background-position-x: ${arrowPosition ? `calc(100% - ${arrowPosition}px)` : "calc(100% - 24px)"};
    `;
    }
  }}
`;
