import styled from "styled-components";
import React, { useState, Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "components/common/circularProgress";

import { Btn, SmallButton } from "components/admin-layout/buttons";
import { ChartDateComponent, TextAreaComponent } from "components/admin-layout/forms";
import { User } from "components/admin-layout/dealTypeCard";
import { SignedDocument } from "components/admin-layout/pages/UserKyc/styled";
import { PortalTooltipWrapper } from "components/admin-layout/portalTooltipWrapper";

import { ReactComponent as CloseIcon } from "assets/x-circle.svg";
import { ReactComponent as Plus } from "assets/plus-circle.svg";
import { ReactComponent as Eye } from "assets/eye.svg";
import { ReactComponent as EyeOff } from "assets/eye-off.svg";
import { ReactComponent as Mess } from "assets/message-square.svg";
import { ReactComponent as Rem } from "assets/x-red-small.svg";
import { ReactComponent as Folder } from "assets/folder-green.svg";
import { ReactComponent as Disc } from "assets/disc.svg";
import { ReactComponent as BlendedIcon } from "assets/blended.svg";
import { ReactComponent as CrossIcon } from "assets/x.svg";
import { File } from "components/common/icons-primary";
import { toggleDemoMessage } from "modules/dispachActions";
import Api from "helpers/api";
import theme from "models/themes";
import { GreyHeader } from "components/admin-layout/errorMessage";
import { flexCenter, fullVwVh, muliFont, column, borderBox } from "components/common/mixins";

export const ProgressContainer = styled.div`
  height: 10px;
  background: ${({ empty, theme: { primaryLight } }) => (empty ? "#F9F9F9" : primaryLight)};
  display: flex;
`;

export const Progress = styled.div`
  height: 10px;
  background: ${({ theme: { primaryMiddle } }) => primaryMiddle};
  width: ${({ width }) => `${width}%`};
  transition: width
    ${({ isDashBoard, isLoad }) =>
      isDashBoard ? "0.9s linear" : isLoad ? ".1s linear" : "1.5s cubic-bezier(0.22, 0.61, 0.36, 1)"};
`;

export const ProgressBar = ({ sold, unsold, soldSharesPercent = false, isDashBoard = false }) => {
  const [progressWidth, setProgressWidth] = useState(0);

  setTimeout(
    () => {
      if (soldSharesPercent) {
        setProgressWidth(soldSharesPercent);
      } else {
        const w = (sold / (sold + unsold)) * 100;
        setProgressWidth(isNaN(w) ? 0 : w);
      }
    },
    isDashBoard ? 0 : 1000
  );
  return (
    <ProgressContainer empty={!sold && !unsold}>
      <Progress isDashBoard={isDashBoard} width={progressWidth} />
    </ProgressContainer>
  );
};

export const Row = styled.div`
  display: flex;
  margin-bottom: ${({ mb = "15px" }) => mb};
`;

const Column = styled.div`
  ${column}
  margin-right: ${({ mRight = 60 }) => `${mRight}px`};
  width: ${({ width }) => width};
  align-items: flex-start;
  overflow: hidden;
`;

const UsersColumn = styled.div`
  ${column}
  margin-left: ${({ mLeft }) => `${mLeft}px`};
`;

const Heading = styled.div`
  white-space: nowrap;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: rgba(24, 24, 24, 0.6);
`;

export const Data = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.05em;
  color: #181818;
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
  white-space: nowrap;
`;

const Cirlcle = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-left: 6px;
  margin-right: ${({ mRight }) => (mRight ? `${mRight}px` : "0")};
`;

export const DataColumn = ({ mRight, heading, data, circleColor = false, width, childrenWidth }) => {
  return (
    <Column mRight={mRight} width={width}>
      <Heading>{heading}</Heading>
      <Data width={childrenWidth}>
        {data}
        {circleColor && <Cirlcle color={circleColor} />}
      </Data>
    </Column>
  );
};

const Period = styled.div`
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.4)")};
`;

const FilterRow = styled.div`
  display: flex;
  ${({ left = false }) => (left ? "margin-right: auto;" : "margin-left: auto;")};

  align-items: center;
  color: rgba(24, 24, 24, 0.4);
  height: fit-content;
  > span {
    margin: 0 3px;
  }
`;

export const Filter = ({ defaultValue = "all", callback = console.log }) => {
  const [active, setActive] = useState(defaultValue);
  const filter = (period) => {
    setActive(period);
    callback(period);
  };
  return (
    <FilterRow>
      <Period active={active === "all"} onClick={() => filter("all")}>
        all
      </Period>
      <span>/</span>
      <Period active={active === "day"} onClick={() => filter("day")}>
        day
      </Period>
      <span>/</span>
      <Period active={active === "week"} onClick={() => filter("week")}>
        week
      </Period>
      <span>/</span>
      <Period active={active === "month"} onClick={() => filter("month")}>
        month
      </Period>
    </FilterRow>
  );
};

const ChartRow = styled.div`
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #181818;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ChartPercent = styled.span`
  color: #181818;
  margin-left: 3px;
`;

export const ChartData = ({ sold, unsold }) => {
  return (
    <Fragment>
      <ChartRow>
        <Cirlcle mRight={7} color={theme.primaryLight} />
        Unsold shares
        <ChartPercent>({((unsold / (sold + unsold)) * 100).toFixed(2)}%)</ChartPercent>
      </ChartRow>
      <ChartRow>
        <Cirlcle mRight={7} color={theme.primaryMiddle} />
        Sold shares <ChartPercent>({((sold / (sold + unsold)) * 100).toFixed(2)}%)</ChartPercent>
      </ChartRow>
    </Fragment>
  );
};

export const ActivityHeading = styled.div`
  font-weight: bold;
  font-size: ${({ fz = "10px" }) => fz};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-bottom: 4px;
  white-space: ${({ ws }) => (ws ? "nowrap" : "normal")};
`;

export const Activity = styled.div`
  font-weight: bold;
  font-size: ${({ activityFz = "14px" }) => activityFz};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #181818;
`;

export const ActivityData = ({ data }) => {
  const { totalViews, totalUsers, averagePerUser } = data;
  return (
    <Fragment>
      <UsersColumn>
        <ActivityHeading>USERS</ActivityHeading>
        <FilterRow left>
          <Activity>{totalUsers || "-"}</Activity>
        </FilterRow>
      </UsersColumn>
      <UsersColumn mLeft={35}>
        <ActivityHeading>VIEWS</ActivityHeading>
        <FilterRow left>
          <Activity>{totalViews || "-"}</Activity>
        </FilterRow>
      </UsersColumn>
      <UsersColumn mLeft={35}>
        <ActivityHeading>AVG. time per user</ActivityHeading>
        <FilterRow left>
          <Activity>{averagePerUser || "-"}</Activity>
        </FilterRow>
      </UsersColumn>
    </Fragment>
  );
};

export const FoldersData = ({ files, folders }) => {
  return (
    <Fragment>
      <UsersColumn>
        <ActivityHeading>FILES </ActivityHeading>
        <FilterRow left>
          <Activity>{files || "-"}</Activity>
        </FilterRow>
      </UsersColumn>
      <UsersColumn mLeft={59}>
        <ActivityHeading>FOLDERS</ActivityHeading>
        <FilterRow left>
          <Activity>{folders || "-"}</Activity>
        </FilterRow>
      </UsersColumn>
    </Fragment>
  );
};

const ButtonsRow = styled.div`
  display: flex;
  margin-left: 30px;
  margin-right: auto;
`;

const DatesRow = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;

  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
`;

const Line = styled.div`
  width: 16px;
  height: 1px;
  background: #181818;
  margin: 0 9px;
`;

export const ChartFilter = ({ periods, selected, onChangeZoom }) => {
  return (
    <ButtonsRow>
      {periods.map((el, i) => {
        return (
          <Btn margin="0 6px 0 0" width={80} transparent={i !== selected} key={el} onClick={onChangeZoom(i)}>
            {el}
          </Btn>
        );
      })}
    </ButtonsRow>
  );
};

export const DateFilter = ({ onChangeStart, onChangeEnd }) => {
  return (
    <DatesRow>
      <ChartDateComponent onChange={onChangeStart} />
      <Line />
      <ChartDateComponent onChange={onChangeStart} />
    </DatesRow>
  );
};

export const ScrollContainer = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "300px")};
  padding-right: ${({ isScroll = true }) => (isScroll ? "25px" : "0px")};
  padding-left: ${({ pLeft }) => (pLeft ? `${pLeft}px` : "0")};
  ${borderBox}
  transform: translateX(-15px);
  width: calc(100% + 15px);
  overflow-y: ${({ isScroll = true }) => (isScroll ? "scroll" : "hidden")};
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const RequestWrapper = styled.div`
  background: ${({ theme: { adminTableRowGrey } }) => adminTableRowGrey};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border: 1px solid ${({ answered, theme: { primary } }) => (answered ? primary : "transparent")};
  ${borderBox}
  border-radius: 2px;
  padding: 18px;

  transition: all 0.3s linear;
  margin-bottom: 10px;
`;

const RequestRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ noMargin }) => (noMargin ? "0" : "8px")};
`;

const Message = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 11px;
  color: #333333;
`;

const DateStyled = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.4);
`;

const Answer = styled(Link)`
  text-decoration: none;

  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ answered, theme: { primary } }) => (answered ? primary : "rgba(24, 24, 24, 0.4)")};
  transition: all 0.3s linear;
  cursor: pointer;
`;

export const Request = ({ token, userName, logo, message, date }) => {
  const [answer, setAnswer] = useState(false);

  return (
    <RequestWrapper answered={answer}>
      <RequestRow>
        <User border token={token} userName={userName} logo={logo} />
        {answer && <Cirlcle color={theme.primary} />}
      </RequestRow>
      <Message>{message}</Message>
      <RequestRow noMargin>
        <DateStyled>{date}</DateStyled>
        <Answer to="/admin/chat">chat</Answer>
      </RequestRow>
    </RequestWrapper>
  );
};

export const CalendarWrapper = styled.div`
  border-bottom: 1px solid #efefef;
  margin-bottom: 21px;

  & > div {
    width: 100% !important;
  }

  & .react-calendar__navigation {
    button.react-calendar__navigation__label {
      pointer-events: none;
    }
  }

  & {
    .react-calendar__month-view__weekdays__weekday,
    .react-calendar__month-view__days button {
      min-width: 14.2857% !important;
      max-width: 14.2857% !important;
      margin-right: 0 !important;
      &.react-calendar__tile--now.react-calendar__tile--active {
        & > * {
          position: absolute;
          z-index: 1;
        }

        &::after {
          content: "";
          position: absolute;
          display: block;
          background: ${({ theme: { primary } }) => primary};
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth * {
    color: #e1e4e7 !important;
  }
`;

const EventRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const EventText = styled.div`
  font-weight: ${({ fWeight }) => (fWeight ? fWeight : "600")};
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  margin-right: 8px;
  color: #181818;
`;

export const EventsList = ({ events }) => {
  const renderEvents = () => {
    return events.map((e) => {
      const { type, date } = e;
      return (
        <EventRow key={date}>
          <Cirlcle mRight={7} color={type} />
          <EventText>{date}</EventText>
          <EventText fWeight={400}>Some event</EventText>
        </EventRow>
      );
    });
  };

  return <Fragment>{renderEvents()}</Fragment>;
};

const BorderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 25px 15px;
  border-bottom: 1px solid #efefef;
  ${borderBox}
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const SignedColumn = styled.div`
  width: ${({ width }) => `${width}%`};
  ${borderBox}
  ${column}
`;

export const Logo = styled.img`
  width: ${({ w = "62px" }) => w};
  height: ${({ h = "62px" }) => h};
  border-radius: 50%;
  margin-bottom: ${({ mb = "11px" }) => mb};
  margin-right: ${({ mr = "0px" }) => mr};
  object-fit: cover;
`;

const UseName = styled(Link)`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding-right: 10px;
  padding-bottom: 4px;
  border-bottom: 0.5px solid rgba(98, 128, 218, 0.2);
  width: fit-content;
  ${borderBox}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: -0.02em;
  color: ${({ theme: { primary } }) => primary};
  text-decoration: none;
  margin-bottom: 20px;
`;

const LoiSpaBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 140px;
`;

const DocStatus = styled.div`
  width: 50%;
  ${column}

  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  > div {
    font-size: 12px;
    color: #181818;
    margin-top: ${({ mTop }) => (mTop ? mTop : "9px")};
    text-transform: none;
  }
  > svg {
    margin-top: 8px;
    width: 14px;
    height: 14px;
    path {
      stroke: green;
    }
  }
`;

const NoDoc = styled.div`
  width: 18px;
  height: 1px;
  background: #181818;
  margin-top: 15px;
`;

export const Empty = styled.div`
  width: ${({ w = "100%" }) => w};
  height: ${({ h = "100%" }) => h};
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  margin: ${({ m = 0 }) => m};
  border-radius: 2px;
  ${borderBox}
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  color: #181818;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-direction: column;
  > svg {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }
`;

export const SignedDoc = ({ token, doc, newWorkflow, onError = console.log, pro = false }) => {
  const { fullname, logo, loiValue, spaSigned, documentAndDate } = doc;

  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (logo && !avatar && logo.fileNameWithExtension && logo.secureHash) {
      Api.getAttachment(token, logo.secureHash, logo.fileNameWithExtension).then((blob) =>
        setAvatar(blob || Api.defaultAvatar)
      );
    } else {
      setAvatar(Api.defaultAvatar);
    }
  }, []);

  const renderNewDocs = () => {
    return documentAndDate.map((file) => (
      <SignedDocument
        {...{ onError, file: { ...file, fullName: fullname, avatar }, token, pro, key: file.id, isPdf: true }}
      />
    ));
  };

  return (
    <Fragment>
      {pro && newWorkflow ? (
        <Fragment>{renderNewDocs()}</Fragment>
      ) : (
        <BorderContainer>
          <SignedColumn width={33}>
            {avatar && <Logo src={avatar} alt="logo" />}
            <UseName>{fullname}</UseName>
            <LoiSpaBlock>
              <DocStatus mTop={!loiValue ? "15px" : ""}>LOI {loiValue ? <div>{loiValue}</div> : <NoDoc />}</DocStatus>
              <DocStatus mTop={spaSigned ? "8px" : "15px"}>SPA {spaSigned ? <div>Signed</div> : <NoDoc />}</DocStatus>
            </LoiSpaBlock>
          </SignedColumn>
          <SignedColumn width={67}>
            {newWorkflow ? (
              <Fragment>{renderNewDocs()}</Fragment>
            ) : (
              <Fragment>
                {!doc.ndaDoc && !doc.loiDoc && !doc.spaDoc ? (
                  <Empty>
                    <File /> <div>No signed documents yet</div>
                  </Empty>
                ) : (
                  <Fragment>
                    {doc.ndaDoc && <SignedDocument doc={doc.ndaDoc} isPdf token={token} />}
                    {doc.loiDoc && <SignedDocument doc={doc.loiDoc} isPdf token={token} />}
                    {doc.spaDoc && <SignedDocument doc={doc.spaDoc} isPdf token={token} />}
                  </Fragment>
                )}
              </Fragment>
            )}
          </SignedColumn>
        </BorderContainer>
      )}
    </Fragment>
  );
};

export const ModalBG = styled.section.attrs(() => ({
  "data-lvl-global": true,
}))`
  width: 100vw;
  height: ${({ height }) => (height ? height : "100vh")};
  position: fixed;
  top: 0;
  left: 0;
  ${borderBox}
  padding: 90px;
  background: rgba(0, 0, 0, 0.15);
`;

export const ModalWrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : "100%")};
  background: #ffffff;
  border: 1px solid #f9f9f9;
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  padding: ${({ padding }) => (padding ? padding : "25px 30px")};
  ${column}
  ${borderBox}
  height: ${({ height }) => (height ? height : "100%")};
  margin: ${({ margin }) => margin};
`;

export const ScrollContainerRegister = styled.div`
  height: 100%;
  padding-right: 8px;
  ${borderBox}
  width: calc(100% + 15px);
  overflow-y: ${({ isScroll = true }) => (isScroll ? "scroll" : "hidden")};
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ isFooter = false }) => (isFooter ? "auto" : "0")};
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const ModalHeading = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

export const TableWrapper = styled.div.attrs(() => ({
  "data-lvl-component": true,
}))`
  ${column}
  padding: ${({ padding = "0 0 15px 0" }) => padding};
  margin: 10px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  /* z-index: 1000; */
  height: calc(100% - 93px);
  width: ${({ width }) => (width ? width : "auto")};
`;

export const DocsWrapper = styled.div.attrs(() => ({
  "data-lvl-component": true,
}))`
  ${column}
  padding: ${({ padding = false }) => (padding ? padding : "25px 0")};
  margin: 20px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  /* z-index: 1000; */
  ${({ height = "" }) => (height ? `height: ${height};` : "")}
`;

const WaitingWrapper = styled.div.attrs(() => ({ "data-lvl-local": true }))`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = () => (
  <WaitingWrapper>
    <CircularProgress />
  </WaitingWrapper>
);

export const BorderBlock = styled.div`
  display: flex;
  border-left: 1px solid #f1f3f8;
  padding-left: 25px;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
`;

export const BlurBg = styled.div.attrs(() => ({ "data-lvl-global": true }))`
  ${fullVwVh}
  ${borderBox}
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  ${flexCenter}
`;

export const ValBorderBlock = styled.div`
  width: 100%;
  height: 108px;
  border-bottom: 1px solid #f6f7fc;
  ${borderBox}
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ValFooter = styled.div`
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FullBg = styled.section`
  ${fullVwVh}
  background: transparent;
  display: flex;
  > div {
    margin: auto;
  }
`;

const NoteContainer = styled.div`
  position: relative;

  > div {
    display: block;
  }
  &:hover {
    > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

const NoteFullText = styled.p`
  ${muliFont}
  text-transform: none;
  color: #181818;
  margin: 8px 0 0;
  white-space: normal;
`;

const NoteItemContainer = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #efefef;
`;

const RemoveNote = styled.div`
  display: flex;
  width: 68px;
  height: 14px;
  cursor: pointer;
  align-items: center;

  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ff4343;
  margin-top: 12px;
  svg {
    width: 11px;
    height: 11px;
    margin-right: 6px;
    path {
      stroke: #ff4343;
    }
  }
`;

const _Modal = styled.div`
  width: 440px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
`;

const NotesWrapper = styled.div`
  width: 100%;
  height: calc(100% - 52px);
  ${column}
  ${borderBox}
  padding: 20px 25px;
`;

const _ScrollContainer = styled.div`
  max-height: 280px;
  padding-right: 22px;
  ${borderBox}
  overflow-y: scroll;
  margin-bottom: 20px;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const _Heading = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
  text-transform: none;
`;

const RowFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const _Date = styled.div`
  letter-spacing: 0.05em;

  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
  height: 14px;
  display: flex;
  align-items: center;
  svg {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    path {
      stroke: rgba(24, 24, 24, 0.4);
    }
  }
`;

const NoteText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  margin: 8px 0;
  color: #181818;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: normal;
`;

const LastUpdate = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
`;

export const NoteButton = styled.button`
  width: ${({ w = "76px" }) => w};
  height: 24px;
  ${borderBox}
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s linear all;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  position: relative;
  outline: none;
  > svg {
    transition: 0.3s linear all;
    margin-right: 7px;
    width: 13px;
    height: 13px;
    path {
      stroke: ${({ isNote, wasRead, theme: { primary } }) => (isNote ? (wasRead ? "#8A8A8A" : primary) : "#FFFFFF")};
    }
  }
  background: ${({ isNote, theme: { primary } }) => (isNote ? "transparent" : primary)};
  color: ${({ isNote, wasRead, theme: { primary } }) => (isNote ? (wasRead ? "#8A8A8A" : primary) : "#FFFFFF")};
  border: 1px solid ${({ isNote, wasRead, theme: { primary } }) => (isNote ? (wasRead ? "#8A8A8A" : primary) : primary)};
`;

const NoNotes = styled.div`
  text-transform: none;
  width: 100%;
  background: #f2f4f7;
  border: 1px solid #f0f0f0;
  ${borderBox}
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  ${muliFont}
  text-align: center;
  color: #181818;
  margin-bottom: 20px;
  flex-direction: column;
  > svg {
    width: 32px;
    height: 32px;
    margin-bottom: 18px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const NoteItem = ({ note: { date, content, read }, onRemove, role, key }) => {
  return (
    <NoteItemContainer key={`note-${key}`}>
      <RowFlex>
        <_Date>
          UPDATED {!read ? "from " : null}
          {moment(date).format("DD.MM.YYYY, hh:mm")}
        </_Date>
        {read ? (
          <Fragment>
            <_Date>
              <Eye />
              READ {moment(read).format("DD.MM.YYYY, hh:mm")}
            </_Date>
          </Fragment>
        ) : null}
      </RowFlex>
      <NoteFullText dangerouslySetInnerHTML={{ __html: content }} />
      {role !== "SPONSOR" ? (
        <RemoveNote onClick={onRemove}>
          <Rem /> <span>remove</span>
        </RemoveNote>
      ) : null}
    </NoteItemContainer>
  );
};

export const Note = ({ token, notes = [], userName, assetId, role, fullName, onRefresh, demo = false }) => {
  const [notesOpen, setNotesOpen] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [wait, setWait] = useState(false);
  const [notesList, setNotes] = useState([...notes]);
  const [wasRead, setWasRead] = useState(notes.every((e) => e.read));
  const noteRef = useRef(null);
  const scrollRef = useRef(null);
  const noNoteRef = useRef(null);
  const debounceRef = useRef(null);

  const [show, setShow] = useState(false);
  const [showNoNotes, setShowNoNotes] = useState(false);

  const onChangeModal = () => {
    setNotesOpen(!notesOpen);
  };

  const renderNotes = () =>
    notesList.map((n, i) => <NoteItem key={n.date} note={n} onRemove={() => onRemoveNote(n, i)} role={role} />);

  const onRemoveNote = async (note, i) => {
    setWait(true);
    const del = await Api.removeNote(token, assetId, userName, note).catch(() => setWait(false));

    if (del) {
      setWait(false);
      onRefresh();
    }
  };

  const onAddNote = async () => {
    setWait(true);
    const _note = { date: Date.now(), content: newNote.replace(/\n/g, "<br>") };

    const res = await Api.createNote(token, assetId, userName, _note).catch(() => setWait(false));

    if (res) {
      setWait(false);
      setNewNote("");
      onRefresh();
    }
  };

  const overContainer = () => {
    if (notesList.length) {
      setShow(true);
    } else setShowNoNotes(true);
  };

  const onReadAllNotes = async () => {
    if (!wasRead) {
      const res = await Api.readNotes(token, assetId, userName);
      if (res) {
        onRefresh();
      }
    }
  };

  const onOpenModal = () => {
    onReadAllNotes();
    onChangeModal();
  };

  const countNotRead = () => {
    let count = 0;
    notes.forEach((e) => {
      if (!e.read) count++;
    });
    return count;
  };

  useEffect(() => {
    setNotes([...notes]);
    setWasRead(notes.every((e) => e.read));

    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      if (scrollRef && scrollRef.current && notes.length) {
        scrollRef.current.scrollBy(0, 10000000);
      }
    }, 350);
  }, [notes]);

  return (
    <NoteContainer>
      <NoteButton
        wasRead={wasRead}
        isNote={notes.length}
        ref={notes.length ? noteRef : noNoteRef}
        onMouseOver={overContainer}
        disabled={role === "SPONSOR" ? !notes.length : false}
      >
        {notes.length ? wasRead ? <Eye /> : <Mess /> : <Plus />}
        Note
      </NoteButton>
      {show && notesList.length && !notesOpen ? (
        <PortalTooltipWrapper noPseudo anchorRef={noteRef} onLeave={() => setShow(false)}>
          <Fragment>
            <RowFlex>
              <LastUpdate> {wasRead ? "UPDATE" : `LAST UPDATE (${countNotRead()})`}</LastUpdate>
              <_Date>
                {moment(notesList[notesList.length - 1] && notesList[notesList.length - 1].date).format(
                  "DD.MM.YYYY, hh:mm"
                )}
              </_Date>
            </RowFlex>
            <NoteText
              dangerouslySetInnerHTML={{
                __html: notesList[notesList.length - 1] && notesList[notesList.length - 1].content,
              }}
            />
            <ReadStatus>
              {wasRead ? (
                <Fragment>
                  <Eye />
                  READ,{" "}
                  {moment(notesList[notesList.length - 1] && notesList[notesList.length - 1].read).format(
                    "DD.MM.YYYY, hh:mm"
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <EyeOff />
                  NOT READ YET
                </Fragment>
              )}
            </ReadStatus>
            <NoNote>
              <NoteLink onClick={demo ? toggleDemoMessage : onOpenModal}>
                <Plus />
                ADD NEW NOTE
              </NoteLink>
              <NoteLink blue onClick={demo ? toggleDemoMessage : onOpenModal}>
                all updates
              </NoteLink>
            </NoNote>
          </Fragment>
        </PortalTooltipWrapper>
      ) : null}

      {showNoNotes && !notesOpen ? (
        <PortalTooltipWrapper noPseudo anchorRef={noNoteRef} onLeave={() => setShowNoNotes(false)}>
          <Fragment>
            <NoNote>
              <NoteLink onClick={demo ? toggleDemoMessage : onChangeModal}>
                <Plus />
                ADD NEW NOTE
              </NoteLink>
              <NoteLink disabled>
                <CloseIcon style={{ rotate: "45deg" }} />
                NO NOTES YET
              </NoteLink>
            </NoNote>
          </Fragment>
        </PortalTooltipWrapper>
      ) : null}

      {notesOpen ? (
        <ModalBG padding="0" display="flex" onClick={onChangeModal}>
          <_Modal onClick={(e) => e.stopPropagation()}>
            <GreyHeader modal>
              <_Heading>{fullName} Notes</_Heading>
              <CrossIcon className="close-icon" onClick={onChangeModal} />
            </GreyHeader>

            <NotesWrapper>
              {notesList.length ? (
                <_ScrollContainer ref={scrollRef}>{renderNotes()}</_ScrollContainer>
              ) : (
                <NoNotes>
                  <Mess />
                  There are no notes yet
                </NoNotes>
              )}

              {role !== "SPONSOR" ? (
                <Fragment>
                  <TextAreaComponent
                    key={`input-${notesList.length}`}
                    label="New Note"
                    placeholder="Write new update here"
                    areaHeight={64}
                    minHeight={64}
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                  />
                  <Btn width={180} height={36} margin="15px 0 0" onClick={onAddNote} disabled={wait}>
                    {notesList.length ? "update" : "add note"}
                  </Btn>
                </Fragment>
              ) : null}
            </NotesWrapper>
          </_Modal>
        </ModalBG>
      ) : null}
    </NoteContainer>
  );
};

const NoNote = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ReadStatus = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  user-select: none;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  svg {
    width: 13px;
    height: 13px;
    margin-right: 7px;
    path {
      stroke: rgba(24, 24, 24, 0.6);
    }
  }
`;

const NoteLink = styled.div`
  display: flex;
  align-items: center;

  font-style: normal;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  user-select: none;
  cursor: ${({ disabled = false }) => (disabled ? "" : "pointer")};
  font-weight: ${({ disabled = false, blue }) => (disabled || blue ? "600" : "800")};
  color: ${({ disabled = false, theme: { primary } }) => (disabled ? "rgba(24, 24, 24, 0.6)" : primary)};
  > svg {
    width: 13px;
    height: 13px;
    margin-right: 7px;
    path {
      stroke: ${({ disabled = false, theme: { primary } }) => (disabled ? "rgba(24, 24, 24, 0.6)" : primary)};
    }
  }
`;

export const Col = styled.div`
  width: ${({ w = "440px" }) => w};
`;

export const Separator = styled.div`
  height: ${({ h = "30" }) => `${h}px`};
`;
export const LeftBlock = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  padding: 11px 15px;
  background: ${({ disabled, theme: { quoteBgInvestor } }) => (disabled ? "#dddddd" : quoteBgInvestor)};
  width: calc(20% - 7.5px);
  height: 50px;
  border-radius: 2px;
  align-items: center;
  ${borderBox}
  margin-left: ${({ ml, ent = false }) => (ent ? "auto" : ml)};
  margin-right: ${({ mr }) => mr};
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  transition: background 0.15s linear;
  &:hover {
    background: #e9ecfa;
  }
`;

export const TextLink = styled.div`
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  color: #333333;
  margin-left: 12px;
`;

export const CircleLink = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  background: ${({ ent, theme: { primary, enterprice } }) => (ent ? enterprice : primary)};
  > svg {
    margin: auto;
  }
`;

export const FlexBlock = styled.div`
  display: flex;
  margin-right: ${({ mr = 0 }) => mr};
`;

export const FilterCounter = styled.div`
  display: flex;
  ${flexCenter}
  position: absolute;
  width: 14px;
  height: 14px;
  right: -7px;
  top: -7px;
  background: #4b68c5;
  font-weight: 800;
  font-size: 9px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #ffffff;
  border-radius: 50%;
`;

export const DetailsLink = ({
  text = "",
  icon = null,
  ent = false,
  mr = "",
  onClick = console.log,
  disabled = false,
}) => {
  return (
    <LinkWrapper disabled={disabled} ent={ent} mr={mr} onClick={!disabled ? onClick : undefined}>
      <CircleLink ent={ent}>{icon}</CircleLink>
      <TextLink>{text}</TextLink>
    </LinkWrapper>
  );
};

export const PanelIcon = ({
  icon = null,
  transparent = false,
  margin = "",
  onClick = console.log,
  disabled,
  scale = false,
  counter = 0,
  width,
  height,
}) => {
  return (
    <SmallButton
      width={width}
      height={height}
      transparent={transparent}
      margin={margin}
      onClick={onClick}
      disabled={disabled}
      scaleSvg={scale}
    >
      {counter ? <FilterCounter>{counter}</FilterCounter> : null}
      {icon}
    </SmallButton>
  );
};

export const Flex = styled.div`
  overflow: hidden;
  flex: ${({ flex = 1 }) => flex};
  padding-right: 10px;
  ${borderBox}
`;

export const DealType = styled.div`
  display: flex;
  justify-content: ${({ justify = "space-between" }) => justify};
  font-weight: 600;
  font-size: ${({ font = 14 }) => `${font}px`};
  line-height: 135%;
  color: ${({ debt = false, isBlended, theme: { primary } }) => (debt ? "#6454A3" : isBlended ? primary : "#31adbf")};
  align-items: center;
`;

export const FinancialType = ({ mRight, heading, data, openEndFund = false }) => {
  const formatedData = data[0] + data.toLowerCase().split("").slice(1).join("");
  const debt = data === "DEBT";
  const isBlended = data === "BLENDED";

  return (
    <Column mRight={mRight}>
      <Heading>{heading}</Heading>
      <DealType debt={debt} isBlended={isBlended} font={12}>
        {debt ? (
          <Disc style={{ marginRight: 7 }} />
        ) : isBlended ? (
          <BlendedIcon style={{ marginRight: 7 }} />
        ) : (
          <Folder style={{ marginRight: 7 }} />
        )}
        {openEndFund ? "Open-End Fund" : formatedData}
      </DealType>
    </Column>
  );
};

export const MessageDemo = styled.div`
  height: 60px;
  background: #ffffff;
  border: 1px solid ${({ theme: { primary } }) => primary};
  ${borderBox}
  border-radius: 4px;
  padding: 18px 25px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin-bottom: 30px;
  width: 100%;
  > svg {
    margin-right: 14px;
    width: 24px;
    height: 24px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  > div {
    margin-left: auto;
    color: ${({ theme: { red } }) => red};
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: ${({ isWait = false }) => (isWait ? 0.5 : 1)};
    > svg {
      margin-right: 6px;
      width: 14px;
      height: 14px;
      path {
        stroke: ${({ theme: { red } }) => red};
      }
    }
  }
`;
