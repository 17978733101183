import styled from "styled-components";
import React from "react";

import { CircularProgress } from "components/common/circularProgress";

import { flexCenter, column, border4, borderBox } from "components/common/mixins";
import { ButtonLink } from "components/common/buttonLink";

export const SetupWrapper = styled.section`
  display: flex;
  flex-direction: ${({ mailing = false }) => (mailing ? "row" : "column")};
  width: 100%;
  min-height: ${({ mh = "calc(100vh - 214px)" }) => mh};
  justify-content: space-between;
`;

export const Column = styled.div`
  ${column}
  width: ${({ fullWidth, width }) => (fullWidth ? "100%" : width ? width : "calc(50% - 17.5px)")};
`;

export const MailColumn = styled.div`
  ${column}
  width: ${({ small = false }) => (small ? "348px" : "calc(100% - 378px)")};
`;

export const NewMailColumn = styled.div`
  ${column}
  width: ${({ small = false }) => (small ? "323px" : "calc(100% - 353px)")};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width = "100%" }) => width};
  margin: ${({ m = 0 }) => m};
`;

export const Separator = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "35px")};
`;

const Item = styled.div`
  width: 100%;
  height: 62px;
  background: #ffffff;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #181818;
`;

export const EditItem = ({ name, to }) => {
  return (
    <Item>
      <Name>{name}</Name>
      <ButtonLink to={to} transparent>
        Edit
      </ButtonLink>
    </Item>
  );
};

export const Heading = styled.h1`
  margin: 0 0 20px 0;
  height: 27px;
  font-weight: bold;
  font-size: 20px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #192036;
  margin-top: ${({ mTop = false }) => (mTop ? mTop : "")};
`;

const Loading = styled.div`
  width: 100%;
  height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Waiting = () => {
  return (
    <Loading>
      <CircularProgress />
    </Loading>
  );
};

const WaitingWrapper = styled.div.attrs(() => ({ "data-lvl-local": true }))`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  /* z-index: 1001; */
`;

export const WaitingFull = () => (
  <WaitingWrapper>
    <CircularProgress />
  </WaitingWrapper>
);

export const Card = styled.div`
  width: ${({ w = "100%" }) => w};
  padding: ${({ p = "20px 25px 30px" }) => p};
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: ${({ mb = "30px" }) => mb};
  box-sizing: border-box;
  ${column}
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mb = "25px" }) => mb};
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 135%;
    color: #181818;
    margin: 0;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135%;
    color: ${({ theme: { primary } }) => primary};
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: 13px;
      height: 13px;
      margin-right: 7px;
      path {
        stroke: ${({ theme: { primary } }) => primary};
      }
    }
  }
`;

export const CenterContainer = styled.div`
  margin: auto;
  color: ${({ theme: primary }) => primary};
`;

export const Uploader = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ padding = "" }) => padding};
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  background: ${({ transparent, theme: { quoteBgInvestor } }) => (transparent ? "transparent" : quoteBgInvestor)};
  border-radius: 4px;
  min-height: 190px;
  color: ${({ theme: { primary } }) => primary};

  h1 {
    margin: 0 0 12px 0;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 135%;
    color: #181818;
    text-align: center;
  }
  h2 {
    width: 100%;

    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(24, 24, 24, 0.6);
    margin: 0 0 12px 0;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    color: #181818;
    width: 185px;
    text-align: center;
    margin-bottom: 18px;
    span {
      cursor: pointer;
      color: ${({ theme: { primary } }) => primary};
      text-decoration: underline;
    }
  }
`;

export const FileCard = styled.div`
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 21px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 12px 14px;
  svg {
    width: 18px;
    height: 18px;
    :first-child {
      margin-right: 15px;
      path {
        stroke: ${({ theme: { primary } }) => primary};
      }
    }
    :last-child {
      margin-left: auto;
      cursor: pointer;
      path {
        stroke: #ff4343;
      }
    }
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #181818;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 70px);
  }
`;

export const FileInfo = styled.div`
  width: 100%;
  margin-bottom: 26px;
  display: flex;
  div {
    width: 50%;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 0;

      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 135%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: rgba(24, 24, 24, 0.6);
    }
    span {
      margin-top: 8px;

      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 135%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #181818;
    }
  }
`;

export const SubmitBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const BtnBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width = "270px" }) => width};
  height: 42px;
  background: ${({ white, theme: { quoteBgInvestor } }) => (white ? "#fff" : quoteBgInvestor)};
  border-radius: ${({ white }) => (white ? "4px" : "2px")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  border: ${({ white }) => (white ? "1px solid #EFEFEF" : "")};
  box-sizing: border-box;
`;

export const ContainerFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ row = false }) => (row ? "row" : "column")};
  justify-content: ${({ justify = "flex-start" }) => justify};
  align-items: ${({ align = "flex-start" }) => align};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}

  width: ${({ w = "100%" }) => w};
  ${({ h = null }) => (h ? `height: ${h};` : "")}

  padding: ${({ paddingSize = 0 }) => paddingSize};
  margin: ${({ marginSize = 0 }) => marginSize};
  background: ${({ bg = "transparent" }) => bg};
  border-radius: ${({ borderRadius = 0 }) => borderRadius};

  ${({ border = null }) => (border ? `border: ${border};` : "")}
  overflow: ${({ overflow = "hidden" }) => overflow};

  z-index: ${({ zIndex = 0 }) => zIndex};
  transition: height 0.3s linear;
  box-sizing: border-box;
`;

export const UploaderLabel = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: rgba(24, 24, 24, 0.4);
`;

export const EmptyBlock = styled.div`
  width: 270px;
`;

export const UploadersCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
`;

export const ColumnContainer = styled.div`
  display: flex;
  width: calc(50% - 17.5px);
`;

export const CardWrap = styled.div`
  ${column}
`;

export const Customize = styled.div`
  position: absolute;
  top: 26px;
  right: 48px;
`;

export const CircularWrapper = styled.div`
  margin: auto;
  color: ${({ theme: { primary } }) => primary};
`;

export const PreviewWrapper = styled.div`
  height: 390px;
  display: flex;
  ${flexCenter}
  background: ${({ theme: { uploaderBg } }) => uploaderBg};
`;

export const MailWrapper = styled.div`
  width: 100%;
  display: flex;
  ${flexCenter}
  flex-direction: column;
  transform: scale(0.43);
`;
export const FlexibleBox = styled.div`
  display: ${({ flex }) => (flex ? "flex" : "block")};
  width: ${({ w }) => w};
  height: ${({ h }) => h};
  background: ${({ bg }) => bg};
  font-size: ${({ fz = "14px" }) => fz};
  margin: ${({ m = 0 }) => m};
  padding: ${({ p = 0 }) => p};
`;

export const TextBlock = styled.div`
  display: flex;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  align-items: ${({ center }) => (center ? "center" : "flex-start")};
  font-family: ${({ ff }) => ff};
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: ${({ size }) => (size ? `${size}px` : "18px")};
  line-height: ${({ lineHeight = "145%" }) => lineHeight};
  color: ${({ color }) => color};
  margin-bottom: ${({ mb = 0 }) => mb};
  text-align: ${(center) => (center ? "center" : "left")};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  font-style: ${({ fs }) => (fs ? "italic" : "normal")};
  width: ${({ w }) => w};
  word-break: break-all;
`;

export const ExampleButton = styled.div`
  display: flex;
  height: 42px;
  width: 50%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: ${({ bg }) => bg};
  margin: 24px 0 32px;
  border-radius: 2px;
  color: #fff;
`;

export const ExampleBorder = styled.div`
  width: 100%;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding: ${({ p = 0 }) => p};
  margin: ${({ m = 0 }) => m};
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
`;

export const SwitchBlock = styled.div`
  display: flex;
  > div {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export const SwtBtn = styled.div`
  display: flex;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#fff")};
  color: ${({ active }) => (active ? "#fff" : "#BABABA")};
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#BABABA")};
  width: ${({ w = "50%" }) => w};
  font-size: 12px;
  letter-spacing: -0.02em;
  height: 32px;
  cursor: pointer;
  ${border4};
  ${borderBox};
  ${flexCenter};
`;

//template setup
export const Header = styled.div`
  width: 100%;
  height: ${({ height = 60 }) => height}px;
  border-radius: 4px 0px 4px 4px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? padding : "0 25px")};

  ${({ isFooter = false, mb = "30px" }) => {
    if (isFooter) return `margin-top: 30px;`;
    else return `margin-bottom: ${mb};`;
  }}
  ${({ between = false }) => (between ? "justify-content: space-between;" : "")}
`;

export const InputRow = styled.div`
  margin-bottom: ${({ last }) => (last ? "0" : "20px")};
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  box-sizing: border-box;
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
`;

export const TextH3 = styled.h3`
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ size }) => (size ? `${size}px` : "14px")};
  font-weight: bold;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;
