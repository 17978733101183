import React from "react";
import styled, { css } from "styled-components";
import { scroll, tooltipStyle } from "components/common/mixins";
import { Btn } from "components/admin-layout/buttons";
import logo from "assets/logos/VALK.png";

export const TooltipInfo = styled.div`
  margin-left: auto;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
  ${tooltipStyle}
  > svg {
    width: 18px;
    height: 18px;
    path {
      stroke: ${({ active, theme: { primary } }) => (!active ? primary : "#fff")};
    }
  }
  ${({ small = false }) =>
    small
      ? `position:absolute;
         right:0;
         top:-3px;
         width:14px;
         > svg {
            width:14px;
            height:14px;
          }
        > .tooltip-info {
            left:-151px;
          }`
      : ``};

  ${({ big = false }) =>
    big
      ? `right:1px;
         top:8px;
         width:32px;
         height: 32px
         > svg {
            width:32px;
            height:32px;
          }
        > .tooltip-info {
            left:-160px;
            bottom: 36px;
               &::before {
      left: calc(50% + 50px);
    }
          }`
      : ``};
`;

export const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const Col = styled(Flex)`
  width: ${({ w = "auto" }) => w};
  flex-direction: column;
  position: ${({ position = "static" }) => position};
  padding: ${({ padding = 0 }) => padding};
  > .side-buttons {
    display: flex;
  }
  @media (max-width: 1280px) {
    > .side-buttons {
      display: none;
    }
  }
`;

export const IconsCol = styled(Flex)`
  width: ${({ w = "100%" }) => w};
  flex-direction: column;
  position: relative;
  > svg {
    position: absolute;
    top: 34px;
    right: 13px;
    width: 16px;
    height: 16px;
    path {
      fill: ${({ active, error, theme: { primary, red } }) => (error ? red : active ? primary : "#DADADA")};
    }
  }
`;

export const Row = styled(Flex)`
  width: ${({ w = "100%" }) => w};
  margin: ${({ m = 0 }) => m};
  justify-content: ${({ j = "space-between" }) => j};
  align-items: ${({ a = "center" }) => a};
  position: relative;
  max-width: ${({ mw }) => mw};
  > .side-buttons {
    display: none;
  }
  @media (max-width: 1280px) {
    > .side-buttons {
      display: flex;
    }
  }
`;

const card = css`
  background: #ffffff;
  border-radius: 4px;
`;

const dots = css`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const center = css`
  align-items: center;
`;

const iconButton = css`
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 0 15px;
  > svg {
    margin-right: 10px;
  }
`;

export const MainCol = styled(Col)`
  ${center};
  width: 676px;
`;

export const SideCol = styled(Col)`
  width: calc(50% - 338px);
  ${({ left }) => (left ? `padding-right: 30px;align-items: flex-end;` : `padding-left: 30px;`)};
`;

export const UserName = styled(Flex)`
  ${card};
  ${center};
  ${iconButton};
  width: 205px;
  height: 36px;
  > span {
    width: 160px;
    ${dots};
  }
`;

const LogoBlock = styled(Col)`
  width: 245px;
  margin: auto;
  > img {
    height: 40px;
    margin: 0 auto 24px;
    width: 170px;
  }
`;

const PageDesc = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(51, 51, 51, 0.9);
`;

export const Card = styled(Col)`
  ${card};
  width: ${({ width = "100%" }) => width};
  padding: ${({ padding = "25px 30px 30px" }) => padding};
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : "25px")};
  margin-top: ${({ mt = 0 }) => mt};
  margin-left: ${({ ml = 0 }) => ml};
  margin-right: ${({ mr = 0 }) => mr};
  position: ${({ pos = "static" }) => pos};
  border-top: ${({ bt }) => (bt ? "1px solid #EFF2F9" : "none")};
  ${({ disabled, disabledHeight = "280", overflow = "hidden" }) =>
    disabled
      ? `height: ${disabledHeight}px;
         position: relative;
         overflow: ${overflow};
         &::after {
           content: "";
           position: absolute;
           width: 100%;
           left: 0;
           bottom: 0;
           height: 170px;
           background: linear-gradient(to top, #fbfbfd, rgba(255, 255, 255, 0));
  }`
      : ""};
  ${({ br = "" }) => {
    if (br) return `border-radius: ${br};`;
  }}
`;

export const CardName = styled.h3`
  font-size: ${({ fontSize = "16px" }) => fontSize};
  line-height: 135%;
  color: #181818;
  margin: ${({ m = "0 0 25px" }) => m};
  font-weight: 700;
`;

export const CardDescription = styled.h3`
  font-size: ${({ fontSize = "14px" }) => fontSize};
  line-height: 135%;
  color: ${({ theme: { red }, error }) => (error ? red : "#181818")};
  margin: ${({ m = "0 0 25px" }) => m};
  font-weight: 400;
`;

export const PageLogo = ({ description }) => (
  <LogoBlock>
    <img alt="logo" src={logo} />
    <PageDesc>{description}</PageDesc>
  </LogoBlock>
);

export const DomainPreview = styled(Flex)`
  ${card};
  ${center};
  ${iconButton};
  height: ${({ h = "42px" }) => h};
  background: ${({ theme: { emptyCardBg }, isDefault = false }) => (isDefault ? "#F8F8F8" : emptyCardBg)};
  width: ${({ w = "100%" }) => w};
  > span {
    width: calc(100% - 56px);
    ${dots};
    .valk-domain {
      opacity: 0.5;
    }
  }
`;

export const File = styled(DomainPreview)`
  > span {
    width: calc(100% - 76px);
  }
  svg.clickeble-icon {
    filter: ${({ isWait }) => (isWait ? "grayscale(1)" : "none")};
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    cursor: pointer;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    &:first-child {
      cursor: auto;
      width: 20px;
      height: 20px;
    }
    &:last-child {
      margin-right: 0;
      margin-left: auto;
    }
  }
`;

export const AssetLink = styled(DomainPreview)`
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme: { green } }) => green};
    color: #fff;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
  > div {
    margin: 0 0 0 auto;
    svg {
      width: 16px;
      height: 16px;
      path {
        stroke-width: 0.5px;
      }
    }
  }
`;

export const BlueBg = styled(Flex)`
  ${card};
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  width: 100%;
  height: 82px;
  margin-bottom: 10px;
  svg {
    cursor: pointer;
  }
`;

export const Text = styled.p`
  margin: ${({ m = 0 }) => m};
  font-size: ${({ small }) => (small ? "12px" : "14px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ small }) => (small ? "rgba(24, 24, 24, 0.4)" : "#333333")};
  text-align: ${({ align = "left" }) => align};
  min-height: 16px;
  > span {
    color: ${({ theme: { primary } }) => primary};
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const TypeContainer = styled.div`
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.15s all linear;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#fff")};
  cursor: pointer;
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;
  border: 1px solid ${({ active, theme: { primary } }) => (!active ? primary : "#fff")};
  border-radius: 50%;
  position: relative;
  margin-right: 15px;
  &::after {
    content: "";
    top: 3px;
    left: 3px;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffffff;
  }
`;

export const Type = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ active, theme: { primary } }) => (!active ? primary : "#fff")};
`;

export const Contract = styled.div`
  height: 400px;
  background: #fbfbfd;
  border-radius: 4px;
  padding: 25px 20px;
  box-sizing: border-box;
  position: relative;
  ${({ darkened = true }) => {
    if (darkened)
      return `
  &::after {
    content: "";
    position: absolute;
    width: calc(100% - 40px);
    height: 100px;
    bottom: 25px;
    left: 12px;
    background: linear-gradient(to top, #fbfbfd, rgba(255, 255, 255, 0));
  }`;
    else return "";
  }}
`;

export const Scroll = styled.div`
  ${scroll};
  width: ${({ w = "auto" }) => w};
`;

export const ContractScroll = styled(Scroll)`
  color: #333333;
  padding-right: 5px;
  p,
  li {
    font-size: 14px;
  }
  h3,
  h4 {
    text-align: uppercase;
  }
  ul,
  ol {
    padding-left: 22px;
    list-style-type: none;
    li {
      &::marker {
        font-weight: bold;
      }
    }
  }
  > p {
    line-height: 135%;
    letter-spacing: -0.02em;
    margin: 7px 0;
  }
  li {
    margin-bottom: 12px;
  }
`;

export const Paper = styled(Flex)`
  ${card};
  ${center};
  width: 440px;
  height: 250px;
  border: 1px solid #efefef;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  margin: auto;
  padding: ${({ p = "30px 60px 20px" }) => p};
  flex-direction: column;
  justify-content: center;
  svg {
    width: 52px;
    height: 52px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const ToolTip = styled(Flex)`
  ${card};
  padding: 20px;
  position: fixed;
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => `${top}px`};
  width: ${({ w = "327px" }) => w};
  height: ${({ h = "153px" }) => h};
  flex-direction: column;
  &::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    z-index: 101;
    border-width: 0 15px 20px 15px;
    ${({ topPosition, leftPosition, bottomLeft, bottomPosition }) =>
      topPosition
        ? `left: 146px;
           top: -13px;`
        : leftPosition
        ? `left: -24px;
           top: ${bottomLeft ? "100px" : "80px"};
           transform: rotate(-90deg);`
        : bottomPosition
        ? `transform: rotate(-180deg);bottom: -20px;left: 40px;`
        : ""}
  }
`;

export const Container = styled.div`
  margin: ${({ m = 0 }) => m};
`;

export const Heading = styled.div`
  font-weight: bold;
  font-size: ${({ fz = "14px" }) => fz};
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #181818;
  width: fit-content;
  > span {
    color: ${({ theme: { primary } }) => primary};
  }
`;

export const Button = Btn;

export const SkipButton = styled.button`
  width: 94px;
  height: 32px;
  background: #f1f3f8;
  border-radius: 4px;
  outline: none;
  border: 1px solid #f1f3f8;
  color: #d5d7dd;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  cursor: pointer;
`;

export const AccountBlock = styled.div.attrs(() => ({
  "data-lvl-local": true,
}))`
  position: absolute;
  right: 0;
  top: 0;
  width: 261px;
  height: 69px;
  background: #fff;
  display: flex;
  padding-right: 30px;
  box-sizing: border-box;
  /* z-index: 1002; */
`;

export const MailSettingsBlock = styled.div`
  position: absolute;
  width: 160px;
  height: 84px;
  left: 383px;
  top: 71px;
  background: #ffffff;
  padding: 0 10px;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  display: flex;
  align-items: center;
  white-space: nowrap;
  > svg {
    width: 28px;
    height: 28px;
    margin-right: 15px;
  }
`;

export const Favicon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

export const BorderContainer = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #efefef;
`;

export const ContentContainer = styled.div`
  position: relative;
`;

export const HideLayout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background: #ffffffad;
`;

export const FavBg = styled(Flex)`
  width: 32px;
  height: 32px;
  background: #1f263e47;
  position: relative;
  > img {
    margin: auto;
    width: 16px;
    height: 16px;
  }
  .remove-layout {
    display: none;
  }
  &:hover {
    .remove-layout {
      display: flex;
    }
  }
`;

export const RemoveFav = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  left: 0;
  background: ${({ theme: { primary } }) => primary};
  background-blend-mode: normal, normal;
  opacity: 0.7;
  > svg {
    cursor: pointer;
    margin: auto;
    width: 16px;
    height: 16px;
  }
`;

export const SubDescription = styled.div`
  margin: 16px 0 0;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #8a8a8a;
`;
