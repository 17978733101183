import { createAction } from "redux-act";
import { model } from "setup-panel/setup.app.flow_model";

export const setStep = createAction("SET_NEXT_STEP");

export const moveToNextStep = () => async (dispatch, getState) => {
  const {
    setupReducer: { currentStep },
  } = getState();

  const nextStepIndex = model.findIndex((e) => e.name === currentStep) + 1;
  const nextStep = model[nextStepIndex];

  if (typeof nextStepIndex === "number" && model[nextStepIndex] && !nextStep.done) {
    dispatch(setStep(model[nextStepIndex].name));
  } else {
    const findNext = (nextStep) => {
      if (!nextStep.done) {
        dispatch(setStep(nextStep.name));
      } else {
        const next = model.find((el) => el.name === nextStep.skip);
        if (next) {
          findNext(next);
          return;
        }
      }
    };

    findNext(nextStep);
  }
};

export const moveToNextStepSkip = () => (dispatch, getState) => {
  const {
    setupReducer: { currentStep },
  } = getState();

  let _currentStep = currentStep;
  let nextStep;

  for (let i = 0; i < model.length; i++) {
    if (model[i].name === _currentStep && model[i + 1]) {
      const nextStepIndex = model.findIndex((e) => e.name === model[i].skip);
      const nextSteps = model.filter((e) => model[nextStepIndex] && model[nextStepIndex].skip === e.skip);

      if (nextSteps[nextSteps.length - 1] && nextSteps[nextSteps.length - 1].done) {
        _currentStep = nextSteps[nextSteps.length - 1].name;
      } else {
        nextStep = nextSteps.find((e) => !e.done);
        break;
      }
    }
  }

  if (!nextStep) {
    nextStep = model[model.length - 1];
  }
  console.log(_currentStep, currentStep, model, nextStep);
  dispatch(setStep(nextStep.name));
};

export const initStep = () => (dispatch) => {
  for (let i = 0; i < model.length - 1; i++) {
    const current = model[i] || {};
    const next = model[i + 1] || {};

    if (current.done && !next.done) {
      dispatch(setStep(next.name));
      return;
    }
  }

  dispatch(setStep(model[0].name));
};
