import * as user from "./userAction";
import { handleActions } from "../../helpers/immer";

function initState() {
  return {
    rememberUser: true,
    token: localStorage.getItem("auth-token") || "",
    defaultView: localStorage.getItem("defaultView") || "col",
    username: "",
    role: "",
    kycPassed: false,
    fullName: "",
    logo: "",
    _2fa: false,
    devicesToken: [],
    paymentInfo: [],
    paymentDetails: [],
    tradePaymentDetails: null,
    paymentResponseData: [],
    paymentResponseDataTrade: null,
    userInfo: {},
    isAppNotReady: !!localStorage.getItem("auth-token"),
    platformPlan: "ENTERPRISE",
    setupPassed: null,
    defaultMailIntegration: "",
    mailIntegrationValid: null,
    demoMode: null,
    demoMessage: false,
    deviceName: "desktop",
  };
}

export const userReducer = handleActions(
  {
    [user.appReadyToWork]: (draftState) => {
      draftState.isAppNotReady = false;
    },
    [user.loginSuccess]: (draftState, { payload }) => {
      draftState.token = payload;
      if (draftState.rememberUser) localStorage.setItem("auth-token", payload);
      else localStorage.removeItem("auth-token");
    },
    [user.saveUserInfo]: (draftState, { payload }) => {
      draftState.username = payload.username;
      draftState.role = payload.role;
      draftState.kycPassed = payload.kycPassed;
      draftState.fullName = payload.userData.fullName;
      draftState.logo = payload.logo;
      draftState.appInInitState = false;
      draftState.devicesToken = [];
      draftState.userInfo = payload.userInfo;
      draftState.platformPlan = payload.platformPlan || "PRO";
      draftState.setupPassed = payload.setupPassed;
      localStorage.setItem(
        "global_user_flag",
        JSON.stringify({
          username: payload.username,
          fullName: payload.userData.fullName,
          role: payload.role,
        })
      );
    },
    [user.rememberUserToggle]: (draftState) => {
      draftState.rememberUser = !draftState.rememberUser;
    },
    [user.logOut]: (draftState) => {
      draftState.username = "";
      draftState.rememberUser = false;
      draftState.token = null;
      draftState.role = "";

      localStorage.removeItem("auth-token");
    },
    [user.set2Fa]: (draftState, { payload }) => {
      draftState._2fa = payload;
      localStorage.setItem("global_user_flag", null);
    },
    [user.updateAvatar]: (draftState, { payload }) => {
      draftState.logo = payload;
    },
    [user.updateUserName]: (draftState, { payload }) => {
      draftState.fullName = payload;
    },
    [user.setPaymentInfo]: (draftState, { payload }) => {
      draftState.paymentInfo[0] = payload;
    },
    [user.setPaymentDetails]: (draftState, { payload }) => {
      draftState.paymentDetails[0] = payload;
    },
    [user.setPaymentResponseData]: (draftState, { payload }) => {
      draftState.paymentResponseData[0] = payload;
    },
    [user.setTradePaymentDetails]: (draftState, { payload }) => {
      draftState.tradePaymentDetails = payload;
    },
    [user.setPaymentResponseDataTrade]: (draftState, { payload }) => {
      draftState.paymentResponseDataTrade = payload;
    },
    [user.setDefaultDealsView]: (draftState, { payload }) => {
      draftState.defaultView = payload;
    },
    [user.setMailIntegration]: (draftState, { payload: { defaultMailIntegration, mailIntegrationValid } }) => {
      draftState.defaultMailIntegration = defaultMailIntegration;
      draftState.mailIntegrationValid = mailIntegrationValid;
    },
    [user.setSetupStatus]: (draftState, { payload }) => {
      draftState.setupPassed = payload;
    },
    [user.setAdminSettings]: (draftState, { payload }) => {
      draftState.demoMode = payload;
    },
    [user.toggleDemoMessageStatus]: (draftState) => {
      draftState.demoMessage = !draftState.demoMessage;
    },
    [user.addNewBankAccount]: (draftState, { payload }) => {
      draftState.userInfo.bankAccounts = [...(draftState.userInfo.bankAccounts || []), payload];
    },
    [user.addAddress]: (draftState, { payload }) => {
      draftState.userInfo.address = payload;
    },
    [user.setDeviceName]: (draftState, { payload }) => {
      draftState.deviceName = payload;
    },
    [user.setOnboardingPassed]: (draftState) => {
      draftState.userInfo.onboardingPassed = true;
    },
  },
  initState()
);
