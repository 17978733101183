import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

import { ReactComponent as Location } from "../../../../assets/map-pin.svg";
import { UserIcon } from "components/common/icons-primary";
import Api from "../../../../helpers/api";
import { numberWithCommas, formatCount } from "../../../../helpers";
import { currenciesModel } from "../../../../models/currenciesModel";
import theme from "models/themes";
import { shadeColor } from "helpers/increseBrightnes";
import { ReactComponent as Folder } from "assets/folder-green.svg";
import { ReactComponent as Disc } from "assets/disc.svg";
import { ReactComponent as BlendedIcon } from "assets/blended.svg";
import { ReactComponent as Refresh } from "assets/refresh-cw.svg";
import { Btn } from "components/admin-layout/buttons";
import { column, borderBox } from "components/common/mixins";
import { ExtraSectors } from "components/admin-layout/pages/Connect/styled";

export const Wrapper = styled.section`
  ${column}
  align-items: center;
  padding: 0 30px;
  ${borderBox}
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

export const FilterCont = styled.div`
  height: 60px;
  width: 100%;
  max-width: 1380px;
  background: #ffffff;
  border-radius: 4px;
  ${borderBox}
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 293px;
  padding-right: 30px;
  ${borderBox}
  height: 100%;
  border-right: 1px solid #f2f2f2;

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 200px;
  }
`;

const Text = styled.div`
  font-style: normal;
  font-weight: ${({ fWeight }) => (fWeight ? fWeight : 600)};
  font-size: 14px;
  line-height: 135%;
  color: ${({ color }) => (color ? color : "#181818")};
`;

export const ViewWrapper = styled.div`
  width: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const BtnsWrapper = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 504px;
  height: 100%;
  margin-right: auto;
  margin-left: 25px;
  @media (max-width: 1200px) {
    margin-right: 0;
    margin-left: auto;
  }
  @media (max-width: 1024px) {
    width: 462px;
    > button {
      width: 145px;
    }
  }
`;

export const Button = styled.button`
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  width: ${({ width, fullWidth, responsive = false }) =>
    responsive ? responsive : width ? `${width}px` : fullWidth ? "100%" : "94px"};
  border: 1px solid
    ${({ black, hide, error, green, violet, blue, theme: { primary, secondary, tertiary, red, green: greenTheme } }) =>
      black
        ? "#20263C"
        : hide
        ? "#CACACA"
        : error
        ? red
        : green
        ? greenTheme
        : violet
        ? secondary
        : blue
        ? tertiary
        : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({
    black,
    white,
    hide,
    transparent,
    error,
    green,
    violet,
    blue,
    theme: { primary, secondary, tertiary },
  }) =>
    black
      ? "#20263C"
      : white || hide
      ? "#fff"
      : transparent || error || green
      ? "transparent"
      : violet
      ? secondary
      : blue
      ? tertiary
      : primary};
  color: ${({ white, hide, transparent, error, green, theme: { primary, red, green: greenTheme } }) =>
    white || transparent ? primary : hide ? "#CACACA" : error ? red : green ? greenTheme : "#fff"};

  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "600")};
  font-size: ${({ size }) => (size ? `${size}px` : "14px")};
  text-align: center;
  letter-spacing: 0.06em;
  border-radius: 4px;
  outline: none;
  display: ${({ none }) => (none ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: ${({ tTransform }) => tTransform};
  font-weight: ${({ fWeight }) => fWeight};
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.1s linear;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: 1px solid #e3e3e3;
    &::before {
      background: #e3e3e3 !important;
    }
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  > svg {
    width: 14px;
    height: 14px;
    ${({ isRight = false, noMargin }) => {
      if (isRight) {
        return `margin-left: 7px;`;
      } else if (noMargin) {
        return `margin: 0;`;
      } else return `margin-right: 7px;`;
    }}
    /* margin-right: 7px; */
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;

    path {
      stroke: ${({ transparent, error, green, back, hide, theme: { primary, red, green: greenTheme } }) =>
        transparent ? primary : error ? red : back || hide ? "#CACACA" : green ? greenTheme : "#fff"};
    }
  }
  &:hover {
    &::before {
      display: ${({ hideBorder = false }) => (hideBorder ? "none" : "block")};
    }
  }
  &:active {
    ${({
      black,
      hide,
      error,
      green,
      violet,
      blue,
      theme: { primary, secondary, tertiary, red, green: greenTheme },
    }) => {
      const activeColor = black
        ? shadeColor("#20263C", -15)
        : hide
        ? shadeColor("#CACACA", -15)
        : error
        ? shadeColor(red, -15)
        : green
        ? shadeColor(greenTheme, -15)
        : violet
        ? shadeColor(secondary, -15)
        : blue
        ? shadeColor(tertiary, -15)
        : shadeColor(primary, -15);
      return `background: ${activeColor};border: 1px solid ${activeColor};color:#fff;`;
    }};
    &::before {
      display: none;
    }
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &::before {
    transition: all 0.3s linear;
    content: "";
    position: absolute;
    display: none;
    bottom: -1px;
    left: -1px;
    height: 3px;
    background: ${({
      black,
      hide,
      error,
      green,
      violet,
      blue,
      theme: { primary, secondary, tertiary, red, green: greenTheme },
    }) =>
      black
        ? "#20263C"
        : hide
        ? "#CACACA"
        : error
        ? red
        : green
        ? greenTheme
        : violet
        ? secondary
        : blue
        ? tertiary
        : primary};
    width: calc(100% + 2px);
    filter: brightness(0.85);
    border-radius: 0 0 4px 4px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
  @media (max-width: 320px) {
    text-transform: none;
  }
`;

export const OffersCounter = ({ count }) => {
  return (
    <CountWrapper>
      <Text>Total offerings</Text>
      <Text bold>{count}</Text>
    </CountWrapper>
  );
};

export const DealsWrapper = styled.div`
  width: 1380px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 100%;
  }
`;

export const AssetRowCard = styled.div`
  filter: ${({ expired }) => (expired ? "grayscale(1)" : "none")};
  width: 100%;
  height: 260px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  ${borderBox}
  display: flex;
  border-radius: 4px;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Asset = styled.div`
  filter: ${({ expired }) => (expired ? "grayscale(1)" : "none")};
  width: 443px;
  height: 530px;
  background: #ffffff;
  ${borderBox}
  border-radius: 4px;
  margin-bottom: 25px;
  ${column}
  @media (max-width: 1439px) {
    width: calc(33.3% - 17px);
  }
  @media (max-width: 1280px) {
    width: calc(50% - 15px);
  }
  @media (max-width: 1024px) {
    width: calc(50% - 12.5px);
  }
`;

export const EmptyCard = styled.div`
  width: 443px;
  @media (max-width: 1439px) {
    width: calc(33.3% - 17px);
  }
  @media (max-width: 1280px) {
    width: calc(50% - 15px);
  }
  @media (max-width: 1024px) {
    width: calc(50% - 12.5px);
  }
`;

export const ImgCont = styled.div`
  width: ${({ width, fullW }) => (width ? `${width}px` : fullW ? "100%" : "440px")};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  position: relative;
  ${borderBox}
  overflow: hidden;
  display: flex;
  filter: ${({ filter }) => (filter ? "grayscale(1)" : "none")};
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > .ValkCircularProgress {
    margin: auto;
    color: ${({ theme: { primary } }) => primary};
  }
`;

export const InfoBlock = styled.div`
  width: 640px;
  height: 100%;
  border-right: 1px solid #f2f2f2;
  ${column}
`;

export const ChartBlock = styled.div`
  position: relative;
  width: 300px;
  height: 100%;
  padding: 16px 0;
  ${borderBox}
  ${column}
  justify-content: space-between;
  align-items: center;
`;

const LabelsRow = styled.div`
  position: absolute;
  width: 100%;
  padding: 0 15px;
  top: 15px;
  ${borderBox}
  display: flex;
  height: 30px;
  justify-content: space-between;
  > button {
    margin-left: auto;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 30px;
  ${borderBox}
  background: #ffffff;
  border-radius: 2px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #333333;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  > svg {
    margin-right: 8px;
    path {
      fill: ${({ violet, blue, theme: { primary, secondary, tertiary } }) =>
        violet ? secondary : blue ? tertiary : primary};
    }
  }
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const Label_ = styled(Label)`
  color: ${({ debt, isBlended, theme: { primaryDark, primary } }) =>
    debt ? primaryDark : isBlended ? primary : "#31ADBF"};
  > svg {
    path {
      fill: none;
    }
  }
`;

export const Labels = ({ openEndFund, location, violet = false, blue, dealTypeFinancial }) => {
  const debt = dealTypeFinancial === "DEBT";
  const isBlended = dealTypeFinancial === "BLENDED";
  return (
    <LabelsRow>
      <Label violet={violet} blue={blue}>
        <Location />
        {location}
      </Label>
      <Label_ {...{ isBlended, debt }}>
        {debt ? <Disc /> : isBlended ? <BlendedIcon /> : <Folder />}
        {debt ? "Debt" : openEndFund ? "Open-end Fund" : isBlended ? "Blended" : "Equity"}
      </Label_>
    </LabelsRow>
  );
};

export const BorderBlock = styled.div`
  ${borderBox}
  ${column}
  width: 100%;
  height: ${({ height }) => `${height}px`};
  padding: ${({ padding }) => padding};
  border-bottom: 1px solid #f2f2f2;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    padding: 15px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height = "auto" }) => height};
  overflow: ${({ noHidden }) => (noHidden ? "" : "hidden")};
  justify-content: ${({ justify }) => justify};
  margin: ${({ margin }) => margin};
  &:last-child {
    margin: 0;
  }
  align-items: ${({ align }) => align};
`;

export const Col = styled.div`
  width: ${({ width }) => (width ? width : "44%")};
  margin-right: 40px;
  ${column}
  &:last-child {
    margin-right: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: ${({ width }) => (width ? width : "calc(50% - 10px)")};
    margin-right: 20px;
  }
`;

const Param = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #545454;
  white-space: nowrap;
  margin-right: 5px;
`;

export const Val = styled.div`
  font-style: normal;
  font-weight: ${({ fWeight }) => (fWeight ? fWeight : 600)};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "12px")};
  line-height: 150%;
  color: #181818;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const InfoRow = ({ label, val, margin = "0 0 8px 0" }) => {
  return (
    <Row justify="space-between" margin={margin}>
      <Param>{label}</Param>
      <Val>{val}</Val>
    </Row>
  );
};

export const LinkBlock = styled.div`
  ${borderBox}
  padding: 0 30px;
  border-left: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SponsorBlock = styled.div`
  width: ${({ width }) => (width ? width : "calc(100% - 240px)")};
  display: flex;
  align-items: center;
  ${borderBox}
  padding: 0 25px;
  height: 100%;
`;

export const AssetName = styled.h3`
  margin: 0 0 14px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #192036;
  white-space: nowrap;
  max-width: 253px;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const SmallDesc = styled.p`
  margin: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #333333;
  text-overflow: ellipsis;
  overflow: hidden;
  > p {
    margin: 0;
  }
`;

const Avatar = styled.img`
  width: ${({ size = 32 }) => `${size}px`};
  height: ${({ size = 32 }) => `${size}px`};
  border-radius: 50%;
  margin-right: ${({ size = 32 }) => `${size / 2 - 4}px`};
  object-fit: cover;
  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
`;

const SponsorName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${({ size = 32 }) => `${size / 2 - 4}px`};
  line-height: 150%;
  color: #181818;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const SponsorDesc = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #8a8a8a;
  @media (max-width: 768px) {
    font-size: 8px;
  }
`;

const IconCont = styled.div`
  margin-right: 14px;
  width: ${({ size = 32 }) => `${size}px`};
  height: ${({ size = 32 }) => `${size}px`};
  > svg {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
`;

export const Sponsor = ({ sponsor: { userLogo, fullName, email }, token, size = 32 }) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (!logo && userLogo) {
      const { fileNameWithExtension, secureHash } = userLogo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => setLogo(blob));
    }
  }, []);

  return (
    <Row align="center">
      {logo ? (
        <Avatar src={logo} size={size} />
      ) : (
        <IconCont size={size}>
          <UserIcon />
        </IconCont>
      )}
      <Col width="70%">
        <SponsorName size={size}>{fullName}</SponsorName>
        <SponsorDesc>{email}</SponsorDesc>
      </Col>
    </Row>
  );
};

const _ChartBg = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const __ChartBg = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
`;

const SegmentSeparator = styled.div`
  position: absolute;
  background: white;
  height: 2px;
  top: 50%;
  left: 50%;
  width: calc(50% + 1px);
  transform-origin: left top;
  z-index: ${({ z = 0 }) => z};
  transform: rotate(${({ angle = 0 }) => angle}deg) translateY(-50%);
`;

export const ChartBg = (props) => {
  const segmentSeparators = [];
  try {
    const total = props.segments.reduce((x, { value }) => x + value, 0);
    let currentAngle = 0;
    props.segments.forEach(({ value, color }) => {
      const angle = (360 * value) / total;
      currentAngle += angle;
      segmentSeparators.push({
        angle: Math.round(currentAngle),
        color,
        isBorder: angle < 5,
      });
    });
  } catch (_) {
    console.log(_);
  }
  return (
    <_ChartBg>
      {props.children}
      {segmentSeparators.map(({ angle, color, isBorder }, i) => (
        <SegmentSeparator angle={angle} z={i} isBorder={isBorder} color={color} />
      ))}
    </_ChartBg>
  );
};

const WhiteBg = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  ${borderBox}
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 31px;
  background: transparent;
`;

export const ChartTxt = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${({ size }) => (size ? `${size}px` : "12px")};
  line-height: 135%;
  text-align: ${({ tAlign }) => (tAlign ? tAlign : "center")};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-bottom: 4px;
  width: ${({ width }) => width};
`;

const Percents = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ trade, theme: { primaryMiddle, secondary } }) => (trade ? secondary : primaryMiddle)};
`;

export const ReminingChart = ({ percent, trade = false, isExisted, roundsInfo }) => {
  const lastRound = isExisted ? roundsInfo[0] : {};
  return (
    <WhiteBg>
      <ChartTxt size={10} width={trade ? "50%" : "auto"}>
        {trade ? "Available  shares" : "Raised"}
      </ChartTxt>
      {isExisted ? (
        <Percents
          dangerouslySetInnerHTML={{
            __html:
              (currenciesModel[lastRound.currency] ? currenciesModel[lastRound.currency].symbol : lastRound.currency) +
              formatCount(roundsInfo[0].roundSize),
          }}
        />
      ) : (
        <Percents trade={trade}>{percent}%</Percents>
      )}
    </WhiteBg>
  );
};

export const DataCol = styled.div`
  z-index: 2;
  ${column}
  justify-content: center;
  align-items: ${({ align = "center" }) => align};
  width: ${({ width }) => (width ? `${width}%` : "100%")};
`;

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify = "center" }) => justify};
  width: 100%;
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const CircleData = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${({ dark, color, theme: { primary, primaryLight } }) => (dark ? primary : color ? color : primaryLight)};
  margin-left: 6px;
`;

export const ChartData = ({ raised, toBeRaised, subscribers, baseCurrency, isExisted, roundsInfo }) => {
  const lastRound = roundsInfo ? roundsInfo[0] : {};

  return (
    <DataCol>
      <DataRow>
        <DataCol width={50}>
          <ChartTxt size={10}>raised amount</ChartTxt>
          <Val fSize={14} fWeight="bold">
            <span
              dangerouslySetInnerHTML={{
                __html: isExisted
                  ? (currenciesModel[lastRound.currency]
                      ? currenciesModel[lastRound.currency].symbol
                      : lastRound.currency) + numberWithCommas(lastRound.roundSize)
                  : (currenciesModel[baseCurrency] ? currenciesModel[baseCurrency].symbol : baseCurrency) +
                    numberWithCommas(raised.toFixed()),
              }}
            />
            {isExisted ? null : <CircleData dark />}
          </Val>
        </DataCol>
        <DataCol width={50}>
          <ChartTxt size={10}>{isExisted ? "LATEST ROUND" : "Total amount"}</ChartTxt>
          <Val fSize={14} fWeight="bold">
            <span
              dangerouslySetInnerHTML={{
                __html: isExisted
                  ? lastRound.fundingName
                  : (currenciesModel[baseCurrency] ? currenciesModel[baseCurrency].symbol : baseCurrency) +
                    numberWithCommas((raised + toBeRaised).toFixed()),
              }}
            />
            {isExisted ? null : <CircleData />}
          </Val>
        </DataCol>
      </DataRow>
      <DataRow>
        <DataCol width={50}>
          <ChartTxt size={10}>{isExisted ? "DATE" : "subscribers"}</ChartTxt>
          <Val fSize={14} fWeight="bold">
            {isExisted ? moment(lastRound.roundTo).format("MM/YYYY") : subscribers || 0}
          </Val>
        </DataCol>
      </DataRow>
    </DataCol>
  );
};

export const TradeChartData = ({ available, total, subscribers }) => {
  return (
    <DataCol>
      <DataRow justify="space-between">
        <DataCol width={60}>
          <ChartTxt size={10}>Available shares</ChartTxt>
          <Val fSize={14} fWeight="bold">
            {numberWithCommas(available)} <CircleData color={theme.secondary} />
          </Val>
        </DataCol>
        <DataCol width={50}>
          <ChartTxt size={10}>Total Shares</ChartTxt>
          <Val fSize={14} fWeight="bold">
            {numberWithCommas(total.toFixed())} <CircleData color={theme.secondaryLight} />
          </Val>
        </DataCol>
      </DataRow>
      <DataRow>
        <DataCol width={50}>
          <ChartTxt size={10}>subscribers</ChartTxt>
          <Val fSize={14} fWeight="bold">
            {numberWithCommas(subscribers)}
          </Val>
        </DataCol>
      </DataRow>
    </DataCol>
  );
};

export const PieChartCircle = styled.div`
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 30px;
`;

export const RaisedCardBlock = styled.div`
  width: 100%;
  height: 95px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  ${borderBox}
  padding: 18px 25px;
  ${column}
`;

export const InfoCardBlock = styled.div`
  width: 100%;
  height: 140px;
  ${borderBox}
  padding: 18px 25px;
  ${column}
`;

export const TooltipCont = styled.div`
  position: relative;
  margin: 3px 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  > svg {
    opacity: 0.4;
    cursor: pointer;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  &:hover {
    > div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
  @media (max-width: 768px) {
    margin: 0 0 0 auto;
  }
`;

export const WrapperTips = styled.div.attrs(() => ({
  "data-lvl-tooltip": true,
}))`
  position: absolute;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #efefef;
  ${borderBox}
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 380px;
  right: -29px;
  bottom: 28px;
  display: none;
  flex-direction: column;
  /* z-index: 100; */
  h1 {
    margin: 0 0 15px;
    font-size: 18px;
    color: #192036;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    width: 150px;
    bottom: 20px;
    right: -20px;
  }
`;

export const TipsText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  letter-spacing: -0.02em;
  color: #333333;
  max-height: 240px;
  overflow: hidden;
`;

export const Triang = styled.div.attrs(() => ({
  "data-lvl-tooltip": true,
}))`
  display: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 12.5px 0 12.5px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  bottom: 10px;
  /* z-index: 101; */
  left: 0px;
`;

export const TooltipCard = styled.div`
  background: #ffffff;
  border: 1px solid #efefef;
  ${borderBox}
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px 25px 30px;
  position: absolute;
  width: 380px;
  right: -26px;
  bottom: 43px;
`;

export const Triangle = styled.div`
  position: absolute;
  right: -8px;
  top: -33px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #edebeb;
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 2px;
  transform: rotate(45deg);
`;

export const WhiteBlock = styled.div`
  bottom: 44px;
  width: 45px;
  height: 28px;
  background: #fff;
  position: absolute;
  z-index: 2;
  right: -15px;
`;

export const ExistedChart = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: ${({ theme: { tertiaryLight } }) => tertiaryLight};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ExistedLabel = styled.h6`
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0;
`;

export const ExistedVal = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: ${({ currentlyFundraising }) => (currentlyFundraising ? "38px" : "28px")};
  line-height: ${({ currentlyFundraising }) => (currentlyFundraising ? "48px" : "35px")};
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: ${({ currentlyFundraising, theme: { tertiaryMiddle, primary } }) =>
    currentlyFundraising ? primary : tertiaryMiddle};
`;

export const NavBlock = styled.div`
  display: flex;
  width: 390px;
  justify-content: space-between;
`;

const Bg = styled.div`
  width: ${({ width = "100%" }) => width};
  height: ${({ heigth = false }) => (heigth ? heigth : "100%")};
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  border-radius: 2px;
  ${borderBox}
  ${column}
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => (padding ? padding : "30px")};
`;

const TextNoInfo = styled.p`
  font-size: 12px;
  line-height: 135%;
  text-align: center;
  margin: 0;
  color: #181818;
`;

export const NoInfo = ({ text = "", heigth, padding, width }) => (
  <Bg width={width} heigth={heigth} padding={padding}>
    <TextNoInfo dangerouslySetInnerHTML={{ __html: text }} />
  </Bg>
);

export const FundRaisingChart = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme: { primaryLight } }) => primaryLight};
  margin: auto;
  position: relative;
`;

export const WrapperRow = styled.div`
  display: grid;
  grid-template-columns: 323px 1fr;
  min-width: 0;
  max-width: 1715px;
  grid-column-gap: 25px;

  @media (max-width: 1080px) {
    grid-template-columns: 200px 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 323px);
  grid-template-rows: auto;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  @media (max-width: 1795px) {
    grid-template-columns: repeat(3, 323px);
  }
  @media (max-width: 1425px) {
    grid-template-columns: repeat(2, 323px);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const FullWidthBlock = styled.div`
  width: 1369px;
  @media (max-width: 1795px) {
    width: 1019px;
  }
  @media (max-width: 1425px) {
    width: 671px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  > div {
    height: 100%;
  }
`;

export const RowFullWidth = styled.div`
  max-width: 1369px;
  @media (max-width: 1425px) {
    width: auto;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 490px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  ${borderBox}
  border-radius: 4px;
  display: grid;
  grid-template-rows: 260px 1fr 64px;
  grid-row-gap: 20px;
  @media (max-width: 768px) {
    height: 360px;
    grid-template-rows: 2fr 1.3fr 0.7fr;
    grid-row-gap: 15px;
  }
`;

export const NameRow = styled.div`
  width: 100%;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 14px;
  grid-column-gap: 20px;
`;

export const Block = styled.div`
  padding: 0 20px;
`;

export const AmBlock = styled.div`
  border-top: 1px solid #f2f2f2;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 32px;
  padding: 0 20px;
  position: relative;
`;

export const CircleLink = styled(Link)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ transparent, theme: { primary } }) => (transparent ? "transparent" : primary)};
  ${borderBox}
  border: 1px solid ${({ theme: { primary } }) => primary};
  display: flex;
  margin-top: 16px;
  > svg {
    width: 10px;
    height: 10px;
    margin: auto;
    transform: rotate(180deg);
    path {
      stroke: ${({ transparent, theme: { primary } }) => (transparent ? primary : "#fff")};
    }
  }
  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
`;

export const DynamicInfo = styled.div`
  height: calc(100% - 35px);
  display: grid;
  grid-template-rows: repeat(4, 1fr);
`;

export const DynamicItem = styled.div`
  display: grid;
  grid-template-columns: 135px 1fr;
  grid-column-gap: 20px;
  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:first-child {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #545454;
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
    &:last-child {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      text-align: right;
      color: #181818;
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1.2fr 1fr;
    grid-column-gap: 0px;
  }
`;

export const ItemRow = styled.div`
  width: 100%;
  height: 260px !important;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  ${borderBox}
  border-radius: 4px;
  display: grid;
  grid-template-columns: 323px 1fr;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const InfoRowDeal = styled.div`
  display: grid;
  grid-template-rows: 114px 81px 65px;
  * {
    ${borderBox}
  }
`;

export const DescriptionDeal = styled.div`
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 30px;
  font-size: 12px;
  line-height: 160%;
  color: #333333;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    width: 100%;
    height: 12px;
    position: absolute;
    background: #fff;
    left: 0;
    bottom: 0;
  }
`;

export const RowNameBlock = styled.div`
  padding: 16px 30px;
  display: grid;
  grid-template-rows: 24px 1fr;
  grid-row-gap: 14px;
  > h3 {
    font-size: 18px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #192036;
    margin: 0;
  }
`;

export const Details = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;

export const SearchBar = styled.input`
  height: 59px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 135%;
  ${borderBox}
  border: none;
  outline: none;
  color: #181818;
  padding: 0 20px 0 60px;
  &::placeholder {
    font-weight: 600;
    letter-spacing: -0.02em;
    color: #d0d0d0;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  position: relative;
  max-width: 1369px;
  @media (max-width: 768px) {
    display: none;
  }
  > svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    left: 20px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const Flex = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    svg {
      transition: all 2s ease;
      transform: ${({ refreshed }) => (refreshed ? "rotate(360deg)" : "rotate(0deg)")};
    }
  }
`;

export const MobileButton = () => {
  const [isRefreshed, setIsRefreshed] = useState(false);
  const onChangeRefresh = () => setIsRefreshed(!isRefreshed);
  return (
    <Flex refreshed={isRefreshed} onClick={onChangeRefresh}>
      <Btn fullWidth>
        <Refresh />
        Show 10 More
      </Btn>
    </Flex>
  );
};

export const MobileSearchContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    height: ${({ isShowed }) => (isShowed ? "50px" : 0)};
    position: relative;
    transition: all 0.2s linear;
    > input {
      height: ${({ isShowed }) => (isShowed ? "50px" : "0")};
      padding: ${({ isShowed }) => (isShowed ? "20px 20px 20px 56px" : "0 20px")};
    }
    > svg {
      position: absolute;
      top: 16px;
      left: 25px;
      width: 16px;
      height: 16px;
      display: ${({ isShowed }) => (isShowed ? "block" : "none")};
    }
  }
`;

export const ExtraSponsors = styled(ExtraSectors)`
  position: absolute;
  right: 70px;
  top: 18px;
`;
