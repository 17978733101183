import { setCompanyInfo, setLoaderSvg } from "./companyInfoActions";
import { handleActions } from "helpers/immer";

const initialState = () => ({
  companyInfo: null,
  theme: null,
  loaderSvg: null,
});

export const companyInfoReducer = handleActions(
  {
    [setCompanyInfo]: (draftState, { payload: { companyInfo, theme } }) => {
      draftState.companyInfo = companyInfo;
      draftState.theme = theme;
    },
    [setLoaderSvg]: (draftState, { payload }) => {
      draftState.loaderSvg = payload;
    },
  },
  initialState()
);
