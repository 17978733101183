import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { ReactComponent as ArrowRight } from "assets/small-arrow-right.svg";
import { ReactComponent as ArrowLeft } from "assets/small-arrow-left.svg";
import { ReactComponent as CloseIcon } from "assets/x-circle.svg";
import { ReactComponent as SuspendIcon } from "assets/slash.svg";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { ReactComponent as ArrowIcon } from "assets/arrow-up-right.svg";
import { ReactComponent as CheckCircleIcon } from "assets/check-circle.svg";
import { ReactComponent as CrossIcon } from "assets/x.svg";
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as ArrowCircle } from "assets/arrow-left-circle.svg";
import BackgroundInv from "assets/sign-in-investure.png";
import { icons } from "models/icons";
import config from "config";
import { Btn } from "./buttons";
import { Progress, ProgressContainer } from "components/admin-layout/pages/AssetDetails/styled";
import { fullVwVh, column, borderBox } from "components/common/mixins";
import { Text as StartText, Heading as StartHeading } from "components/common/typography";
import { ContainerFlex } from "components/common/containers/_index";
import { AbsoluteBlock, Column, SvgContainer } from "components/common/containers";

import { closeStartModal } from "modules/dataRedux/dataAction";

const StyledArrowRight = styled(ArrowRight)`
  margin-left: 8px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  margin-right: 8px;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  margin-right: 8px;
`;

// TODO check all Props zIndex
const Background = styled.section.attrs(() => ({ "data-lvl-local": true }))`
  ${fullVwVh}
  background: ${({ bg = "rgba(0, 0, 0, 0.15)" }) => bg};
  display: flex;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1000)};
  top: ${({ offset = 0 }) => `-${offset}px`};
  padding-bottom: ${({ offset = 0 }) => `${offset}px`};
`;

const Wrapper = styled.div`
  width: ${({ width = false }) => (width ? width : "fit-content")};
  height: ${({ height = "auto" }) => height};
  min-width: 400px;
  max-width: ${({ isBig }) => (isBig ? "1000px" : "800px")};
  ${column}
  background: ${({ bg = "#ffffff" }) => bg};
  border: 1px solid ${({ borderColor = "#f9f9f9" }) => borderColor};
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
  padding: ${({ p = 0 }) => p};
  @media (max-width: 768px) {
    min-width: 200px;
    transform: ${({ translateY = "0" }) => `translateY(${translateY})`};
  }
`;

const Header = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: ${({ p = "25px" }) => p};
  ${borderBox}
  ${column}
  align-items: ${({ align = "center" }) => align};
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const Heading = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: ${({ fs = "16px" }) => fs};
  line-height: 135%;
  margin: ${({ margin = "0px" }) => margin};
  color: ${({ color = "#181818" }) => color};
  text-transform: none;
  display: flex;
  align-items: center;
  > svg {
    margin-right: 18px;
    transition: all 0.2s linear;
    &:hover {
      transform: scale(1.2);
    }
  }
`;

const SubHeading = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #192036;
  margin-bottom: 5px;
`;

const Text = styled.p`
  text-transform: none;
  margin: ${({ noMargin, margin }) => (noMargin ? 0 : margin ? margin : "15px 0 30px")};

  font-style: normal;
  color: #333333;
  font-weight: ${({ bold }) => (bold ? 600 : "normal")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "18px")};
  line-height: 150%;
  text-align: ${({ textAlign = "center" }) => textAlign};
`;

const TextApprove = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #192036;
  text-transform: none;
  margin: ${({ noMargin, margin }) => (noMargin ? 0 : margin ? margin : "15px 0 30px")};

  font-style: normal;
  font-weight: ${({ bold }) => (bold ? 700 : "normal")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "18px")};
  text-align: ${(textAlign = "center") => textAlign};
`;

const Button = styled.button`
  margin: ${({ margin = false }) => (margin ? margin : "")};
  outline: none;
  width: ${({ width = 170 }) => `${width}px`};
  height: 32px;
  background: ${({ grey, green, red, theme: { primary } }) =>
    grey ? "transparent" : green ? "#019D52" : red ? "#ff4343" : primary};
  border-radius: 4px;

  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: ${({ noLineHeight = false }) => (noLineHeight ? "135%" : "")};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0;
  color: ${({ grey }) => (grey ? "#CACACA" : "#ffffff")};
  ${borderBox}
  border: 1px solid
    ${({ grey, green, red, theme: { primary } }) => (grey ? "#CACACA" : green ? "#019D52" : red ? "#ff4343" : primary)};
  cursor: pointer;
  > svg {
    ${({ red, green }) => {
      if (red || green) {
        return `margin-right:7px;
                path{
                  stroke: #fff;
                }`;
      } else return "";
    }}
  }
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

const Flex = styled.div`
  width: 100%;
  display: ${({ noButtons }) => (noButtons ? "none" : "flex")};
  align-items: center;
  justify-content: ${({ jc = false }) => (jc ? jc : "space-between")};
`;

const ImgContainer = styled.div`
  svg {
    width: 488px;
    height: 180px;
    margin: 0 auto 20px;
    display: block;
  }
`;

const IconCont = styled.div`
  margin-left: 7px;
  transform: rotate(180deg);
  display: flex;
  svg {
    margin: auto;
    path {
      stroke: #fff;
    }
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const Card = styled.div`
  width: calc(50% - 10px);
  height: 245px;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  ${borderBox}
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
  ${column}
  align-items: center;
  transition: all 0.1s linear;
  position: relative;
  &::before {
    transition: all 0.1s linear;
    content: "";
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
    position: absolute;
    bottom: -10px;
    ${borderBox}
    border-radius: 50%;
  }
  &::after {
    content: "";
    transition: all 0.1s linear;
    position: absolute;
    bottom: -6px;
    width: 12px;
    height: 12px;
    background: ${({ active, theme: { primary } }) => (active ? primary : "#fff")};
    /* border: 1px solid ${({ theme: { primary } }) => primary}; */
    ${borderBox}
    border-radius: 50%;
  }
`;

const IconBg = styled.div`
  width: 100%;
  height: 160px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  margin-bottom: 14px;
  > svg {
    margin: auto;
    width: 62px;
    height: 62px;
    transition: all 0.1s linear;
    path {
      stroke: ${({ active, theme: { primary } }) => (active ? primary : "#A2B1DE")};
    }
  }
`;

export const GreyHeader = styled.div`
  background: ${({ success, wrong, normal, theme: { quoteBgInvestor } }) =>
    success ? "#EDFBF4" : wrong ? "#FFF1F1" : normal ? "#F6F7FC" : quoteBgInvestor};
  width: 100%;
  height: 70px;
  border-radius: 4px 4px 0 0;
  display: flex;
  position: relative;
  > svg.close-icon {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    path {
      stroke: ${({ success, wrong }) => (success ? "#A8DAC2" : wrong ? "#FFAEAE" : "#B6BFD9")};
    }
  }
  ${({ modal }) =>
    modal
      ? `    align-items: center;
    padding: 0 0 0 25px;
    box-sizing: border-box;`
      : ``}
`;

const Gold = styled.div`
  margin: auto;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  background: ${({ success, wrong, blue = false, theme: { enterprice, primary, red } }) =>
    success ? "#019D52" : wrong ? red : blue ? primary : enterprice};
  transform: translateY(23px);
  > svg {
    width: 36px;
    height: 36px;
    margin: auto;
    path {
      stroke: #ffffff;
    }
  }
`;

const WhiteCircle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: inherit;
  display: inherit;
  border: 2px solid #ffffff;
  margin: auto;
  > svg {
    width: 22px;
    height: 22px;
    margin: auto;
    path {
      stroke: #ffffff;
    }
  }
`;

const UpgradeHeading = styled.h3`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #181818;
  margin: 50px 0 12px;
`;

const UpgrageText = styled.p`
  width: 285px;
  height: ${({ h = "51px" }) => h};
  font-size: 12px;
  line-height: 145%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #181818;
  margin: 0 auto 18px;
`;

export const ErrorMessage = ({ onClose, er, width = "340px" }) => {
  let message;
  if (Array.isArray(er)) {
    message = er.join(", ");
  } else {
    message = er.message;
  }
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper width={width} height="fit-content" p="0 0 30px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader wrong>
          <Gold wrong>
            <CloseIcon />
          </Gold>
          <CrossIcon className="close-icon" onClick={onClose} />
        </GreyHeader>
        <UpgradeHeading>Something Went Wrong</UpgradeHeading>
        <UpgrageText h="fit-content">{message}</UpgrageText>
        <Btn margin="0 auto 0" width={180} onClick={onClose} error>
          Ok, Close
        </Btn>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const ErrorMessagePortal = ({ onClose, er, width = "340px", heading = "Something Went Wrong" }) => {
  let message;
  if (Array.isArray(er)) {
    message = er.join(", ");
  } else {
    message = er.message;
  }
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper width={width} height="fit-content" p="0 0 30px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader wrong>
          <Gold wrong>
            <CloseIcon />
          </Gold>
          <CrossIcon className="close-icon" onClick={onClose} />
        </GreyHeader>
        <UpgradeHeading>{heading}</UpgradeHeading>
        <UpgrageText h="fit-content">{message}</UpgrageText>
        <Btn margin="0 auto 0" width={180} onClick={onClose} error>
          Ok, Close
        </Btn>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const SuccessMessage = ({ onClose, message }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper width="340px" height="fit-content" p="0 0 30px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader success>
          <Gold success>
            <CheckCircleIcon />
          </Gold>
          <CrossIcon className="close-icon" onClick={onClose} />
        </GreyHeader>
        <UpgradeHeading>Congratulations</UpgradeHeading>
        <UpgrageText h="fit-content">{message}</UpgrageText>
        <Btn margin="0 auto 0" width={180} onClick={onClose} fullGreen>
          Close
        </Btn>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const ConfirmMessagePortal = ({
  onClose,
  onSubmit,
  message,
  heading,
  subheading,
  load = false,
  usual = false,
  green = false,
  confirmText = "Confirm",
  additionalAction,
  additionalActionName,
}) => {
  const isAdditional = additionalActionName && additionalAction;
  return ReactDOM.createPortal(
    <Background>
      <Wrapper width="440px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader modal wrong={!usual}>
          <Heading>{heading}</Heading>
          <CrossIcon className="close-icon" onClick={onClose} />
        </GreyHeader>
        <Content>
          {subheading && <SubHeading>{subheading}</SubHeading>}
          <Text fSize={12} dangerouslySetInnerHTML={{ __html: message }} />
          <Flex>
            <Button
              width={130}
              onClick={isAdditional ? additionalAction : onClose}
              grey={!isAdditional}
              red={isAdditional}
              disabled={isAdditional && load}
            >
              {isAdditional ? additionalActionName : "Cancel"}
            </Button>
            <Button width={230} onClick={onSubmit} disabled={load} red={!usual} green={green}>
              {confirmText}
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const ApproveRequestPortal = ({ onClose, onSubmit, message, heading, load = false }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper width="440px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader modal success>
          <Heading>{heading}</Heading>
          <CrossIcon className="close-icon" onClick={onClose} />
        </GreyHeader>
        <Content>
          <TextApprove fSize={16} dangerouslySetInnerHTML={{ __html: message }} noMargin />
          <Text
            fSize={12}
            dangerouslySetInnerHTML={{
              __html: `After the validation an email to set-up the <br/>password will be sent`,
            }}
          />
          <Flex>
            <Button width={130} onClick={onClose} grey>
              CANCEL
            </Button>
            <Button width={230} onClick={onSubmit} disabled={load} green>
              <CheckIcon />
              Yes, approve
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const RejectRequestPortal = ({ onClose, onSubmit, message, heading, load = false }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper width="440px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader modal wrong>
          <Heading>{heading}</Heading>
          <CrossIcon className="close-icon" onClick={onClose} />
        </GreyHeader>
        <Content>
          <TextApprove fSize={16} margin="0 0 30px" dangerouslySetInnerHTML={{ __html: message }} />
          <Flex>
            <Button width={130} onClick={onClose} grey>
              CANCEL
            </Button>
            <Button width={230} onClick={onSubmit} disabled={load} red>
              <SuspendIcon />
              Yes, reject
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const GuidePortal = ({ onClose, text, img }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper width="540px" height="fit-content" p="0 0 30px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader>
          <Gold blue>
            <InfoIcon />
          </Gold>
          <CrossIcon className="close-icon" onClick={onClose} />
        </GreyHeader>
        <UpgradeHeading>Table Guide</UpgradeHeading>
        <UpgrageText h="fit-content" dangerouslySetInnerHTML={{ __html: text }} />
        <ImgContainer>{img}</ImgContainer>
        <Btn margin="0 auto 0" width={180} onClick={onClose}>
          Ok, close
        </Btn>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const CategorySelectorPortal = ({ onClose, history, assetId }) => {
  const [type, setType] = useState("elective");

  const onChangeType = (type) => () => setType(type);

  const onSubmit = () => history.push(`/investor/offerings/${assetId}/${type}/client-categorization`);

  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()} width="440px">
        <Header>
          <Heading>Client Categorization</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <Text fSize={16} bold margin="0 0 24px">
            Please choose what client are you
          </Text>
          <CardContainer>
            <Card active={type === "elective"} onClick={onChangeType("elective")}>
              <IconBg active={type === "elective"}>{icons.userCheck}</IconBg>
              <Text fSize={14} bold margin="0">
                Elective Professional Client
              </Text>
            </Card>
            <Card active={type === "professional"} onClick={onChangeType("professional")}>
              <IconBg active={type === "professional"}>{icons.userPlus}</IconBg>{" "}
              <Text fSize={14} bold margin="0">
                Professional <br /> Client
              </Text>
            </Card>
          </CardContainer>
          <Flex>
            <Button width={94} height={36} onClick={onClose} grey>
              CLOSE
            </Button>
            <Button height={36} width={266} onClick={onSubmit}>
              Confirm & Next <IconCont>{icons.left}</IconCont>
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const UpdateEmailSettingsPortal = ({ onClose, error }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{error ? "Unsuccess" : "Success"}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          {error ? (
            <Fragment>
              <Text
                noMargin
                fSize={14}
                dangerouslySetInnerHTML={{
                  __html: `The email integration has been unsuccessful.`,
                }}
              />
              <Text
                fSize={12}
                dangerouslySetInnerHTML={{
                  __html: `Please check that your credentials are right or with your
                          <br/> 
                          email provider if you are allowed to perform such an action.`,
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Text
                noMargin
                fSize={14}
                dangerouslySetInnerHTML={{
                  __html: `A test email has just been sent to your account.<br/>
                   Please open the email to make sure 
                    the integration <br/> has been successful.`,
                }}
              />
              <Text
                fSize={12}
                dangerouslySetInnerHTML={{
                  __html: `In case you didn’t receive this email, please check 
                  <br/> with you email provider or contact 
                  <br/> us at <u>contact@valktech.io</u>`,
                }}
              />
            </Fragment>
          )}
          <Flex jc="center">
            <Button width={100} height={36} onClick={onClose} green={!error} red={error}>
              CLOSE
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const ModalPortal = ({
  onClose,
  onSubmit,
  heading,
  load = false,
  submitText = "Ok",
  red = false,
  children,
  width,
  noCancel = false,
  disableBackdrop = false,
  extraButton = null,
  contentPadding = "25px",
  closeText = "CLOSE",
  submitBtnWidth = 100,
  closeBtnWidth = 100,
  headingFz = "16px",
  btnIcon = null,
  crossClose = false,
  onBack,
  noButtons = false,
  translateY,
  isAdditional = false,
  isBig = false,
  offset = 0,
}) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => (document.body.style.overflowY = "auto");
  }, []);
  return ReactDOM.createPortal(
    <Background offset={offset} onClick={disableBackdrop ? undefined : onClose}>
      <Wrapper width={width} onClick={(e) => e.stopPropagation()} translateY={translateY} isBig={isBig}>
        <GreyHeader modal wrong={red}>
          <Heading fs={headingFz}>
            {isAdditional && <ArrowCircle onClick={onBack} />}
            {heading}
          </Heading>
          <CrossIcon className="close-icon" onClick={onClose} />
        </GreyHeader>
        <Content p={contentPadding}>
          {children}
          <Flex noButtons={noButtons}>
            <Button
              style={{ visibility: noCancel ? "hidden" : "visible" }}
              width={closeBtnWidth}
              height={36}
              onClick={crossClose ? onBack : onClose}
              grey
            >
              {btnIcon === "confirm" ? <StyledArrowLeft /> : null}
              {closeText}
            </Button>
            {extraButton}
            <Button height={36} onClick={onSubmit} disabled={load} red={red} width={submitBtnWidth}>
              {btnIcon === "confirm" ? <StyledCheckIcon /> : null}
              {submitText}
              {btnIcon === "next" ? <StyledArrowRight /> : null}
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("round-modal")
  );
};

export const InfoPortal = ({
  onClose,
  onSubmit,
  heading = "Upgrade Your Plan",
  text = `To use this feature you should upgrade your plan to «Enterprise» first. If you want to know more about the
different plans please click on the button below`,
  submitText = "UPGRADE PLAN",
  icon = <ArrowIcon />,
  blueCircle = false,
}) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => (document.body.style.overflowY = "auto");
  }, []);
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper width="340px" height="280px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader>
          <Gold blue={blueCircle}>
            <WhiteCircle>{icon}</WhiteCircle>
          </Gold>
        </GreyHeader>
        <UpgradeHeading>{heading}</UpgradeHeading>
        <UpgrageText>{text}</UpgrageText>
        <Btn margin="0 auto 0" width={180} onClick={onSubmit}>
          {submitText}
        </Btn>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const FileMessagePortal = ({ onClose, onDownload, message, disable }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper width="340px" height="fit-content" p="0 0 30px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader>
          <Gold blue>
            <InfoIcon />
          </Gold>
          <CrossIcon className="close-icon" onClick={onClose} />
        </GreyHeader>
        <UpgradeHeading>Information</UpgradeHeading>
        <UpgrageText h="fit-content" dangerouslySetInnerHTML={{ __html: message }} />
        <Btn margin="0 auto 0" width={180} onClick={onDownload} disabled={disable}>
          Download
        </Btn>
      </Wrapper>
    </Background>,
    document.getElementById("round-modal")
  );
};

export const ProgressPortal = ({ onClose, message, heading, progress }) => {
  return ReactDOM.createPortal(
    <Background>
      <Wrapper width="340px" height="fit-content" p="0 0 30px" onClick={(e) => e.stopPropagation()}>
        <GreyHeader modal>
          <Heading>{heading}</Heading>
          {onClose && <CrossIcon className="close-icon" onClick={onClose} />}
        </GreyHeader>
        <Content>
          <Text margin="0 0 20px" fSize={14}>
            {message}
          </Text>
          <ProgressContainer style={{ width: "100%" }}>
            <Progress isLoad width={progress} />
          </ProgressContainer>
        </Content>
        <Btn margin="0 auto 0" width={180} error onClick={onClose}>
          cancel
        </Btn>
      </Wrapper>
    </Background>,
    document.getElementById("round-modal")
  );
};

export const StartPortal = ({
  heading = "Coming soon",
  text = `Thank you for signing up as an investor on the WeReach platform.  We have some of the world's most exclusive investment opportunities in the pipeline.  We will notify you when our first deal is listed, so you do not miss out. 
`,
  subtext = "In the meantime, please check out our Education Hub.",
  wish = `Kind regards The WeReach Team`,
  submitText = "Go to Education Hub",
}) => {
  const dispatch = useDispatch();
  const { platformLogo } = useSelector((s) => s.companyInfoReducer.companyInfo);
  const current = process.env.REACT_APP_CURRENT;

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = "auto");
  }, []);

  const onSubmit = () => window.open("https://wereach.com.au/", "_blank");

  const onClose = () => dispatch(closeStartModal());

  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper style={{ overflow: "hidden" }} width="440px" onClick={(e) => e.stopPropagation()}>
        <ContainerFlex paddingSize="10px 10px" h="100px" bg={`100%/cover no-repeat url(${BackgroundInv})`}>
          <SvgContainer size={30} stroke="#fff" m="0 0 0 auto" onClick={onClose} pointer>
            <CrossIcon />
          </SvgContainer>
          <AbsoluteBlock w="230px" right={105} top={25}>
            <img
              src={platformLogo ? platformLogo : config[current].logo}
              alt="logo"
              style={{ width: "100%", height: "100%" }}
            />
          </AbsoluteBlock>
        </ContainerFlex>
        <Column w="100%" p="25px 35px">
          <Flex jc="center">
            <StartHeading fs={16} m="0 0 20px">
              {heading}
            </StartHeading>
          </Flex>
          <StartText light center m="0 0 20px">
            {text}
          </StartText>
          <StartText light center m="0 0 20px">
            {subtext}
          </StartText>
          <StartText light center m="0 0 20px">
            {wish}
          </StartText>
          <Btn margin="0 auto 0" width={180} onClick={onSubmit}>
            {submitText}
          </Btn>
        </Column>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};
