//development envs
import DEV from "config/devs/dev";
import INVESTURE from "config/devs/investure";
import GLAFKA_DEV from "config/devs/glafka-dev";
import ANTON_DEV from "config/devs/anton-dev";
//test envs
import TEST from "config/tests/test";
import USTART2 from "config/tests/u-start2";
import USTART1 from "config/tests/u-start1";
import VALK_DEMO from "config/tests/demo";
import INFINITY from "config/tests/infinity";
import ALPHIER from "config/tests/alphier";
import FREEMIUM_TEST from "config/tests/freemium-test";
//production envs
import VALK_PROD from "config/productions/valk";
import GLAFKA_PROD from "config/productions/glafka";
import INVESTURE_PROD from "config/productions/investure";
import FREEMIUM_PROD from "config/productions/freemium-prod";
//general
import FREEMIUM_DEV from "config/devs/freemium";

const config = {
  DEV,
  TEST,
  INVESTURE,
  USTART2,
  USTART1,
  GLAFKA_DEV,
  GLAFKA_PROD,
  VALK_DEMO,
  INFINITY,
  VALK_PROD,
  INVESTURE_PROD,
  ALPHIER,
  FREEMIUM_DEV,
  ANTON_DEV,
  FREEMIUM_PROD,
  FREEMIUM_TEST,
};

export default config;
