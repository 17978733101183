// dev config
// export default {
//   origin: `https://valkpro.dealroom-app.com`,
//   url: "https://valk-api-prod-v2.cf",
//   domain: ".dealroom-app.com",
//   logoStyles: {},
// };

//server config
export default {
  origin: window.location.origin,
  url: window.location.origin,
  domain: ".dealroom-app.com",
  logoStyles: {},
};
