import store from "index";

function signContractRoute(username = ":username") {
  return `/setup/sign-contract/${username}`;
}

signContractRoute.toString = function () {
  return `/setup/sign-contract/${store.getState().userReducer.userInfo.email}`;
};

function createAssetRoute(username = ":username") {
  return `/setup/asset-creation/${username}`;
}

createAssetRoute.toString = function () {
  return `/setup/asset-creation/${store.getState().userReducer.userInfo.email}`;
};

function createDealDataRoomRoute(assetId = "id", dealsType = "type") {
  return `/setup/data-room/${assetId}/${dealsType}`;
}

createDealDataRoomRoute.toString = function () {
  const deals = store.getState().dataRoomReducer.foldersViewAdmin || [];
  const { assetId, dealsType } = deals[0] || {};
  if (assetId && dealsType) return `/setup/data-room/${assetId}/${dealsType}`;
  else return `/setup/data-room`;
};

export function extendModel(modelByServer) {
  model.forEach((step, index, self) => {
    console.log(modelByServer[step.name]);

    self[index] = {
      ...step,
      ...(typeof modelByServer[step.name] === "object" ? modelByServer[step.name] : {}),
      skip: step.skip,
    };
  });

  return model;
}

export function modelToApi() {
  return model.reduce(
    (_, item) => ({
      ..._,
      [item.name]: {
        done: item.done,
        skip: item.skip,
      },
    }),
    {}
  );
}

export const model = [
  {
    name: "SIGN_CONTRACT",
    done: false,
    route: signContractRoute,
    skip: null,
  },
  {
    name: "SUCCESS_MESSAGE",
    done: false,
    route: "/setup/dashboard",
    skip: "PROFILE_SETUP_MESSAGE",
  },
  {
    name: "ASSET_CREATION_MESSAGE",
    done: false,
    route: "/setup/dashboard",
    skip: "PROFILE_SETUP_MESSAGE",
  },
  {
    name: "CREATE_ASSET",
    done: false,
    route: createAssetRoute,
    skip: "PROFILE_SETUP_MESSAGE",
  },
  {
    name: "CREATE_DATA_ROOM_FOR_ASSET_LINK_MESSAGE",
    done: false,
    route: "/setup/dashboard",
    skip: "PROFILE_SETUP_MESSAGE",
  },
  {
    name: "CREATE_DATA_ROOM_FOR_ASSET_BUTTON_MESSAGE",
    done: false,
    route: "/setup/data-room/all",
    skip: "PROFILE_SETUP_MESSAGE",
  },
  {
    name: "CREATE_DATA_ROOM_FOR_ASSET",
    done: false,
    route: createDealDataRoomRoute,
    skip: "PROFILE_SETUP_MESSAGE",
  },
  {
    name: "PROFILE_SETUP_MESSAGE",
    done: false,
    route: "/setup/dashboard",
    skip: "COMMUNICATION_SETUP_MESSAGE",
  },
  {
    name: "PROFILE_SETUP",
    done: false,
    route: "/setup/account",
    skip: "COMMUNICATION_SETUP_MESSAGE",
  },
  {
    name: "COMMUNICATION_SETUP_MESSAGE",
    done: false,
    route: "/setup/dashboard",
    skip: "WEBSITE_SETUP_MESSAGE",
  },
  {
    name: "EMAIL_SETTINGS_MESSAGE",
    done: false,
    route: "/setup/comunication-setup/email-settings",
    skip: "WEBSITE_SETUP_MESSAGE",
  },
  {
    name: "EMAIL_SETTINGS",
    done: false,
    route: "/setup/comunication-setup/email-settings",
    skip: "WEBSITE_SETUP_MESSAGE",
  },
  {
    name: "WEBSITE_SETUP_MESSAGE",
    done: false,
    route: "/setup/dashboard",
    skip: "INFORM_USERS_MESSAGE",
  },
  {
    name: "WEBSITE_SETUP",
    done: false,
    route: "/setup/website-settings",
    skip: "INFORM_USERS_MESSAGE",
  },
  {
    name: "INFORM_USERS_MESSAGE",
    done: false,
    route: "/setup/dashboard",
    skip: "UPGRADE_PLATFORM",
  },
  {
    name: "MAILING_USERS",
    done: false,
    route: "/setup/comunication-setup/email-mailing",
    skip: "UPGRADE_PLATFORM",
  },
  {
    name: "UPGRADE_PLATFORM",
    done: false,
    route: "/admin/full-dashboard",
    skip: "END_SETUP",
  },
  {
    name: "END_SETUP",
    done: false,
    route: "/admin/dashboard",
  },
];

export function getRouteByStep(stepName = "") {
  const step = model.find((e) => e.name === stepName);

  if (step) return `${step.route}`;
  return "";
}

export const requiredDescriptions = {
  CREATE_DATA_ROOM_FOR_ASSET:
    'Please click on "Save to Draft" or "Save & Publish Asset" to not lose actions made in the Data Room',
  EMAIL_SETTINGS:
    "Please finish setting up the email integration or choose the default email to start inviting your investors",
};
