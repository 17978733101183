import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
`;

export const Column = styled(Flex)`
  flex-direction: column;
  width: ${({ w = "auto" }) => w};
  padding: ${({ p = 0 }) => p};
  margin: ${({ m = 0 }) => m};
  box-sizing: border-box;
  background: ${({ bg }) => bg};
`;

export const FlexCenterAuto = styled(Flex)`
  margin: 0 auto;
`;

export const ColumCenterAuto = styled(Column)`
  margin: 0 auto;
`;

export const Row = styled(Flex)`
  align-items: ${({ align = "flex-start" }) => align};
  justify-content: ${({ justify = "space-between" }) => justify};
  position: ${({ position = "static" }) => position};
  margin: ${({ m = 0 }) => m};
  padding: ${({ p = 0 }) => p};
  width: ${({ w = "auto" }) => w};
  height: ${({ h }) => h};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
`;

export const AbsoluteBlock = styled.div`
  width: ${({ w = "auto" }) => w};
  height: ${({ h = "auto" }) => h};
  position: absolute;
  right: ${({ right = 0 }) => `${right}px`};
  top: ${({ top = 0 }) => `${top}px`};
`;

export const TableRow = styled(Row)`
  background: ${({ gray, theme: { adminTableRowGrey } }) => (gray ? adminTableRowGrey : "inherit")};
  height: ${({ h }) => `${h}px`};
`;

export const SvgContainer = styled(Flex)`
  margin: ${({ m }) => m};
  position: ${({ position = "relative" }) => position};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
  top: ${({ t = "auto" }) => t};
  right: ${({ r = "auto" }) => r};
  > svg {
    width: ${({ size = 16 }) => `${size}px`};
    height: ${({ size = 16 }) => `${size}px`};
    transform: ${({ tf }) => tf};
    transition: all 0.25s linear;
    path {
      stroke: ${({ stroke, theme: { primary } }) => (stroke ? stroke : primary)};
      fill: ${({ fill }) => fill};
    }
  }
`;

export const WrapRow = styled(Row)`
  flex-wrap: wrap;
`;
