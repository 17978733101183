import React from "react";
import styled, { keyframes } from "styled-components";
import { useSelector } from "react-redux";

import theme from "models/themes";

const progressCircular = keyframes`
    0% {
        transform: rotate(0deg);
    }
    12.5% {
        transform: rotate(180deg);
        animation-timing-function: linear;
    }
    25% {
        transform: rotate(630deg);
    }
    37.5% {
        transform: rotate(810deg);
        animation-timing-function: linear;
    }
    50% {
        transform: rotate(1260deg);
    }
    62.5% {
        transform: rotate(1440deg);
        animation-timing-function: linear;
    }
    75% {
        transform: rotate(1890deg);
    }
    87.5% {
        transform: rotate(2070deg);
        animation-timing-function: linear;
    }
    100% {
        transform: rotate(2520deg);
    }
`;

const progressCircularPseudo = keyframes`
    0% {
        transform: rotate(-30deg);
    }
    29.4% {
        border-left-color: transparent;
    }
    29.41% {
        border-left-color: currentColor;
    }
    64.7% {
        border-bottom-color: transparent;
    }
    64.71% {
        border-bottom-color: currentColor;
    }
    100% {
        border-left-color: currentColor;
        border-bottom-color: currentColor;
        transform: rotate(225deg);
    }
`;

const fill = keyframes`
    0% {
        background:transparent;
    }
    50% {
      background:${theme.primary};
    }
`;

export const Progress = styled.progress`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  color: ${({ color }) => color};
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  width: ${({ size = 40 }) => `${size}px`};
  height: ${({ size = 40 }) => `${size}px`};
  background-color: transparent;
  font-size: ${({ size = 40 }) => `${size / 3}px`};
  overflow: hidden;
  &::-webkit-progress-bar {
    background-color: transparent;
  }
  &:indeterminate {
    -webkit-mask-image: linear-gradient(transparent 50%, black 50%),
      linear-gradient(to right, transparent 50%, black 50%);
    mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
    animation: ${progressCircular} 6s infinite cubic-bezier(0.3, 0.6, 1, 1);
    &::before,
    &::-webkit-progress-value {
      content: "";
      display: block;
      box-sizing: border-box;
      margin-bottom: 0.25em;
      border: solid 0.25em transparent;
      border-top-color: currentColor;
      border-radius: 50%;
      width: 100% !important;
      height: 100%;
      background-color: transparent;
      animation: ${progressCircularPseudo} 0.75s infinite linear alternate;
    }
    &::-moz-progress-bar {
      box-sizing: border-box;
      border: solid 0.25em transparent;
      border-top-color: currentColor;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-color: transparent;
      animation: ${progressCircularPseudo} 0.75s infinite linear alternate;
    }
    &::-ms-fill {
      animation-name: -ms-ring;
    }
  }
`;

const Block = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  justify-content: center;
  > .svg-container {
    position: absolute;
    width: 90%;
    height: 90%;
    align-self: center;
    z-index: 2;
    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .layout {
    position: absolute;
    width: 90%;
    height: 90%;
    align-self: center;
    z-index: 1;
    animation: ${fill} 6s linear infinite;
    border-radius: 50%;
  }
`;

export const CircularProgress = ({ size, style, className = "ValkCircularProgress" }) => {
  const svg = useSelector((s) => s.companyInfoReducer.loaderSvg);
  return (
    <Block style={style} className={className}>
      <Progress size={size} color={theme.primary} />
      <div className="svg-container" dangerouslySetInnerHTML={{ __html: svg }} />
      <div className="layout" />
    </Block>
  );
};
