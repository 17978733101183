import * as data from "./chatAction";
import { handleActions } from "../../helpers/immer";

import moment from "moment";

function initState() {
  return {
    socketConnect: false,
    messages: [],
    unreadMessages: "",
    assetMessages: [],
    chatThemes: [],
    adminChatThemes: [],
    assetMultiChat: {},
    currentChatId: "",
    adminChatReady: false,
  };
}

export const chatReducer = handleActions(
  {
    [data.setCurrentChatId]: (draftState, { payload }) => {
      draftState.currentChatId = payload;
      draftState.messages = [...(draftState.assetMultiChat[payload] || [])];
    },
    [data.setChatMessages]: (draftState, { payload }) => {
      console.log(payload);
      const id = ((payload && payload[0]) || {}).assetId;
      if (id) {
        const hashMap = {};

        draftState.assetMultiChat = {
          ...draftState.assetMultiChat,
          [id]: [...payload, ...(draftState.assetMultiChat[id] || [])]
            .filter((e) => {
              if (hashMap[e.id]) return false;
              else {
                hashMap[e.id] = true;
                return true;
              }
            })
            .sort((a, b) => +moment(b.date).format("xxx") - +moment(a.date).format("xxx")),
        };

        draftState.messages = [...(draftState.assetMultiChat[draftState.currentChatId] || [])];
      }
    },
    [data.setUnreadMessages]: (draftState, { payload }) => {
      draftState.unreadMessages = payload;
    },
    [data.setChatThemes]: (draftState, { payload }) => {
      draftState.chatThemes = payload;
    },
    [data.setSocketConnectionStatus]: (draftState, { payload }) => {
      draftState.socketConnect = payload;
    },
    [data.setAdminChatThemes]: (draftState, { payload }) => {
      draftState.adminChatThemes = payload;
      draftState.adminChatReady = true;
    },
    [data.clearMessages]: (draftState, { payload }) => {
      draftState.messages = payload;
    },
  },
  initState()
);
