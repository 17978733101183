import logo from "assets/logos/alphiner.png";

export default {
  origin: "https://valk-alphier.cf",
  url: "https://valueonchain.tk",
  logo: logo,
  logoStyles: {
    auth: `width: 310px;height: 90px;margin-bottom: 30px;>img{width: 100%;filter: invert(1);}`,
    admin: `filter: invert(1);height: 60px;position: relative;left: -5px;`,
    investor: `>img{filter: invert(1); @media(max-width:1280px){width:160px}}`,
  },
  title: "Alphier Capital",
  favicon: "/alphier.ico",
};
