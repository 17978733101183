import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";

const firebaseConfig = (({
  REACT_APP_apiKey,
  REACT_APP_authDomain,
  REACT_APP_databaseURL,
  REACT_APP_projectId,
  REACT_APP_storageBucket,
  REACT_APP_messagingSenderId,
  REACT_APP_appId,
  REACT_APP_measurementId,
}) => ({
  apiKey: REACT_APP_apiKey,
  authDomain: REACT_APP_authDomain,
  databaseURL: REACT_APP_databaseURL,
  projectId: REACT_APP_projectId,
  storageBucket: REACT_APP_storageBucket,
  messagingSenderId: REACT_APP_messagingSenderId,
  appId: REACT_APP_appId,
  measurementId: REACT_APP_measurementId,
}))(process.env);

let _app = null;
let _messaging = null;

if (firebase.messaging.isSupported()) {
  _app = firebase.initializeApp({ ...firebaseConfig });
  _messaging = _app.messaging();
}

export const app = _app;
export const messaging = _messaging;

if (navigator && navigator.serviceWorker && messaging && messaging.onMessage) {
  // navigator.serviceWorker.addEventListener("message", (event) => {
  //   try {
  //     const { data } = event.data;
  //     if (data) {
  //       console.log(data);
  //     }
  //   } catch (error) {
  //     console.clear();
  //     console.log(error);
  //   }
  // });

  messaging.onMessage(function (payload) {
    try {
      new Notification(payload.notification.title, {
        ...payload.notification,
        renotify: true,
      });

      // messaging.showNotification(notificationTitle, notificationOptions);
    } catch (error) {
      console.log(error);
    }
  });
}

export const requestToken = () => {
  return new Promise((res) => {
    if (messaging) {
      messaging
        .getToken({ vapidKey: process.env.REACT_APP_PUBLIC_KEY })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("currentToken", currentToken);
            // TODO: save token
            res(currentToken);
          } else {
            res(null);
            console.log("No Instance ID token available. Request permission to generate one.");
          }
        })
        .catch((err) => {
          res(null);
          console.log("An error occurred while retrieving token. ", err);
        });
    }
  });
};
