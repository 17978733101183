import DEV from "./dev";
import INVESTURE from "./investure";
import ALPHIER from "./alphier";

const INVESTURE_PROD = { ...INVESTURE };

const current = process.env.REACT_APP_CURRENT;

const themes = { DEV, INVESTURE, INVESTURE_PROD, ALPHIER };

const palete = themes[current] || themes.DEV;

export default palete;
