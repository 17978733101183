class GlobalFetchInterceptor {
  excludeRoute403 = [];
  excludeRoute500 = [];
  excludeRoutePageUrl = [];
  onError = console.log;

  constructor(excludeRoute403, excludeRoute500, excludeRoute404, excludeRoutePageUrl, onError) {
    this.excludeRoute403 = excludeRoute403 || [];
    this.excludeRoute500 = excludeRoute500 || [];
    this.excludeRoute404 = excludeRoute404 || [];
    this.excludeRoutePageUrl = excludeRoutePageUrl || [];
    this.onError = onError;

    const self = this;

    (function () {
      const _fetch = window.fetch;
      window.fetch = function (...args) {
        return _fetch.apply(this, args).then(async (response) => {
          if (
            response.status === 403 &&
            args &&
            args[0] &&
            excludeRoute403.every((e) => !args[0].includes(e)) &&
            excludeRoutePageUrl.every((e) => !window.location.href.includes(e)) &&
            !args[0].includes("s3.amazonaws.com")
          ) {
            const assetsView = localStorage.getItem("defaultView");
            localStorage.clear();
            if (assetsView) localStorage.setItem("defaultView", assetsView);
            window.location.href = "/";
          } else if (
            (response.status === 500 || response.status === 400) &&
            args &&
            args[0] &&
            excludeRoute500.every((e) => !args[0].includes(e)) &&
            excludeRoutePageUrl.every((e) => !window.location.href.includes(e))
          ) {
            const json = response.json;
            response.json = async () => {
              const data = await json.apply(response);
              self.errorModalHandler(data, args[0]);
              return data;
            };

            const text = response.text;
            response.text = async () => {
              const data = await text.apply(response);
              self.errorModalHandler(data, args[0]);
              return data;
            };
          } else if (
            response.status === 404 &&
            args &&
            args[0] &&
            excludeRoute404.every((e) => !args[0].includes(e)) &&
            excludeRoutePageUrl.every((e) => !window.location.href.includes(e))
          ) {
            const json = response.json;
            response.json = async () => {
              const data = await json.apply(response);
              self.errorModalHandler(data, args[0]);
              return data;
            };

            const text = response.text;
            response.text = async () => {
              const data = await text.apply(response);
              self.errorModalHandler(data, args[0]);
              return data;
            };
          }
          return new Promise((res) => res(response));
        });
      };
    })();
  }

  errorModalHandler(data, url) {
    let message = "Unknown Error";
    if (data && data.message) {
      message = data.message;
    } else {
      try {
        message = JSON.parse(data).message;
      } catch (err) {
        // console.log(data, err);
      }
    }
    this.onError && this.onError(message, url, window.location.pathname);
  }
}

let _ = null;

// Пример патерна сингелтон
export function factorySingleTon(
  excludeRoute403 = [],
  excludeRoute500 = [],
  excludeRoute404 = [],
  excludeRoutePageUrl = [],
  onError
) {
  if (_) return _;
  _ = new GlobalFetchInterceptor(excludeRoute403, excludeRoute500, excludeRoute404, excludeRoutePageUrl, onError);
}
