import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ContainerFlex } from "../containers/container";

import { ReactComponent as CheckIcon } from "assets/check-icon.svg";
import Api from "helpers/api";
import { icons } from "models/icons";
import { textToDot } from "components/common/mixins";
/* Это называеться костыль :( */
export const NoneInput = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
`;
export const WrapperEditor = styled.div`
  visibility: ${({ visible = true }) => (visible ? "visible" : "hidden")};
  margin-top: 30px;
  position: relative;
  border: 1px solid #d3e0f3;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
  & .txt._se_command_format {
    position: relative;
    min-height: 20px;
    opacity: 1;

    &:after {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background: white;
      top: 0;
      left: 0;
    }

    &[data-focus="P"]:after {
      content: "Paragraph";
    }

    &[data-focus="H1"]:after {
      content: "Headline 1";
    }

    &[data-focus="H2"]:after {
      content: "Headline 2";
    }

    &[data-focus="H3"]:after {
      content: "Headline 3";
    }
  }

  & .se-btn.se-btn-select.se-btn-tool-format.se-tooltip {
    &:focus {
      box-shadow: none;
    }
    & div:last-child {
      display: flex;
      align-items: center;
      padding-left: 15px;
      height: 35px;
      width: 100%;
    }
  }

  & .sun-editor-editable {
    padding: 20px;
    word-break: normal;
  }
  & .se-toolbar.sun-editor-common {
    min-height: 65px;
    background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .se-btn.se-btn-select.se-btn-tool-format.se-tooltip {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
    }

    .se-menu-list,
    .se-btn-module {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 10px 0;
    }

    .se-menu-list {
      padding: 10px 15px;
    }

    .se-menu-list > li:nth-child(1) {
      max-width: 120px;
      min-width: 120px;
    }

    .se-menu-list > li {
      background: white;
      margin-right: 0.75vw !important;

      &:nth-child(3n) {
        margin-right: 2vw !important;
      }
      &:first-child {
        margin-right: 2vw !important;
        margin-left: 0px !important;

        & * {
          max-width: 100%;
        }
      }

      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
`;

export const Text = styled.span`
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - 5px));
`;

export const ContainerDatePicker = styled(ContainerFlex)`
  padding-top: 0;
  padding-bottom: 0;

  & .react-datepicker-wrapper {
    width: ${({ widthPicker = "calc(50% - 10px)" }) => widthPicker};
  }
  position: relative;
  overflow: visible;
`;

export const LabelDate = styled.span.attrs(() => ({
  "data-lvl-relative": true,
}))`
  position: absolute;
  left: 0;
  /* z-index: 999; */
  transform: translate(18px, -12px);
  background: white;
`;

export const LocationWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const LocationSuggestWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
`;

export const NoAvatar = styled.div`
  cursor: pointer;
  width: ${({ sponsorBg }) => (sponsorBg ? "234px" : "54px")};
  height: 54px;
  border-radius: ${({ sponsorBg }) => (sponsorBg ? 0 : "50%")};
  background: #8a8a8a4f;
`;

export const Avatar = styled.img`
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  ${({ small = false }) => {
    if (small) {
      return `
        width: 20px;
        height: 20px;
      `;
    }

    return `
        width: 54px;
        height: 54px;
      `;
  }}
  width: ${({ sponsorBg }) => sponsorBg && "234px"};
  border-radius: ${({ sponsorBg }) => (sponsorBg ? 0 : "50%")};
`;

export const FooterBlock = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "42px")};
  border: ${({ noBorder, active, theme: { primary } }) =>
    noBorder ? "" : active ? `1px solid ${primary}` : "1px solid #EFEFEF"};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ noBorder }) => (noBorder ? "" : "0 20px")};
`;

export const FooterBolockTxt = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "12px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  margin: ${({ margin }) => margin};
  color: #181818;
`;

export const CheckBox = styled.div`
  width: 32px;
  height: 16px;
  position: relative;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#EFF0F3")};
  transition: background linear 0.1s;
  border-radius: 8px;
  cursor: pointer;
  order: ${({ leftText = false }) => (leftText ? 1 : 0)};
`;

export const Circle = styled.div`
  width: 14px;
  height: 14px;
  background: ${({ active }) => (active ? "#fff" : "#616161")};
  border-radius: 8px;
  position: absolute;
  right: ${({ active }) => (active ? "1px" : "17px")};
  transition: right linear 0.1s;
  top: 1px;
`;

export const FileName = styled.div`
  ${textToDot}

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  margin-bottom: 10px;
  width: ${({ small }) => (small ? "160px" : "auto")};
`;

export const FileAction = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ del, theme: { red, primary } }) => (del ? red : primary)};
  margin-left: ${({ del }) => (del ? "12px" : "0")};
  cursor: pointer;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > svg {
    width: 20px;
    height: 20px;
  }
`;

export const ChipsOptionWrapper = styled(FlexWrapper)`
  user-select: none;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  ${({ isBorder = false }) => (isBorder ? `border: 1px solid ${({ theme: { primary } }) => primary};` : "")};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 16px;

  margin-bottom: 8px;
  height: 42px;
  ${({ primaryIcon = true }) =>
    primaryIcon
      ? `svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }`
      : ``}
  >svg.check-slim {
    path {
      stroke-width: 1;
    }
  }
`;

export const DocChipsWrapper = styled.div`
  white-space: nowrap;

  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ isInvestor }) => (isInvestor ? "rgba(24, 24, 24, 0.6)" : "rgba(24, 24, 24, 0.4)")};
  margin-bottom: ${({ mb = "12px" }) => mb};
  display: flex;
  flex-wrap: wrap;

  ${({ asSelect, arrowPosition, arrow, height, width, padding, error }) => {
    if (asSelect) {
      return `
        box-sizing: border-box;
        font-size: 12px;
        line-height: 135%;
        font-weight: normal;
        letter-spacing: -0.02em;
        padding: ${padding ? padding : "12px 20px"};
        color: #181818;
        border: 1px solid ${error ? "#FF4343" : "#efefef"};
        background-position: right;
        background-size: 16px;
        background-repeat: no-repeat;
        background-image: url(${arrow});
        height: ${({ height = "fit-content" }) => height};
        min-height: 42px;
        border-radius: 4px;
        width: ${width ? width : "100%"};
        background-position-x: ${arrowPosition ? `calc(100% - ${arrowPosition}px)` : "calc(100% - 24px)"};
      `;
    }
  }}

  &[disabled] {
    background: ${({ errorWithBG = false, theme: { disabledField } }) =>
      errorWithBG ? "rgba(255, 67, 67, 0.05)" : disabledField};
    opacity: 1;
  }
`;

export const ChipsWrapper = styled(FlexWrapper)`
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  padding: 3px 10px;
  margin: 6px;
  text-transform: none;
  > svg {
    margin-left: 10px;
  }
`;

export const GroupName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
`;

export const DocChips = ({ children, onClick, iconHash, iconName, token, image = null, disabled }) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (iconHash && iconName) {
      Api.getAttachment(token, iconHash, iconName).then((b) => setLogo(b));
    } else {
      setLogo(Api.defaultAvatar);
    }
  }, []);
  return (
    <ChipsWrapper onClick={disabled ? false : onClick}>
      <FlexWrapper>
        {image || <Avatar small src={logo} />}
        <div style={{ width: 10 }} />
        {children}
      </FlexWrapper>
      {icons.closeSmall}
    </ChipsWrapper>
  );
};

export const DocOptionsChips = ({ children, onClick, selected, token, iconHash, iconName, image = null }) => {
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    if (iconHash && iconName) {
      Api.getAttachment(token, iconHash, iconName).then((b) => setLogo(b));
    } else {
      setLogo(Api.defaultAvatar);
    }
  }, []);

  return (
    <ChipsOptionWrapper primaryIcon={false} onClick={onClick} isBorder={selected}>
      <FlexWrapper>
        {image || <Avatar small src={logo} />}
        <div style={{ width: 10 }} />
        {children}
        <div style={{ width: 10 }} />
      </FlexWrapper>
      <CheckIcon className="check-slim" style={{ display: selected ? "block" : "none" }} />
    </ChipsOptionWrapper>
  );
};

const AvBlock = styled.div`
  height: 26px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  margin: 0 6px 6px 0;
  display: flex;
`;

export const UserItem = ({ el: { iconHash, iconName, label }, token }) => {
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    if (iconHash && iconName) {
      Api.getAttachment(token, iconHash, iconName).then((b) => setLogo(b));
    } else {
      setLogo(Api.defaultAvatar);
    }
  }, []);

  return (
    <AvBlock>
      <FlexWrapper>
        <Avatar small src={logo} />
        <div style={{ width: 10 }} />
        {label}
        <div style={{ width: 10 }} />
      </FlexWrapper>
    </AvBlock>
  );
};

export const MultiplePrewiev = styled.div`
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  /* line-height: 135%; */
  letter-spacing: -0.02em;
  color: #181818;
  text-transform: none;
  cursor: pointer;
`;

export const EditableField = styled.div`
  height: 22px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const EditBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ w }) => w};
  position: relative;
  top: ${({ t = 0 }) => t};
  > svg {
    cursor: pointer;
    &:first-of-type {
      margin-right: 6px;
      path {
        stroke: ${({ edit = false, theme: { primary, green } }) => (edit ? green : primary)};
      }
    }
    &:last-of-type {
      width: 12px;
      height: 12px;
    }
  }
`;

export const EditInput = styled.input`
  border: none;
  outline: none;
  font-size: 10px;
  width: 100%;
  padding: 0;
  font-weight: 700;
  text-transform: uppercase;
  height: 13px;
  margin-bottom: ${({ isInvestor }) => (isInvestor ? "8px" : "12px")};
  letter-spacing: 0.05em;
  line-height: 135%;
  caret-color: ${({ theme: { primary } }) => primary};
`;

export const ExtraOption = styled.div`
  width: 100%;
  border-top: 1px solid #efefef;
  box-sizing: border-box;
  cursor: pointer;
  color: ${({ theme: { primary } }) => primary};
  font-size: 12px;
  font-weight: bold;
  line-height: 135%;
  letter-spacing: -0.02em;
  padding-top: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    width: 14px;
    height: 14px;
  }
`;
