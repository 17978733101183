import React, { useState, useEffect, Fragment } from "react";
import styled, { keyframes } from "styled-components";
import { CircularProgress } from "components/common/circularProgress";

import { ContainerFlex } from "./containers/container";
import Api from "../../helpers/api";
import { icons } from "models/icons";
import { LogoContainer, LogoImage } from "./dealTypeCard";
import { ReactComponent as MenuIcon } from "../../assets/hidden-menu-icon.svg";
import { ReactComponent as UserIcon } from "../../assets/no-avatar.svg";
import { ReactComponent as OutIcon } from "../../assets/log-out.svg";
import { ReactComponent as UserIcon_ } from "../../assets/user2px.svg";
import { ReactComponent as CaseIcon } from "../../assets/briefcase.svg";
import { MobileMenuNotification, ProfileLink } from "investor-panel/styled";
import { Button } from "./buttons";
import { flexCenter, fullVwVh, column, borderBox } from "components/common/mixins";

const fade = keyframes`from{transform: scale(0);}to{transform: scale(1);}`;

export const Img = styled.img`
  width: ${({ w = "36px" }) => w};
  height: ${({ h = "36px" }) => h};
  border-radius: 50%;
  object-fit: cover;
`;

const WrapperAvatar = styled(ContainerFlex)`
  position: relative;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  overflow: visible;
  ${({ cutHeader }) => (cutHeader ? `margin-left: auto;` : ``)};
`;

const NameContainer = styled.div`
  ${column}
  justify-content: center;
  align-items: flex-start;
  margin-left: 15px;
  overflow: hidden;
  ${({ setup = false }) => (setup ? `width: 110px;` : "")};
  /* min-width: 270px; */
`;

const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ isInvestor }) => (isInvestor ? "#fff" : "#192036")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

const Role = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ isInvestor }) => (isInvestor ? "rgba(255, 255, 255, 0.6)" : "#d5d7dd")};
`;

const MenuContainer = styled.div`
  display: flex;
  ${flexCenter}
  margin-left: 15px;
  cursor: pointer;
  position: relative;
  > svg {
    transition: all 0.15s linear;
  }
  ${({ isOpen, theme: { primary } }) => (isOpen ? `>svg{path{stroke: ${primary};}}` : ``)};
`;

export const Tooltip = styled.div.attrs(() => ({ "data-lvl-tooltip-decoration-front": true }))`
  position: absolute;
  width: ${({ w = 200 }) => `${w}px`};
  background: #ffffff;
  border: 1px solid #edebeb;
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  top: ${({ top = "41px" }) => top};
  right: ${({ right = "-12px" }) => right};
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #181818;
  flex-direction: column;
  padding: ${({ padding = "16px 20px" }) => padding};
  transition: all 0.15s linear;
  transform: ${({ scale }) => `scale(${scale})`};
  svg {
    margin-right: 10px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    width: 24px;
    background-color: #ffffff;
    right: ${({ r = 11 }) => `${r}px`};
    top: ${({ t = -1 }) => `${t}px`};
    border-radius: 2px 2px 0 0;
  }
`;

export const Square = styled.div.attrs(() => ({
  "data-lvl-tooltip-decoration-backend": true,
}))`
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: ${({ noBorder = false }) => (noBorder ? "none" : "1px solid #edebeb")};
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 1px;
  position: absolute;
  bottom: ${({ bottom = "-29px" }) => bottom};
  left: ${({ left = "auto" }) => left};
  transform: rotate(45deg);
  animation: ${fade} 0.15s linear;
`;

export const HiddenLayout = styled.section.attrs(() => ({ "data-lvl-tooltip": true }))`
  ${fullVwVh}
  background-color: transparent;
`;

export const MenuItem = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  color: ${({ theme: { primary, red, greenInvestor }, isRed, isGreen }) =>
    isRed ? red : isGreen ? greenInvestor : primary};
  font-weight: 800;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 11px;
  cursor: pointer;
  transition: all 0.15s linear;
  ${borderBox}
  padding-bottom: 3px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    transform: translateX(5px);
    &::after {
      width: 100%;
    }
  }
  &::after {
    transition: all 0.15s linear;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    background: ${({ theme: { primary, red, greenInvestor }, isRed, isGreen }) =>
      isRed ? red : isGreen ? greenInvestor : primary};
  }
  > svg {
    width: ${({ svgSize = 13 }) => `${svgSize}px`};
    height: ${({ svgSize = 13 }) => `${svgSize}px`};
    margin-right: 7px;
    path {
      stroke: ${({ theme: { primary, red, greenInvestor }, isRed, isGreen }) =>
        isRed ? red : isGreen ? greenInvestor : primary};
    }
  }
`;

export const Border = styled.div`
  border-top: 1px solid #f1f3f8;
  width: 100%;
  margin-bottom: 12px;
`;

const Holder = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

export const AvatarMenu = ({
  userAvatar,
  onLogout,
  username,
  role,
  isInvestor,
  onClick,
  fullHeader,
  setup = false,
  onEditProfile = console.log,
  onEditCompany = console.log,
}) => {
  const [open, setOpen] = useState(false);

  const onChangeOpen = () => setOpen(!open);

  return (
    <WrapperAvatar cutHeader={!fullHeader} data-popup="profile_setup">
      {userAvatar ? (
        <Img src={userAvatar} onClick={onClick} />
      ) : (
        <UserIcon style={{ width: 36, height: 36 }} onClick={onClick} />
      )}
      <NameContainer setup={setup}>
        <Name isInvestor={isInvestor}>{username}</Name>
        <Role isInvestor={isInvestor}>{role}</Role>
      </NameContainer>
      <MenuContainer isOpen={open} aria-controls="simple-menu" onClick={onChangeOpen}>
        <MenuIcon />
        {open ? (
          <Fragment>
            <HiddenLayout onClick={onChangeOpen} />
            <Square scale={Number(open)} />
          </Fragment>
        ) : null}
        <Tooltip scale={Number(open)}>
          {window.location.pathname.includes("admin") ? (
            <Fragment>
              <MenuItem onClick={onEditProfile}>
                <UserIcon_ />
                Edit MY Profile
              </MenuItem>
              <MenuItem onClick={onEditCompany}>
                <CaseIcon />
                Edit COMPANY INFO
              </MenuItem>
              <Border />
            </Fragment>
          ) : null}
          <Holder onClick={onLogout}>
            <MenuItem isRed>
              <OutIcon />
              Log out
            </MenuItem>
          </Holder>
        </Tooltip>
      </MenuContainer>
    </WrapperAvatar>
  );
};

export const AssetLogo = ({ images = [], token, isUnfinished }) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    (async function () {
      if (isUnfinished && images[0] && images[0].base64) {
        setLogo(images[0].base64);
      } else if (!logo && images[0]) {
        const { fileNameWithExtension, secureHash } = images[0];
        setLogo((await Api.getAttachment(token, secureHash, fileNameWithExtension)) || Api.defaultDeal);
      } else {
        setLogo(Api.defaultDeal);
      }
    })();
  }, []);
  return (
    <LogoContainer>
      {logo ? <LogoImage src={logo} alt="logo" /> : <CircularProgress size={20} color="inherit" />}
    </LogoContainer>
  );
};

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const UploadImg = (props) => {
  return (
    <ContainerFlex
      justify="center"
      align="center"
      h="calc(100% - 10px)"
      w="calc(100% - 10px)"
      borderRadius="4px"
      border="1px solid #3B49E7"
    >
      {props.url ? (
        <Image src={props.url} />
      ) : (
        <Button variant="contained" component="label">
          Add picture
          <input type="file" style={{ display: "none" }} onChange={props.uploadFile} />
        </Button>
      )}
    </ContainerFlex>
  );
};

const WrapperMobileAvatar = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 19px;
    right: 13px;
    cursor: pointer;
    width: fit-content;
  }
`;

export const MobileProfileMenu = styled.div`
  display: none;
  @media (max-width: 768px) {
    width: calc(100% - 25px);
    height: 220px;
    display: ${({ open }) => (open ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 62px;
    right: 9px;
    padding: 0px 15px;
    background: #ffffff;
    border: 1px solid #f9f9f9;
    ${borderBox}
    box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
    border-radius: 4px;
    &::before {
      content: "";
      display: block;
      width: 26px;
      height: 26px;
      z-index: 2;
      position: absolute;
      transform: rotate(45deg);
      top: -12px;
      right: 5px;
      background: #fff;
      ${borderBox}
    }
  }
`;

export const ProfileMenu = ({ isOpenProfile, onLogout, newMessages, newNotifications, onChangeOpenProfile }) => {
  const { bell, mobileMessage, mobileProfile, mobileLogout } = icons;
  return (
    <MobileProfileMenu open={isOpenProfile} onClick={(e) => e.stopPropagation()}>
      <HiddenLayout onClick={onChangeOpenProfile} />
      <ProfileLink
        icon={bell}
        text="Notifications"
        newNotifications={newNotifications}
        onChangeOpenProfile={onChangeOpenProfile}
      />
      <ProfileLink
        icon={mobileMessage}
        text="Messages"
        link="/investor/chat"
        newMessages={newMessages}
        onChangeOpenProfile={onChangeOpenProfile}
      />
      <ProfileLink
        icon={mobileProfile}
        text="Profile"
        link="/investor/account/profile"
        onChangeOpenProfile={onChangeOpenProfile}
      />
      <ProfileLink icon={mobileLogout} text="Log Out" onChangeOpenProfile={onLogout} />
    </MobileProfileMenu>
  );
};

export const MobileAvatarMenu = ({ userAvatar, onClick, newMessages = 0, newNotifications = 0 }) => {
  return (
    <WrapperMobileAvatar onClick={onClick}>
      {userAvatar ? (
        <Img w="26px" h="26px" src={userAvatar} onClick={console.log} />
      ) : (
        <UserIcon style={{ width: 26, height: 26 }} onClick={console.log} />
      )}
      {newMessages || newNotifications ? (
        <MobileMenuNotification>{newMessages + newNotifications}</MobileMenuNotification>
      ) : null}
    </WrapperMobileAvatar>
  );
};
