import Api from "./api";

export default class ApiNotification {
  static saveToken(token, deviceId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/firebaseToken`, {
        method: "POST",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: deviceId }),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static removeToken(token, deviceId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/firebaseToken`, {
        method: "DELETE",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: deviceId,
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }
}
