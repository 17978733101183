import logo from "assets/logos/investure-white.png";

export default {
  origin: "https://platform.investure.co",
  url: "https://valk-api-prod.cf",
  logo: logo,
  logoStyles: {
    auth: `width: 327px;height: 94px;margin-bottom: 30px;>img{width: 100%;}`,
    admin: `position: relative;left: -10px;height: 70px;top: 2px;`,
    investor: `> img {margin-top: 5px;}`,
  },
  favicon: "/investure.ico",
  title: "Investure",
};
