import logo from "assets/logos/Infinity.png";

export default {
  origin: "VoC8",
  url: "https://valueonchain.tk",
  logo: logo,
  logoStyles: {
    auth: `width: 100%;height: 120px;margin-bottom: 30px;display:flex;> img {height:100%;margin:auto;}`,
    admin: `margin-left: -10px;height:50px;`,
    investor: `> img {width: 80px;}`,
  },
};
