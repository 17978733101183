import { handleActions } from "../../helpers/immer";
import * as asyncData from "./asyncDataAction";

function initState() {
  return {
    acceptedCurrency: [],
    sponsors: [],
    accesses: [],
    dealTypes: [],
    categories: [],
    investorWorkflowTypes: [],
    ruleOptions: [],
    countriesOption: [],
    lockUpTime: [],
    sponsorsFull: [],
    financialDealTypes: [],
    financialTemplates: {},
    waitAsyncData: true,
  };
}

export const asyncDataReducer = handleActions(
  {
    [asyncData.setAcceptedCurrency]: (draftState, { payload }) => {
      draftState.acceptedCurrency = payload;
    },
    [asyncData.setSponsors]: (draftState, { payload }) => {
      draftState.sponsors = payload;
    },
    [asyncData.addNewSponsor]: (draftState, { payload }) => {
      draftState.sponsors.push(payload.username);
      draftState.sponsorsFull.push(payload);
    },
    [asyncData.updateSponsor]: (draftState, { payload }) => {
      const index = draftState.sponsorsFull.findIndex((el) => el.username === payload.username);
      draftState.sponsorsFull[index] = payload;
    },
    [asyncData.setAccesses]: (draftState, { payload }) => {
      draftState.accesses = payload;
    },
    [asyncData.setDealTypes]: (draftState, { payload }) => {
      draftState.dealTypes = payload;
    },
    [asyncData.setCategories]: (draftState, { payload }) => {
      draftState.categories = payload;
    },
    [asyncData.setInvestorWorkflowTypes]: (draftState, { payload }) => {
      draftState.investorWorkflowTypes = payload;
    },
    [asyncData.setRuleOptions]: (draftState, { payload }) => {
      draftState.ruleOptions = payload;
    },
    [asyncData.setCountriesOption]: (draftState, { payload }) => {
      draftState.countriesOption = payload;
    },
    [asyncData.setLockUpTimes]: (draftState, { payload }) => {
      draftState.lockUpTime = payload;
    },
    [asyncData.setSponsorsFull]: (draftState, { payload }) => {
      draftState.sponsorsFull = payload;
    },
    [asyncData.setFinancialTypes]: (draftState, { payload }) => {
      draftState.financialDealTypes = payload;
    },
    [asyncData.setFinancialTemplates]: (draftState, { payload }) => {
      const templates = { ...payload };
      const _templates = {};
      for (let key in templates) {
        let arr;
        try {
          arr = JSON.parse(templates[key]).map((el) => {
            if ("id" in el) {
              return {
                ...el,
                value: "",
              };
            } else {
              return {
                ...el,
                value: "",
                id: Math.round(Math.random() * 10000),
              };
            }
          });
        } catch (_) {
          arr = [];
        }
        _templates[key] = arr;
      }
      draftState.financialTemplates = _templates;
    },
    [asyncData.setWaitAsyncData]: (draftState) => {
      draftState.waitAsyncData = false;
    },
  },
  initState()
);
