import * as dataRoom from "./dataRoomActions";
import { handleActions } from "../../helpers/immer";

const initialState = () => ({
  legalDocuments: {},
  foldersViewInvestor: null,
  foldersViewAdmin: null,
  investorDeals: {},
  adminDeals: {},
  lastCreatedFolder: "",
});

export const dataRoomReducer = handleActions(
  {
    [dataRoom.setLegalDocomentStatus]: (draftState, { payload }) => {
      const { assetId, name, data } = payload;
      draftState.legalDocuments[assetId] = {
        ...draftState.legalDocuments[assetId],
        [name]: data,
      };
    },
    [dataRoom.setInvestorView]: (draftState, { payload }) => {
      draftState.foldersViewInvestor = payload;
    },
    [dataRoom.setAdminView]: (draftState, { payload }) => {
      draftState.foldersViewAdmin = payload;
    },
    [dataRoom.setInvDealFiles]: (draftState, { payload }) => {
      const { assetId, files } = payload;
      draftState.investorDeals[assetId] = files;
    },
    [dataRoom.setAdmDealFiles]: (draftState, { payload }) => {
      const { assetId, files } = payload;
      draftState.adminDeals[assetId] = files;
    },
    [dataRoom.setLastCreatedFolder]: (draftState, { payload }) => {
      draftState.lastCreatedFolder = payload;
    },
    [dataRoom.updateInvestorFolders]: (draftState, { payload }) => {
      const { assetId, folders } = payload;
      draftState.investorDeals[assetId].folders = folders;
    },
  },
  initialState()
);
