import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { ContainerFlex } from "../../../containers/container";

import { Link as _Link } from "react-router-dom";
import { ReactComponent as Check } from "assets/check.svg";
import { Btn } from "components/admin-layout/buttons";
import { flexCenter, textToDot, fullVwVh, muliFont, column, borderBox } from "components/common/mixins";

export const ReaderWrapper = styled(ContainerFlex)`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
  .pg-viewer-wrapper {
    width: 100% !important;
  }
`;

export const Main = styled.section`
  width: 100%;
  flex: 1;
  ${borderBox}
  background: white;

  ${column}
  ${flexCenter}
  padding: 60px 0;
`;

export const Header = styled.div`
  width: 100%;
  height: ${({ height = 60 }) => height}px;
  border-radius: 4px 0px 4px 4px;
  background: white;
  display: flex;
  align-items: center;
  ${borderBox}
  padding: 0 25px;

  ${({ isFooter = false }) => {
    if (isFooter) return `margin-top: 30px;`;
    else return `margin-bottom: 30px;`;
  }}
  ${({ between = false }) => (between ? "justify-content: space-between;" : "")}
`;

export const TextH3 = styled.h3`
  text-align: center;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: bold;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const TextSub = styled.p`
  text-align: center;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

const RequiredCheckBox = styled.div`
  overflow: hidden;
  width: 18px;
  height: 18px;
  margin-right: 14px;
  background: ${({ checked }) => (checked ? "transparent" : "#f3f3f3")};
  border: 1px solid ${({ checked, theme: { primary } }) => (checked ? primary : "#EFEFEF")};
  ${borderBox}
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    width: 14px;
    height: 14px;
    transform: ${({ checked }) => (checked ? "rotateY(0)" : "rotateY(90deg)")};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    transition: all linear 0.1s;
  }
`;

const ReqText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: #181818;
`;

const ReqRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Required = ({ label, onChange, defaultValue = false }) => {
  const [active, setActive] = useState(defaultValue);

  const onChangeRequired = () => {
    setActive(!active);
  };

  useEffect(() => {
    onChange && onChange(active);
  }, [active]);

  return (
    <ReqRow>
      <RequiredCheckBox checked={active} onClick={onChangeRequired}>
        <Check />
      </RequiredCheckBox>
      <ReqText>{label}</ReqText>
    </ReqRow>
  );
};

export const AddRound = styled(Btn)`
  width: 165px;
  height: 32px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 2px;
  position: ${({ position = "absolute" }) => position};
  /* border: none; */
  /* right: 25px;
  top: 15px; */
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ m = 0 }) => m};
  position: relative;
  > span {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #ffffff;
  }
  > svg {
    margin-right: 8px;
  }
`;

export const DynamicBtn = styled(Btn).attrs(() => ({
  "data-lvl-component": true,
}))`
  width: ${({ small }) => (small ? "90px" : "165px")};
  height: 32px;
  background: ${({ light, theme: { primary } }) => (light ? "#fff" : primary)};
  border: 1px solid ${({ theme: { primary } }) => primary};
  ${borderBox}
  border-radius: 2px;
  position: absolute;
  right: ${({ light, rightPosition }) => (rightPosition ? rightPosition : light ? "242px" : "65px")};
  top: ${({ top = "20px" }) => top};
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 3; */
  > span {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: ${({ light, theme: { primary } }) => (light ? primary : "#fff")};
  }
  > svg {
    margin-right: 8px;
    path {
      stroke: ${({ light, theme: { primary } }) => (light ? primary : "#fff")};
    }
  }
  &:active {
    > span {
      color: #fff;
    }
  }
`;

export const Background = styled.section.attrs(() => ({
  "data-lvl-global": true,
}))`
  ${fullVwVh}
  background: ${({ bg = "rgba(0, 0, 0, 0.15)" }) => bg};
  display: flex;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1005)};
  padding: ${({ p = 0 }) => p};
  ${borderBox}
`;

export const Wrapper = styled.div`
  width: fit-content;
  width: ${({ width = 565 }) => `${width}px`};
  ${column}
  background: ${({ bg = "#ffffff" }) => bg};
  border: 1px solid ${({ borderColor = "#f9f9f9" }) => borderColor};
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: ${({ m = "auto" }) => m};
  align-self: ${({ align = "auto" }) => align};
`;

export const HeaderModal = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const Heading = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

export const Content = styled.div`
  width: 100%;
  padding-left: 25px;
  ${borderBox}
  ${column}
  align-items: center;
  padding: ${({ p }) => p};
`;

export const ContentScroll = styled.div`
  width: calc(100% - 25px);
  ${borderBox}
  ${column}
  align-items: center;
  max-height: 400px;
  overflow-y: ${({ isScroll }) => (isScroll ? "scroll" : "hidden")};
  padding-right: 8px;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const RowInputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FooterModal = styled.div`
  width: 100%;
  margin-top: auto;
  border-top: 1px solid #f6f7fc;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  ${borderBox}
`;

export const RoundBorder = styled.div`
  height: 42px;
  width: 100%;
  border: 1px solid #efefef;
  ${borderBox}
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RoundName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  ${textToDot}
  width: 20%;
  margin-bottom: ${({ mb = "0px" }) => mb};
`;

export const RoundCol = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.7);
  ${textToDot}
  width: calc(25% - 14px);
  > span {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const Icons = styled.div`
  width: ${({ colorPicker }) => (colorPicker ? "75px" : "50px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  align-self: ${({ as = "center" }) => as};
  > svg {
    width: 12px;
    height: 12px;
    &:first-child {
      path {
        stroke: ${({ theme: { primary } }) => primary};
      }
    }
    &:last-child {
      width: 18px;
      height: 18px;
      path {
        stroke: #ff4343;
      }
    }
  }
`;

export const NoIcon = styled.div`
  width: 12px;
  height: 12px;
`;

export const Card = styled.div`
  background: ${({ bg = "white" }) => bg};
  border-radius: 4px;
  ${column}
  padding: ${({ pt = "20px" }) => pt} 25px ${({ pb = "10px" }) => pb} ${({ pl = "25px" }) => pl};
  width: 100%;
  ${borderBox}
  position: relative;
  height: ${({ h = "auto" }) => h};
`;

export const HeadingCard = styled.h6`
  width: 100%;
  font-size: 14px;
  color: #181818;
  margin: 0;
`;

export const RowCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &:first-child {
    margin-bottom: 0;
  }
`;

export const TemplateControls = styled.div`
  width: 100%;
  border-bottom: 1px solid #efefef;
  ${borderBox}
  flex-direction: column;
  margin-bottom: 25px;
  h1 {
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(24, 24, 24, 0.6);
  }
  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const TemplateInfo = styled.div`
  width: calc(33.33% - 20px);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: #8a8a8a;
  height: 42px;
  display: flex;
  align-items: center;
`;

export const BtnBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(66.67% - 10px);
`;

export const TemplateBtn = styled.div`
  width: ${({ w = "calc(50% - 12px)" }) => w};
  height: 42px;
  border: 1px solid ${({ theme: { primary }, active }) => (active ? primary : "#efefef")};
  ${borderBox}
  border-radius: 4px;
  padding-left: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ theme: { textPrimary, textSecondary }, active }) => (active ? textPrimary : textSecondary)};
  cursor: pointer;
  display: flex;
  align-items: center;
  filter: ${({ _disabled }) => (_disabled ? "grayscale(1)" : "none")};
  ::before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme: { primary }, active }) => (active ? primary : "#efefef")};
    ${borderBox}
    border-radius: 50%;
    margin-right: 10px;
  }
  ::after {
    content: "";
    width: 10px;
    height: 10px;
    background: ${({ theme: { primary } }) => primary};
    border-radius: 50%;
    margin-left: 3px;
    position: absolute;
    display: ${({ active }) => (active ? "block" : "none")};
  }
`;

export const DynamicFielsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${({ margin = "35px 0 0" }) => margin};
`;

export const FieldBlock = styled.div`
  margin-top: 20px;
  width: calc(33.33% - 20px);
  &:nth-child(1) {
    margin-top: 0px;
  }
  &:nth-child(2) {
    margin-top: 0px;
  }
  &:nth-child(3) {
    margin-top: 0px;
  }
  &:last-child {
    margin-top: 0px;
  }
`;

export const EditableBlock = styled.div`
  height: 42px;
  border: 1px solid ${({ edit, theme: { primary } }) => (edit ? primary : "#efefef")};
  ${borderBox}
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  > input {
    height: 100%;
    width: calc(100% - 55px);
    padding: 0 20px;
    ${borderBox}
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #181818;
    border: none;
    outline: none;
    position: relative;
    &:disabled {
      background: #fff;
    }
  }
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;

  &:first-child {
    > svg {
      width: 14px;
      height: 14px;
      path {
        stroke: ${({ theme: { primary } }) => primary};
      }
    }
  }
`;

export const PaddingBox = styled.div`
  padding: ${({ p = "0 0 24px" }) => p};
`;

export const Hidden = styled.div.attrs(() => ({ "data-lvl-local": true }))`
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ edit }) => (edit ? "none" : "block")};
  cursor: grab;
  width: ${({ colorPicker }) => (colorPicker ? "calc(100% - 105px)" : "calc(100% - 80px)")};
  height: 100%;
  /* z-index: 2; */
`;

export const Label = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
  margin-bottom: 12px;
`;

export const Separator = styled.div`
  height: 25px;
`;

export const ModalText = styled.p`
  ${muliFont}
  color: ${({ theme: { red }, wrong = false }) => (wrong ? red : "#181818")};
  padding-left: 25px;
  ${({ m }) => (m ? `margin: ${m}` : ``)};
`;

export const RuleBlock = styled.div`
  width: 100%;
  height: 50px;
  background: ${({ active }) => (active ? "#F6F8FB" : "transparent")};
  border: 1px solid #efefef;
  ${borderBox}
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 0 17px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 135%;
  transition: all 0.1s linear;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.6)")};
  > svg {
    margin-right: 20px;
    path {
      stroke: ${({ active, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.6)")};
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RulesColumn = styled.div`
  width: 100%;
  padding: 0 25px;
  ${column}
  ${borderBox}
`;

export const ShowButton = styled.div`
  width: 32px;
  height: 16px;
  position: relative;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#EFF0F3")};
  transition: background linear 0.1s;
  border-radius: 8px;
  cursor: pointer;
  margin-left: auto;
`;

export const Circle = styled.div`
  width: 14px;
  height: 14px;
  background: ${({ active }) => (active ? "#fff" : "#616161")};
  border-radius: 8px;
  position: absolute;
  right: ${({ active }) => (active ? "1px" : "17px")};
  transition: right linear 0.1s;
  top: 1px;
`;

export const PreviewWrapper = styled.section.attrs(() => ({
  "data-lvl-global": true,
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* z-index: 1002; */
  min-height: 100vh;
  background: #f1f1f1;

  ${({ noScroll }) => {
    return noScroll ? `height: 100vh; overflow-y: auto;` : "";
  }}
`;

export const PrevHeader = styled.div`
  height: 80px;
  background: ${({ theme: { enterprice } }) => enterprice};
  ${borderBox}
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Back = styled.div`
  width: 200px;
  height: 32px;
  border: 1px solid #333333;
  ${borderBox}
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #333333;
  cursor: pointer;
  > svg {
    margin-right: 7px;
    path {
      stroke: #333333;
    }
  }
`;

export const PrevHeading = styled.h5`
  font-size: 18px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  margin: 0;
`;

export const Empty = styled.div`
  width: 200px;
`;

export const BottomHeader = styled.div`
  height: 60px;
  background: ${({ theme: { previewHeader } }) => previewHeader};
  ${borderBox}
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PrevText = styled.p`
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
`;

export const ColWrapper = styled.div`
  display: flex;
  max-width: 1440px;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  ${borderBox}
  padding: 30px;
  background: #e5e5e5;
`;
export const Col = styled.div`
  ${column}
  width: ${({ width }) => width};
  ${borderBox}
  padding: ${({ padding }) => padding};
  align-items: ${({ align }) => align};
  height: ${({ height }) => height};
`;

export const NameBlock = styled.div`
  width: 100%;
  ${borderBox}
  padding: 20px 25px;
`;

export const DinRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
  > button {
    position: static;
    &:first-child {
      margin-right: 12px;
    }
  }
`;

export const IbanError = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #ff0000;
  ${borderBox}
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  padding: 20px;
  margin-top: 20px;
  text-align: ${({ center = false }) => (center ? "center" : "left")};
`;

export const SettingsBg = styled.div`
  filter: ${({ disabled }) => (disabled ? "grayscale(1)" : "none")};
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  border-radius: 2px;
  padding: ${({ p = "40px" }) => p};
  width: 100%;
  ${borderBox}
  margin: ${({ m = "0 0 20px" }) => m};
  display: flex;
  align-items: center;
  justify-content: ${({ justify = "center" }) => justify};
  flex-direction: ${({ dir = "column" }) => dir};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const SettingsHeading = styled.h6`
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: ${({ m = "36px 0 14px" }) => m};
`;

export const SettingsText = styled.p`
  font-size: 12px;
  line-height: 135%;
  color: #181818;
  margin: ${({ m = "0 0 27px" }) => m};
  text-align: ${({ align = "center" }) => align};
`;

export const SettingsInfo = styled.div`
  margin: 0 30px;
`;

export const CountingValues = styled.div`
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  width: ${({ w }) => w};
  margin-top: 12px;
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid #f6f7fc;
  margin: 5px 0 20px;
`;
