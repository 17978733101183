import history from "../modules/history";
import { clearStore } from "./localstore";
import store from "../index";
import { setCash, setHashToPending, removeHashFromPending, setPromiseToStore } from "../modules/cashRedux/cashAction";
import { getFileAndName } from "helpers/getFileAndName";

import config from "config";
export default class Api {
  static current = process.env.REACT_APP_CURRENT;

  static url = config[Api.current].url;

  static origin = config[Api.current].origin;

  static is2faRequired(authData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/is2faEnabled`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(authData),
      });
      if (result.status === 200) {
        const c = await result.json();
        res(c);
      } else {
        rej(await result.text());
      }
    });
  }

  static login(authData, _2faCode = "") {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/user/login${_2faCode && "?code=" + _2faCode}`, {
        method: "POST",
        headers: {
          OriginVoC: Api.origin,
        },
        body: JSON.stringify(authData),
      });
      let token = result.headers.get("Authorization");
      if (result.status === 200 && token) {
        const user = await result.json();
        res({ token, user });
      } else {
        history.push("/");
        clearStore();
        rej(await result.text());
      }
    });
  }

  static getUserInfo(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/whoami`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        clearStore();
        window.location.href = "/";
        rej(await result.text());
      }
    });
  }

  static getUsers(
    token,
    role,
    page = 1,
    size = 10,
    keyword = "",
    sort = "",
    sortedParam = "",
    enabled = "",
    kyced = "",
    countries = [],
    days = ""
  ) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/admin/user/infos?page=${page}&size=${size}${
          role && role !== "ALL USERS" ? `&role=${role}` : ""
        }${keyword ? `&usernameOrFullName=${keyword}` : ""}&sort=${sort}&sortedParam=${sortedParam}${
          enabled ? `&enabled=${enabled}` : ``
        }${kyced ? `&kyced=${kyced}` : ``}${countries.length ? `&countries=${countries}` : ""}${
          days ? `&days=${days}` : ""
        }`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getUserFinancialInfo(token, username) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/financialInfo/${username}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getUserInvestedIn(token, username) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/investedIn/${username}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getAssets(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) {
        res(await result.json());
      } else rej(await result.text());
    });
  }

  static getTrades(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/trades`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static toggleAssetStatus(token, id, status) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/${status ? "deactivate" : "activate"}/${id}`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }

  static getAttachment(token, hash, file, returnBlob = false) {
    const storeBlob = store.getState().cashReducer.blobs[hash];
    const isPending = store.getState().cashReducer.pendingHash.includes(hash);
    return new Promise(async (res) => {
      if (hash && !storeBlob && !isPending) {
        store.dispatch(setHashToPending(hash));
        const result = await fetch(`${Api.url}/api/dataroom/getS3FileAttachmentByHashAsLink/${hash}/${file}`, {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        if (result.status === 200) {
          const s3link = await result.text();

          const blob = await fetch(s3link)
            .then((res) => res.blob())
            .then((b) => URL.createObjectURL(b))
            .catch(console.log);

          //return blob
          res(blob);

          //set in store
          store.dispatch(setCash({ secureHash: hash, blob }));

          //return blob for download
          if (returnBlob) return blob;
        } else {
          store.dispatch(removeHashFromPending(hash));
          res("");
        }
      } else if (isPending) {
        store.dispatch(setPromiseToStore({ resolveFc: res, secureHash: hash }));
      } else {
        res(hash ? storeBlob : "");
      }
    });
  }

  static getVisitorStatistic(token, id, days) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userviewstatistics/${id}/${days}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getDynamicChart(token, id, days, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userviewstatistics/getDynamicChart/${id}/${days}?type=${type}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getUserStatisticInfo(token, id, days, username) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userviewstatistics/${id}/${days}/${username}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getUsersLoginData(token, username) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userviewstatistics/lastLogins/${username}`, {
        headers: {
          OriginVoc: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) {
        try {
          res(await result.json());
        } catch (error) {
          res(null);
        }
      } else rej(await result.json());
    });
  }

  static getUsersList(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/infos?page=1&role=INVESTOR&size=1000`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static createGroup(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/create`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static updateGroup(token, data, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/edit/${id}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static duplicateGroup(token, name, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/duplicate/${id}/${name}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static deleteUserFromGroup(token, data, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/removeUsers/${id}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static addUserToGroups(token, data, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/addUserToGroups/${id}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static addUsersToGroup(token, data, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/addUsers/${id}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static getUserGroups(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/all`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.json());
      } else {
        await result.json();
        res([]);
      }
    });
  }

  static getGroupById(token, id) {
    return new Promise(async (res) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/group/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.json());
      } else {
        await result.json();
        res([]);
      }
    });
  }

  static getGroupByUserId(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/user/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.json());
      } else {
        await result.json();
        res([]);
      }
    });
  }

  static deleteGroupById(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/remove/${id}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static setMoney(token, name, amount, currency) {
    return new Promise(async (res) => {
      const result = await fetch(
        `${Api.url}/api/admin/wallet/issueCash?amount=${amount}&currency=${currency}&name=${name}`,
        {
          method: "POST",
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      res(result.status === 200);
      await result.text();
    });
  }

  static addSharesManualy(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/wallet/issueTokensManually`, {
        method: "POST",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static addSharesManualyDebt(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/wallet/issueTokensManuallyDebt`, {
        method: "POST",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getAsset(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getAssetInvestor(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getCategory(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/category`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getDealTypes(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/dealTypes`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getSponsors(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/sponsors`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static async createAsset(token, data) {
    for (let i = 0; data.assetProperties.images && i < data.assetProperties.images.length; i++) {
      if (
        !data.assetProperties.images[i].file ||
        typeof data.assetProperties.images[i].file !== "object" ||
        typeof data.assetProperties.images[i].file.name !== "string"
      ) {
        continue;
      }

      const formData = new FormData();
      formData.append("file", data.assetProperties.images[i].file);
      const attachment = await fetch(`${Api.url}/api/attachment/upload`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });

      if (attachment.status === 200) {
        data.assetProperties.images[i] = {
          ...data.assetProperties.images[i],
          ...(await attachment.json()),
        };
        delete data.assetProperties.images[i].file;
      } else {
        await attachment.text();
      }
    }

    return new Promise(async (res, rej) => {
      const asset = await fetch(`${Api.url}/api/admin/asset`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (asset.ok) res(await asset.text());
      else rej(await asset.json());
    });
  }

  static async updateAsset(token, data) {
    return new Promise(async (res, rej) => {
      const asset = await fetch(`${Api.url}/api/admin/asset/${data.linearId}`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (asset.status === 200) res(true);
      else rej(await asset.json());
    });
  }

  static getWallet(token, currency) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/wallet/balance${currency ? `?mainWalletCurrency=${currency}` : ""}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static sellShares(token, tokenId, valuation, currency, amountToRaise) {
    return new Promise(async (res, rej) => {
      const data = {
        valuation: valuation,
        currency: currency,
        amountToRaise: amountToRaise,
      };
      const result = await fetch(`${Api.url}/api/wallet/sale/${tokenId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getPossibleCurrencies(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/currencies`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static convertTokens(token, id, quantity, currency) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/convertTokens/${id}?quantity=${quantity}&currency=${currency}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getCashBalance(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/wallet/cashBalance`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getBankDetails(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/wallet/account`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json().catch(() => null));
      else rej(await result.json());
    });
  }

  static buyViaBank(token, assetId, currency, quantity, ibanAccountNumber) {
    const data = {
      assetId,
      currency,
      quantity,
      accountNumber: ibanAccountNumber,
    };
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/buyViaBank`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static buyOnline(token, assetId, currency, quantity) {
    const data = {
      assetId: assetId,
      currency: currency,
      quantity: quantity,
    };
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/buyOnline`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static addBankAccount(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/wallet/addBankAccount`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.ok) res("The bank account has been successfully added.");
      else rej(await result.json());
    });
  }

  static buyTradeOnline(tokenTxHash, token, tokenId, currency, quantity, seller, envelopeId, secretToken) {
    const data = {
      tokenTxHash: tokenTxHash,
      seller: seller,
      tokenId: tokenId,
      currency: currency,
      quantity: quantity,
    };
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/trades/buyOnline/${envelopeId}/${secretToken}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static buyTradeViaBank(
    tokenTxHash,
    token,
    tokenId,
    currency,
    quantity,
    seller,
    envelopeId,
    secretToken,
    accountNumber
  ) {
    const data = {
      tokenTxHash,
      seller,
      tokenId,
      currency,
      quantity,
      accountNumber,
    };
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/trades/buyViaBank/${envelopeId}/${secretToken}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getOrderHistory(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/transaction/user`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static buyTokenAgreement(
    tokenTxHash = null,
    token,
    assetOrTokenId,
    currency,
    quantity,
    seller = null,
    paymentType,
    accountNumber
  ) {
    let data;
    if (paymentType === "bankMarketplace") {
      data = {
        tokenTxHash,
        seller,
        tokenId: assetOrTokenId,
        currency,
        quantity,
        accountNumber,
      };
    } else if (paymentType === "onlineMarketplace") {
      data = {
        tokenTxHash,
        seller,
        tokenId: assetOrTokenId,
        currency,
        quantity,
      };
    } else if (paymentType === "bank") {
      data = {
        assetId: assetOrTokenId,
        currency: currency,
        quantity: quantity,
        accountNumber: accountNumber,
      };
    } else if (paymentType === "online") {
      data = {
        assetId: assetOrTokenId,
        currency: currency,
        quantity: quantity,
      };
    }
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/docusign/buyTokenAgreement/${paymentType}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getDocuSignInfo(token, envelopeId, tokenToBuy) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/docusign/doc/register?envelopeId=${envelopeId}&token=${tokenToBuy}`, {
        headers: {
          Authorization: token,
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getInvestorDocs(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/dataroom/investor/documents`, {
        headers: {
          Authorization: token,
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json().catch(() => []));
      else rej(await result.json());
    });
  }

  static getTransaction(token, type, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/transaction/${type}/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static downloadLegalDocument = async (token, fileId) => {
    return new Promise(async (res, rej) => {
      const requestFile = await fetch(`${Api.url}/api/dataroom/investor/documents/fileLink/${fileId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (requestFile.status === 200) res(await requestFile.text());
      else rej(await requestFile.json());
    });
  };

  static downloadDocumentBlob = async (token, fileId) => {
    return new Promise(async (res, rej) => {
      const requestFile = await fetch(`${Api.url}/api/dataroom/investor/documents/file/${fileId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (requestFile.status === 200) res(await requestFile.blob());
      else rej(await requestFile.json());
    });
  };

  static makeTransaction(token, sourceAccountNumber, destAccountNumber, description, amount) {
    return new Promise(async (res, rej) => {
      const data = {
        sourceAccountNumber,
        destAccountNumber,
        description,
        amount,
      };
      const result = await fetch(`${Api.url}/api/transactionIssuer`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.json().catch(() => "Success"));
      else rej(await result.json());
    });
  }

  static uploadAttachment(token, file) {
    return new Promise(async (res, rej) => {
      const formData = new FormData();
      formData.append("file", file);

      const result = await fetch(`${Api.url}/api/attachment/upload`, {
        method: "POST",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static uploadReport(token, file, id) {
    return new Promise(async (res, rej) => {
      const formData = new FormData();
      formData.append("file", file);

      const result = await fetch(`${Api.url}/api/admin/dataroom/admin/uploadReport/${id}`, {
        method: "POST",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static getUsersProgress(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dealWorkflow/userProgress/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getRegister(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dealWorkflow/register/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getAssetRounds(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/rounds/${assetId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200)
        res(
          await result.json().catch((e) => {
            return {};
          })
        );
      else rej(await result.json());
    });
  }

  static sendEmail(token, email, subject, message) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/sendEmail`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          recipient: email,
          subject: subject,
          text: message,
        }),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static registerInvestor(token, email, firstName, lastName, countryOfResidence, gender, invite) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/admin/registration/investor?email=${email}&firstName=${firstName}&lastName=${lastName}&countryOfResidence=${countryOfResidence}&gender=${gender}&invite=${invite}`,
        {
          method: "post",
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static registerInvestorInstitution(
    token,
    institutionId,
    email,
    firstName,
    lastName,
    countryOfResidence,
    gender,
    invite
  ) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/admin/registration/institutionInvestor/${institutionId}?email=${email}&firstName=${firstName}&lastName=${lastName}&countryOfResidence=${countryOfResidence}&gender=${gender}&invite=${invite}`,
        {
          method: "post",
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static registerAdmin(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/registration/admin`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.ok) res(true);
      else {
        rej(await result.json());
      }
    });
  }

  static registerSponsor(token, data, sendInvite = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/registration/sponsor?&sendInvite=${sendInvite}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.ok) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static updateSponsor(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/sponsor/edit`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.ok) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static createPassword(formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/registration/initial/changePassword`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
        },
        body: formData,
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getCreatePasswordToken(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/registration/initial/registrationConfirm?token=${token}`, {
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static changeAvatar(token, fileNameWithExtension, secureHash) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/changeAvatar`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileNameWithExtension: fileNameWithExtension,
          secureHash: secureHash,
        }),
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getUserMoreInfo(token, userName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/info/${userName}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getUsersTransactions(token, userName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/tx/${userName}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getAssetsList(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getNewRequests(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/registration/applies`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static registerUser(countryOfResidence, email, firstName, lastName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/registration/initial/sign-up?countryOfResidence=${countryOfResidence}&email=${email}&firstName=${firstName}&lastName=${lastName}`,
        {
          method: "post",
          headers: {
            OriginVoC: Api.origin,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static approveUser(token, email) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/registration/approve/${email}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.ok) res(await result.text());
      else rej(await result.text());
    });
  }

  static regectUser(token, email) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/registration/reject/${email}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.ok) res(await result.text());
      else rej(await result.text());
    });
  }

  static getDashboardQuickViewList(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dashboard/quickViewList2`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getDashboardQuickViewListSettings(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dashboard/quickViewListSettings`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getDashboardTransactions(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dashboard/transactions`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static editDashboardQuickViewList(token, id, isShow) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dashboard/editQuickViewList/${id}?isActive=${isShow}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static getMailingTemplates(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailing/templates`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getPersonalSettings(token, isPost, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailing/personalSettings`, {
        method: isPost ? "post" : "get",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: isPost && JSON.stringify(data),
      });
      if (result.status === 200) res((await isPost) ? result.text() : result.json());
      else rej(await result.json());
    });
  }

  static getMailConnection(token, isPost, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailConnection`, {
        method: isPost ? "post" : "get",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: isPost && JSON.stringify(data),
      });
      if (result.status === 200) {
        try {
          res(await result.json());
        } catch (error) {
          res(null);
        }
      } else rej(await result.json());
    });
  }

  static getTemplateSettings(token, template) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailing/template/${template}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static updateTemplateSettings(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailing/template`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static getKycStatuses(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/kyc/statuses`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static getRuleOptions(token) {
    return new Promise(async (res) => {
      const result = await fetch(`${Api.url}/api/admin/asset/ruleOptions`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        await result.text();
        res([]);
      }
    });
  }

  static getCountriesOption() {
    return new Promise(async (res) => {
      const result = await fetch(`${Api.url}/api/registration/initial/countries`, {
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        await result.text();
        res([]);
      }
    });
  }

  static getPendingTxInfo(token, tx) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/wallet/txInEscrow/${tx}`, {
        headers: {
          Authorization: token,
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static approveTransaction(token, paymentId, isReject = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/admin/wallet/payment/${isReject ? "reject" : "approve"}/${paymentId}`,
        {
          method: "post",
          headers: {
            Authorization: token,
            OriginVoC: Api.origin,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static update2FA(token, val) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/update2fa?enabled=${val}`, {
        method: "post",
        headers: {
          Authorization: token,
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static getLockedUpPeriods(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/lockUpTime`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej();
    });
  }

  static sendMessageToAM(token, message, assetId, attachments = {}) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/amChat/send`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message, assetId, attachments }),
      });
      if (result.status === 200) res(result.status);
      else rej(await result.text());
    });
  }

  static sendMessageToInvestor(token, receiver, message, assetId, attachments = {}) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/amChat/send`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ receiver, message, assetId, attachments }),
      });
      if (result.status === 200) res(result.status);
      else rej(await result.text());
    });
  }

  static updateChatMessages(token, messagePortion, showMessagesNumber, assetId, userName = "") {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/${userName && `admin/`}amChat/messages/${assetId}${
          userName && `/${userName}`
        }?messagePortion=${messagePortion}&showMessagesNumber=${showMessagesNumber}`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static sendMailsToInvestors(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/message/investors`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.text());
    });
  }

  static sendMailsToGroup(token, formData, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userGroups/sendEmail/${id}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.text());
    });
  }

  static sendMailsToInstitution(token, formData, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/institution/sendEmail/${id}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.text());
    });
  }

  static updateUnreadMessages(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/amChat/unread`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static getUserData(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/myInfo`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static changeUserData(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/changeData`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static changeUserPassword(token, data) {
    return new Promise(async (res, rej) => {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        formData.append([key], data[key]);
      });

      const result = await fetch(`${Api.url}/api/user/changePassword`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          ContentType: "multipart/form-data",
        },
        body: formData,
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static requestAssetMessage(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/amChat/messagesOverview`, {
        method: "get",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          ContentType: "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static setNetAssetVal(token, id, val) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/netAssetValue/${id}?valuation=${val}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getUnfinishedOrders(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/wallet/orders`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.text());
    });
  }

  static sayPaymentIsDone(token, code) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/wallet/orderPayed/${code}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static resetPassword(email) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/registration/initial/passwordRecovery/${email}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getXlsxTransactions(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dashboard/generateXlsxFile`, {
        cors: true,
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await getFileAndName(result));
      else rej(await result.json());
    });
  }

  static getXlsxUSers(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dealWorkflow/exportUserProgress/${assetId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await getFileAndName(result));
      else rej(await result.json());
    });
  }

  static getXlsxUsersRegister(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dealWorkflow/exportUserRegister/${assetId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await getFileAndName(result));
      else rej(await result.json());
    });
  }

  static getXlsxUsersStatisticsByUser(token, assetId, days, username) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/admin/userviewstatistics/exportUserStatisticsXLSX/${assetId}/${days}/${username}`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await getFileAndName(result));
      else rej(await result.json());
    });
  }

  static getXlsxUsersStatisticsAll(token, assetId, days) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/admin/userviewstatistics/exportUsersStatisticsXLSX/${assetId}/${days}`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) res(await getFileAndName(result));
      else rej(await result.json());
    });
  }

  static getXlsxAssetTransactions(token, assetId, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/transaction/${type}/${assetId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await getFileAndName(result));
      else rej(await result.json());
    });
  }

  static getXlsxUsersList(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/export`, {
        cors: true,
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await getFileAndName(result));
      else rej(await result.json());
    });
  }

  static getInvestorsMessages(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/amChat/investorsMessages`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static uploadChatFiles(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/amChat/attachment/upload`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        res(await result.json());
      } else rej(await result.json());
    });
  }

  static getChatFile(token, messageId, fileHash) {
    return new Promise(async (res) => {
      const result = await fetch(`${Api.url}/api/amChat/attachment/${messageId}/${fileHash}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        const blob = await result.blob();
        res(URL.createObjectURL(blob));
      } else {
        await result.text();
        res("");
      }
    });
  }

  static getUnfinishedDeals(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/assetUnfinished`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getUnfinishedDeal(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/assetUnfinished/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static saveDealAsUnfinished(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/assetUnfinished`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: JSON.stringify(data),
        }),
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static deleteUnfinishedDeal(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/assetUnfinished/${id}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static updateUnfinished(token, data, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/assetUnfinished/${id}`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: JSON.stringify(data),
        }),
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getPreSignDocument(token, assetId, intent = 0, additionalReplacementMap = {}, roundIdentifier) {
    return new Promise(async (res, rej) => {
      const options = {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      };
      if (additionalReplacementMap) options.body = JSON.stringify(additionalReplacementMap);
      const result = await fetch(
        `${Api.url}/api/workflow/prepareLD/${assetId}${
          intent && roundIdentifier ? `?intendedQuantityToBuy=${intent}&roundIdentifier=${roundIdentifier}` : ""
        }`,
        options
      );

      if (result.status === 200) {
        const blob = await result.blob();
        res({
          file: URL.createObjectURL(blob),
          fileName: result.headers.get("Content-Disposition").replace(/(^.+={1,1})|("+)/g, ""),
        });
      } else {
        rej(await result.json());
      }
    });
  }

  static getSignedDocument(token, assetId, useSignatureImage, additionalReplacementMap = {}) {
    return new Promise(async (res, rej) => {
      const options = {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      };
      if (additionalReplacementMap) options.body = JSON.stringify(additionalReplacementMap);
      const result = await fetch(
        `${Api.url}/api/workflow/prepareLDWithSignature/${assetId}?useSignatureImage=${useSignatureImage}`,
        options
      );

      if (result.status === 200) {
        const blob = await result.blob();
        res(URL.createObjectURL(blob));
      } else {
        rej(await result.json());
      }
    });
  }

  static approveSignedDocument(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/workflow/approve/${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getDigitalSignature(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/digitalSignature/image`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        const blob = await result.blob();
        res(URL.createObjectURL(blob));
      } else if (result.status === 409) {
        res(null);
      } else rej(await result.json());
    });
  }

  static deleteSignature(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/digitalSignature/image`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static replaceSignature(token, file) {
    return new Promise(async (res, rej) => {
      const formData = new FormData();
      formData.append("signatureImageFile", file);

      const result = await fetch(`${Api.url}/api/digitalSignature/image/replaceSignatureImage`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });

      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static addSignature(token, file) {
    return new Promise(async (res, rej) => {
      const formData = new FormData();
      formData.append("signatureImageFile", file);

      const result = await fetch(`${Api.url}/api/digitalSignature/image`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });

      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getDocusignLink(token, assetId, intention = 0) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/workflow/docusign/link/${assetId}${intention ? `?intendedQuantityToBuy=${intention}` : ""}`,
        {
          headers: {
            OriginVoC: Api.origin,
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static registerInstitution(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/registration/institution`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static getInstitutionById(token, id, fromInvestor = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/${fromInvestor ? "investor" : "admin"}/institution/ofInvestor/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getInstitutionInvested(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/institution/investedIn/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getInstitutionTransaction(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/institution/transactions/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getInstitutionAll(token, page, size = 10, sort = "") {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/institution?page=${page}&size=${size}&sort=${sort}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static changeLogoInstitution(token, id, fileData, fromInvestor = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/${fromInvestor ? "investor" : "admin"}/institution/changeLogo/${id}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(fileData),
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static updateInstitution(token, id, data, fromInvestor = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/${fromInvestor ? "investor" : "admin"}/institution/update/${id}`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static approveDocuSign(token, envelopeId, tokenToBuy) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/workflow/docusign/approve/${envelopeId}/${tokenToBuy}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static exchangeCurrency(token, baseCurrency, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/wallet/exchange/${baseCurrency}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static deleteInstitution(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/institution/${id}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static removeInvestor(token, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/registration/investor/delete/${userId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static getAssetReports(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dataroom/admin/reports/info/${assetId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.json());
      } else {
        rej(await result.json());
      }
    });
  }

  static getAssetLastReport(token, assetId, isInvestor = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/${isInvestor ? "api/dataroom" : "api/admin/dataroom/admin"}/reports/infoLastReport/${assetId}`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) {
        let lastReport;
        try {
          lastReport = await result.json();
        } catch (_) {
          lastReport = null;
        }
        res(lastReport);
      } else {
        rej(await result.json());
      }
    });
  }

  static sendAssetReport(token, assetId, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/reports/send/asset/${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) {
        res(await result.text());
      } else {
        rej(await result.json());
      }
    });
  }

  static sendUserReports(token, user, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/reports/send/user/${user}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) {
        res(await result.text());
      } else {
        rej(await result.json());
      }
    });
  }

  static getAssetActivity(token, assetId, days) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/admin/userviewstatistics/activityStatisticsByAsset/${assetId}/${days}`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) {
        res(await result.json());
      } else {
        rej(await result.json());
      }
    });
  }

  static getFinancialDealTypes(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/financialDealTypes`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.json());
      } else {
        rej(await result.json());
      }
    });
  }

  static getFilesViewStatistic(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dataroom/admin/statistics/getFileViewStatistics/${assetId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.json());
      } else {
        rej(await result.json());
      }
    });
  }

  static getQuestionAssetAllInv(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/qanda/investor/allQuestions/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getQuestionCategoriesInv(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/qanda/investor/categories/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getQuestionAnswer(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/qanda/admin`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getQuestionAnswerInv(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/qanda/investor`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getQuestionAssetAll(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/qanda/admin/allQuestions/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getQuestionCategories(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/qanda/admin/categories/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static delQuestionCategory(token, assetId, name) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/qanda/admin/deleteCategory/${assetId}/${name}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static createQuestionCategory(token, assetId, name) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/qanda/admin/createCategory/${assetId}?category=${name}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static deleteQuestion(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/qanda/admin/deleteQuestionById/${id}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static answerQuestion(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/qanda/admin/answerQuestion`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static updateAnswerQuestion(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/qanda/admin/editAnswer`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static askQuestion(token, assetId, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/qanda/investor/askQuestion/${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static deleteQuestionInv(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/qanda/investor/deleteQuestionById/${id}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getInvestorAnswers(token, page, size, status) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/admin/kyc/getUserAnswers?page=${page}&size=${size}&sort=DESC&status=${status}`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static onUserReadAnswer(token, questionId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/qanda/investor/markAnswerAsRead/${questionId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static registerUserNewForm(query) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/registration/initial/sign-up?${query}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static createNote(token, assetId, userName, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dealWorkflow/note/${assetId}/${userName}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static removeNote(token, assetId, userName, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dealWorkflow/note/${assetId}/${userName}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getKycRoles(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/rolesKyc`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static approveUserNew(token, email, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/registration/approve/${email}?institutionId=${id}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.ok) res(await result.text());
      else rej(await result.json());
    });
  }

  static getQuestionnaireAnswers(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/questionnaire/answers/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        try {
          res(await result.json());
        } catch (error) {
          res(null);
        }
      } else rej(await result.json());
    });
  }

  static readNotes(token, assetId, userName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dealWorkflow/noteRead/${assetId}/${userName}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getWorkflowSteps(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/workflowSteps/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static sendMailing(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/onBoard`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static sendMailingAllUsers(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/mailingUsers`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }
  static checkMailingBase(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailing/checkMailingList`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static registerUserPost(data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/registration/initial/sign-up-post`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static subscribeIntention(token, assetID, quantity, roundIdentifier) {
    return new Promise(async (res, rej) => {
      const data = {};
      const result = await fetch(
        `${Api.url}/api/workflow/subscription/${assetID}?intendedQuantityToBuy=${quantity}&roundIdentifier=${roundIdentifier}`,
        {
          method: "post",
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (result.status === 200) res(await result.text());
      else rej(await result.text());
    });
  }

  static getSCAnswers(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/selfcertification/userSCByUserId/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        try {
          res(await result.json());
        } catch (error) {
          res(null);
        }
      } else rej(await result.json());
    });
  }

  static getCCAnswers(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/clientcategorization/userCCByUserId/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        try {
          res(await result.json());
        } catch (error) {
          res(null);
        }
      } else rej(await result.json());
    });
  }

  static getAMSettings(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/investor/amSettings/getSettings`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getAllSignedDocuments(token, assetId, users) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/dataroom/signedLegalDocuments/${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(users),
      });
      if (result.status === 200)
        res({
          file: await result.blob(),
          fileName: result.headers.get("Content-Disposition")
            ? result.headers.get("Content-Disposition").replace(/(^.+={1,1})|("+)/g, "")
            : `Signed-documents.zip`,
        });
      else rej(await result.json());
    });
  }

  static getAccountDetails(token, userName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/getAccountDetails/${userName}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getSponsorAssets(token, userName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/sponsor/hasAssets/${userName}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static removeSponsor(token, userName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/sponsor/delete/${userName}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        method: "delete",
      });
      if (result.ok) res(true);
      else rej(await result.json());
    });
  }

  static getAdditionalPlaceholders(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/workflow/ldAdditionalPlaceholders/${assetId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getCompanyInfo() {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/freemium`, {
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej({});
    });
  }

  static getPlatformInfo(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/freemium/company`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej({});
    });
  }

  static getCompanyColorSchema() {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/freemium/colorScheme`, {
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      }).catch(() => {});
      if (result.status === 200) res(await result.json());
      else rej({});
    });
  }

  static getChatResponseTimes(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/getAssetChatResponseTimeOptions`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getUserViewStatistics(token, assetId, days) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/userviewstatistics/pro/${assetId}/${days}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      result.status === 200 ? res(await result.json()) : rej(await result.json());
    });
  }

  static resendInvite(token, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/registration/resendInvite/${userId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
      });
      result.ok ? res(await result.text()) : rej(await result.json());
    });
  }

  static setProfileInfo(token, profileInfo) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/admin/edit`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profileInfo),
      });
      result.status === 200 ? res("success") : rej(await result.json());
    });
  }

  static logOut(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/logout`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      result.status === 200 ? res(await result.json()) : rej(await result.json());
    });
  }

  static customizeEmail(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailing/htmlTemplate/customise`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getEmail(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailing/htmlTemplate/customise`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getDefaultMailSending(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailConnection/defaultEmail`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static setServiceInfo(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/service/registration/freemium`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      result.ok ? res("success") : rej(await result.json());
    });
  }

  static getAdminToken(authData, returnToken = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/user/login`, {
        method: "POST",
        headers: {
          OriginVoC: Api.origin,
        },
        body: JSON.stringify(authData),
      });
      if (result.status === 200) {
        let token = result.headers.get("Authorization");
        const { role } = await result.json();
        if (returnToken) {
          res(token);
          return;
        }
        if ((role === "AM" || role === "ADMIN") && token) res(true);
      } else {
        rej({ message: "The e-mail or password you entered is incorrect" });
      }
    });
  }

  static getAdminSettings(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/amSettings/getSettings`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static createSimpleTicket(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/ticketing/createSimple`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static setDemoStatus(token, status = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/demoMode/${status}`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
      });

      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static setDefaultEmail(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/mailConnection/setDefault`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static getBaseCompaniesInfo(token, signal) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/service/valkAdminPanel/baseNumberInfo`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        signal,
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static downloadBaseCompaniesInfo(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/service/valkAdminPanel/baseNumberInfoAsExcel`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await getFileAndName(result));
      else rej(await result.json());
    });
  }

  static resendCompanyInvite(token, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/service/valkAdminPanel/resendInviteFreemiumAm/${userId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static sendEmailToCompany(token, amId, formData, allAm = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/service/valkAdminPanel/${allAm ? "sendEmailToAdmins" : "sendEmailToAm"}/${amId}`,
        {
          method: "post",
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            ContentType: "multipart/form-data",
          },
          body: formData,
        }
      );
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getAssetBase(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/getAssetNameAndLogo/${assetId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getQaConnect(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/qanda/admin/allQuestionsForConnect/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static setAddress(token, address) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/user/addAdditionalInfo`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(address),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static getUserNegotiation(token, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/dataroom/admin/negotiationLD/activeNegotiations/${userId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static replaceBase64(token, assetProperty) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/replaceBase64ToUrl`, {
        method: "POST",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(assetProperty),
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static fiatToToken(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/asset/investment/fiatToToken`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.text());
      else rej(await result.json());
    });
  }

  static generateDealShareLink(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/generatePublicAccessLink`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.ok) res(await result.text());
      else rej(await result.json());
    });
  }

  static getWorkflowActions(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/workflowActions`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getWorkflowTemplate(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/workflow/template/${assetId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getDefaultWorkflow(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/workflowActions/sample`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getWorkflows(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/workflow/templates`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static setDefaultWorkflow(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/workflowActions/sample`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static updateDefaultWorkflow(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/workflowActions/sample`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static saveCurrentWorkflow(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/workflow/template`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static updateBankAcc(token, assetId, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/updateBankAccount/${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.ok) res("The bank account has been successfully added.");
      else rej(await result.json());
    });
  }

  static deleteDefaultWorkflow(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/workflowActions/sample/${id}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static getSignUpAnswers(token, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/questionnaire/answers/signUpPages/${userId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        try {
          res(await result.json());
        } catch (error) {
          res(null);
        }
      } else rej(await result.json());
    });
  }

  static getCompanyLoader() {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/freemium/loaderLogo`, {
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.text());
      else rej(false);
    });
  }

  static updateInvestor(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/user/investor/edit`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getOnboardingAnswers(token, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/onboarding/answers/${userId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static defaultFemaleAvatar = "/assets/defaultImages/avatar-female.svg";

  static defaultAvatar = "/assets/defaultImages/avatar.png";

  static defaultLogo = "/assets/defaultImages/companyLogo.png";

  static defaultDeal = "/assets/defaultImages/deal.png";

  static defaultNeitralAvatar = "/assets/defaultImages/other.svg";
}
