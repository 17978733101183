import styled, { keyframes } from "styled-components";
import React from "react";
import { useSelector } from "react-redux";

const scale = keyframes`
  30%{ background:#F3F6F9;width:180px;height:180px;}
  70%{ background:#F3F6F9;width:180px;height:180px;}
  90%{ background:#F3F6F9;width:180px;height:180px;}
`;

const scale1 = keyframes`
  40%{ background:rgba(243, 246, 249, 0.4);width:250px;height:250px;}
  70%{ background:rgba(243, 246, 249, 0.6);width:290px;height:290px;}
  90%{ background:rgba(243, 246, 249, 0.6);width:290px;height:290px;}
`;

const growth = keyframes`
  30% {opacity:1;width:25px;}
  40% {opacity:1;width:25px;}
  70% {opacity:1;width:50px;}
  90% {opacity:1;width:50px;}
  100%{opacity:0;width:50px;}
`;

const LoadCircle = styled.div`
  border-radius: 50%;
  width: ${({ size = 250 }) => `${size}px`};
  height: ${({ size = 250 }) => `${size}px`};
  background: ${({ bg = "rgba(243, 246, 249, 0.5)" }) => bg};
  display: flex;
  margin: auto;
  position: relative;
  animation: ${scale1} 3s linear infinite;
`;

const CirleMiddle = styled(LoadCircle)`
  position: relative;
  animation: ${scale} 3s linear infinite;
  > .svg-container {
    margin: auto;
    position: relative;
    z-index: 2;
    width: 80px;
    height: 80px;
    > svg {
      width: 80px;
      height: 80px;
    }
  }
`;

const UnderLayer = styled.div`
  position: absolute;
  animation: ${growth} 3s infinite forwards;
  z-index: 1;
  left: calc(50% - 25px);
  bottom: calc(50% - 25px);
  width: 0;
  height: 50px;
  background: #6280da;
`;

export const ValkLoader = () => {
  const svg = useSelector((s) => s.companyInfoReducer.loaderSvg);
  return (
    <LoadCircle bg="rgba(243, 246, 249, 0.4)">
      <CirleMiddle size={150} bg="#f3f6f94f">
        <UnderLayer />
        <div className="svg-container" dangerouslySetInnerHTML={{ __html: svg }} />
      </CirleMiddle>
    </LoadCircle>
  );
};
