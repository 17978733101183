import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation as useLocationRouter } from "react-router-dom";
import tawkTo from "tawkto-react";

import { setDeviceName, setOnboardingPassed } from "modules/userRedux/userAction";
import { updateInvestorNotifications, setDocuments, updateAdminNotifications } from "modules/dataRedux/dataAction";
import { setDataRoomView } from "modules/dataRoomRedux/dataRoomActions";
import {
  setAcceptedCurrency,
  setSponsors,
  setSponsorsFull,
  setDealTypes,
  setCategories,
  setRuleOptions,
  setCountriesOption,
  setLockUpTimes,
  setFinancialTemplates,
  setFinancialTypes,
  setWaitAsyncData,
} from "modules/asyncDataRedux/asyncDataAction";

import { requestToken } from "modules/notification";
import ApiNotification from "helpers/apiNotification";
import Api from "helpers/api";
import DataRoomApi from "data-room-panel/helpers/api";
import SetupPanelApi from "setup-panel/api";
import { clearStore } from "helpers/localstore";
import { countDocumentNotifications, logFc } from "helpers";
import { isStateUpdate } from "helpers/isStateUpdate";

import { ReactComponent as Twitter } from "assets/tw-inv.svg";
import { ReactComponent as LinkedIn } from "assets/linked.svg";
import { ReactComponent as Facebook } from "assets/fb.svg";
import { ReactComponent as WS } from "assets/ws.svg";
import { ReactComponent as Medium } from "assets/Medium.svg";
import { ReactComponent as Instagram } from "assets/Instagram.svg";

export function useDebounceState(initialValue, debounceTime = 450) {
  const [state, setState] = useState(initialValue);
  const debounceId = useRef(null);
  const _setState = useCallback((value) => {
    clearTimeout(debounceId.current);

    debounceId.current = setTimeout(() => {
      setState(value);
    }, debounceTime);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(debounceId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, _setState];
}

export const useLocation = () => {
  const [state, setState] = useState({
    protocol: "",
    host: "",
    port: "",
    pathname: "",
    href: "",
  });

  useEffect(() => {
    const locationChangeHandler = function () {
      try {
        const { protocol = "", host = "", port = "", pathname = "", href = "" } = window.location;
        const _state = { protocol, host, port, pathname, href };

        if (JSON.stringify(state) !== JSON.stringify(_state)) {
          setState(_state);
        }
      } catch (_) {}
    };
    locationChangeHandler();
    window.addEventListener("popstate", locationChangeHandler);

    return () => {
      window.removeEventListener("popstate", locationChangeHandler);
    };
  });

  return { ...state };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

export const useSearchInput = (initialValue, placeholder) => {
  const [defaultValue, setDefaultValue] = useDebounceState(initialValue);

  const reset = () => {
    setDefaultValue(initialValue);
  };

  const inputProps = {
    defaultValue,
    onChange: (e) => setDefaultValue(e.target.value),
    placeholder,
  };

  return [defaultValue, inputProps, reset];
};

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((state) => !state), []);

  return [state, toggle];
};

export const useDeviceName = () => {
  const dispatch = useDispatch();
  const deviceName = useSelector((state) => state.userReducer.deviceName);

  useEffect(() => {
    const handleResize = () => {
      const screen = window.innerWidth;
      const _deviceName = screen < 769 ? "mobile" : screen < 1025 ? "tablet" : "desktop";
      if (deviceName !== _deviceName) dispatch(setDeviceName(_deviceName));
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  });
};

export const useSocialLinks = () => {
  const { instagram, twitter, linkedin, medium, website, facebook } =
    useSelector((state) => state.companyInfoReducer.companyInfo) || {};

  const links = [
    {
      icon: <Instagram />,
      link: instagram,
    },
    {
      icon: <Twitter />,
      link: twitter,
    },
    {
      icon: <LinkedIn />,
      link: linkedin,
    },
    {
      icon: <Medium />,
      link: medium,
    },
    {
      icon: <WS />,
      link: website,
    },
    {
      icon: <Facebook />,
      link: facebook,
    },
  ].filter(({ link }) => link);

  return links;
};

//dummy flag to force component be updated))
export const useIncrement = (initVal = 0) => {
  const [state, setState] = useState(initVal);
  const onIncrement = useCallback(() => setState((state) => state + 1), []);

  return [state, onIncrement];
};

//useSearchParams - new URLSearchParams(object).toString()
//usePaginationState

export const useLogout = () => {
  const token = useSelector((s) => s.userReducer.token);
  const onLogout = useCallback(async () => {
    try {
      requestToken().then((tokenNotification) => {
        if (tokenNotification) ApiNotification.removeToken(token, tokenNotification).catch(logFc);
      });
      clearStore();
      window.location.replace(`${window.location.origin}/`);
    } catch (error) {}
    await Api.logOut(token);
  }, [token]);

  return onLogout;
};

export const useUpdateUnreadMessages = () => {
  const token = useSelector((s) => s.userReducer.token);
  const socketConnect = useSelector((s) => s.chatReducer.socketConnect);

  useEffect(() => {
    if (socketConnect) {
      Api.updateUnreadMessages(token);
    }
  }, [socketConnect, token]);
};

export const useInvestorNotifications = () => {
  const token = useSelector((s) => s.userReducer.token);
  const dispatch = useDispatch();

  const getNotifications = async () => {
    const documents = await Api.getInvestorDocs(token).catch(logFc);

    if (documents) {
      if (isStateUpdate("dataReducer", "documents", documents)) {
        dispatch(setDocuments(documents));
      }
    }

    const notifications = {
      Offerings: 0,
      Transactions: 0,
      Documents: countDocumentNotifications(documents),
      Wallet: 0,
      "Data Room": 0,
      "Q&A": 0,
    };
    dispatch(updateInvestorNotifications(notifications));
  };

  useEffect(
    () => {
      getNotifications();
    },
    // eslint-disable-next-line
    []
  );
};

export const useOnboardingStatus = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onPassQuestinnaire = useCallback(() => {
    dispatch(setOnboardingPassed());
    history.push("/investor/offerings/all");
  }, [dispatch, history]);

  return onPassQuestinnaire;
};

export const useCheckInvestorRole = () => {
  const history = useHistory();
  const { role, token } = useSelector((s) => s.userReducer);
  const { pathname } = useLocationRouter();

  useEffect(() => {
    if ((!token || (role && role !== "INVESTOR" && role !== "FACILITATOR")) && !pathname.includes("/createpassword")) {
      history.push("/");
    }
  });
};

export const useFirebaseToken = () => {
  const {
    token,
    userInfo: { deviceIds },
  } = useSelector((s) => s.userReducer);

  useEffect(
    () => {
      (async () => {
        try {
          if (deviceIds && token) {
            const deviceId = await requestToken();
            if (!deviceIds.includes(deviceId)) {
              ApiNotification.saveToken(token, deviceId);
            }
          }
        } catch (err) {
          console.log(err);
        }
      })();
    },
    // eslint-disable-next-line
    []
  );
};

export const useBlob = (file) => {
  const [blob, setBlob] = useState(null);
  const token = useSelector((s) => s.userReducer.token);

  const { fileNameWithExtension, secureHash } = file || {};

  useEffect(() => {
    if (fileNameWithExtension && secureHash && token) {
      Api.getAttachment(token, secureHash, fileNameWithExtension).then(setBlob).catch(logFc);
    }
  }, [fileNameWithExtension, secureHash, token]);

  return blob;
};

export const useAdminNotifications = () => {
  const token = useSelector((s) => s.userReducer.token);
  const dispatch = useDispatch();

  const getNotifications = async () => {
    const transactions = await Api.getDashboardTransactions(token).catch(logFc);
    const newRequests = await Api.getNewRequests(token).catch(logFc);
    const assets = await Api.getAssets(token).catch(logFc);
    const processingKyc = await Api.getInvestorAnswers(token, 1, 1000, "PROCESSING").catch(logFc);
    const waitingQuestions = await Api.getQuestionAnswer(token).catch(logFc);
    const dataRoomView = await DataRoomApi.getAllFiles(token).catch(logFc);

    dispatch(setDataRoomView(dataRoomView));

    const notifications = {
      Dashboard: transactions ? transactions.filter((t) => t.movementType === "PENDING").length : 0,
      Assets: assets && assets.NEW_DEAL ? assets.NEW_DEAL.filter((e) => !e.finalized).length : 0,
      Users: newRequests ? newRequests.length : 0,
      DataRoom: dataRoomView
        ? dataRoomView.reduce((acc, { negotiationsNotificationCount }) => {
            return acc + negotiationsNotificationCount;
          }, 0)
        : 0,
      KYC: processingKyc ? processingKyc.content.length : 0,
      Connect: null,
      Workflows: null,
      "Q&A": waitingQuestions
        ? waitingQuestions.reduce((total, e) => {
            return total + e.waitingQuestions;
          }, 0)
        : 0,
    };
    dispatch(updateAdminNotifications(notifications));
  };

  useEffect(
    () => {
      getNotifications();
    },
    // eslint-disable-next-line
    []
  );
};

export const useTawkTo = () => {
  const { REACT_APP_TAWK_KEY, REACT_APP_TAWK_ID } = process.env;
  useEffect(
    () => {
      try {
        !window.location.port && tawkTo(REACT_APP_TAWK_ID, REACT_APP_TAWK_KEY);
      } catch (_) {}
    },
    // eslint-disable-next-line
    []
  );
};

export const useCheckAdminRoles = () => {
  const history = useHistory();
  const { role, token } = useSelector((s) => s.userReducer);

  useEffect(() => {
    if (!token || (role && role !== "AM" && role !== "ADMIN" && role !== "SPONSOR")) {
      history.push("/");
    }
  });
};

export const useAsyncData = () => {
  const dispatch = useDispatch();
  const {
    acceptedCurrency,
    sponsors,
    sponsorsFull,
    dealTypes,
    categories,
    ruleOptions,
    countriesOption,
    financialDealTypes,
    lockUpTime,
    financialTemplates,
    waitAsyncData,
  } = useSelector((s) => s.asyncDataReducer);
  const token = useSelector((s) => s.userReducer.token);

  const getAsyncData = async () => {
    if (!acceptedCurrency.length) {
      const acceptedCurrency = await Api.getPossibleCurrencies(token);
      dispatch(setAcceptedCurrency(acceptedCurrency));
    }

    if (!sponsors.length || !sponsorsFull.length) {
      const sponsors = await Api.getSponsors(token);
      dispatch(setSponsors(sponsors.map(({ username }) => username)));
      dispatch(setSponsorsFull(sponsors));
    }

    if (!dealTypes.length) {
      const dealTypes = await Api.getDealTypes(token);
      dispatch(setDealTypes(dealTypes));
    }

    if (!categories.length) {
      const categories = await Api.getCategory(token);
      dispatch(setCategories(categories.map(({ name }) => name)));
    }

    if (!ruleOptions.length) {
      const ruleOptions = await Api.getRuleOptions(token);
      dispatch(setRuleOptions(ruleOptions));
    }

    if (!countriesOption.length) {
      const countriesOption = await Api.getCountriesOption(token);
      dispatch(setCountriesOption(countriesOption));
    }

    if (!lockUpTime.length) {
      const lockedUpPeriods = await Api.getLockedUpPeriods(token);
      dispatch(setLockUpTimes(lockedUpPeriods));
    }

    if (!financialDealTypes.length) {
      const financialTypes = await Api.getFinancialDealTypes(token);
      dispatch(setFinancialTypes(financialTypes));
    }

    if (!Object.keys(financialTemplates).length) {
      const financialTemplates = await SetupPanelApi.getFinancialTemplates(token);
      dispatch(setFinancialTemplates(financialTemplates));
    }

    dispatch(setWaitAsyncData());
  };
  useEffect(
    () => {
      waitAsyncData && getAsyncData();
    }, // eslint-disable-next-line
    []
  );
};
