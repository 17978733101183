import * as cash from "modules/cashRedux/cashAction";
import { handleActions } from "helpers/immer";

function initState() {
  return { pendingHash: [], blobs: {}, pendingPromises: {}, editorActive: false };
}

export const cashReducer = handleActions(
  {
    [cash.setCash]: (draftState, { payload }) => {
      const { secureHash, blob } = payload;
      draftState.blobs[secureHash] = blob;

      const pendingIndex = draftState.pendingHash.indexOf(secureHash);
      if (pendingIndex !== -1) {
        draftState.pendingHash.splice(pendingIndex, 1);
      }

      if (draftState.pendingPromises[secureHash] && draftState.pendingPromises[secureHash].length) {
        for (const resolve of draftState.pendingPromises[secureHash]) {
          try {
            resolve(blob);
          } catch (error) {
            console.log(error);
          }
        }
        delete draftState.pendingPromises[secureHash];
      }
    },
    [cash.setHashToPending]: (draftState, { payload }) => {
      draftState.pendingHash.push(payload);
    },
    [cash.removeHashFromPending]: (draftState, { payload }) => {
      const pendingIndex = draftState.pendingHash.indexOf(payload);
      if (pendingIndex !== -1) {
        draftState.pendingHash.splice(pendingIndex, 1);
      }
      if (draftState.pendingPromises[payload] && draftState.pendingPromises[payload].length) {
        delete draftState.pendingPromises[payload];
      }
    },
    [cash.setPromiseToStore]: (draftState, { payload }) => {
      const { resolveFc, secureHash } = payload;
      if (!draftState.pendingPromises[secureHash]) {
        draftState.pendingPromises[secureHash] = [];
      }
      draftState.pendingPromises[secureHash].push(resolveFc);
    },
    [cash.setEditorActive]: (ds, { payload }) => {
      ds.editorActive = payload;
    },
  },
  initState()
);
