import React, { useRef, useEffect, useState, Fragment } from "react";
import styled from "styled-components";

import { ContainerFlex, DocSelect, DocOptionContainer, DocInput } from "components/admin-layout/containers/container";
import { DocOptionsChips, DocChips, DocChipsWrapper, GroupName } from "components/admin-layout/forms/styled";
import SearchIcon from "assets/search-gray.svg";
import { HiddenLayout } from "components/admin-layout/avatar";

import theme from "models/themes";

const Label = styled.h6`
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
  margin: 0 0 12px;
`;

const Placeholder = styled.div`
  font-size: 12px;
  letter-spacing: -0.02em;
  color: #181818;
  align-self: center;
  margin-left: 20px;
  text-transform: none;
`;

export default ({
  onChange,
  width = "100%",
  label = "",
  placeholder,
  value,
  values = [],
  hiddenLabel,
  multiple = false,
  defaultValue,
  view = "default",
  disabled,
  labelFsize,
  fSize,
  selectHeight,
  isInvestor = false,
  padding,
  borderColor,
  token,
  oneOption = false,
  error = false,
  mb = null,
}) => {
  const wrapperRef = useRef(null);
  const [top, setTop] = useState(65);
  const [_values, _setValues] = useState([...values]);
  const [filterWord, setFilterWord] = useState("");

  const [selectedValues, setSelectedValues] = useState(Array.isArray(defaultValue) ? [...defaultValue] : []);

  useEffect(() => {
    if (wrapperRef.current) {
      const { height } = wrapperRef.current.getBoundingClientRect();
      setTop(height - 2);
    }
  }, [selectedValues]);

  useEffect(() => {
    _setValues(
      values.map((e) => {
        if (e && e.label && e.label.toLowerCase().includes(filterWord.toLowerCase())) {
          return e;
        } else {
          return null;
        }
      })
    );
  }, [values]);

  const onChangeFilter = (e) => setFilterWord(e.target.value || "");

  useEffect(() => {
    _setValues(
      values.map((e) => {
        if (e && e.label && e.label.toLowerCase().includes(filterWord.toLowerCase())) {
          return e;
        } else {
          return null;
        }
      })
    );
  }, [filterWord]);

  const uniqueId = useRef(Math.random().toString(36).replace("0.", ""));
  const currentSelect = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const onToggle = (e) => {
    e.stopPropagation();
    setOpen(!isOpen);
    const event = new CustomEvent("open_new", { uniqueId });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    function detectNewOpen(e) {
      if (e.uniqueId !== uniqueId.current && isOpen) {
        setOpen(false);
      }
    }

    function closeAll() {
      setOpen(false);
    }

    function _onChange(...e) {
      onChange && onChange(...e);
    }

    currentSelect.current && currentSelect.current.addEventListener("change", _onChange);

    window.addEventListener("open_new", detectNewOpen);
    window.addEventListener("click", closeAll);
    return () => {
      currentSelect.current && currentSelect.current.removeEventListener("change", _onChange);
      window.removeEventListener("open_new", detectNewOpen);
      window.removeEventListener("click", closeAll);
    };
  });

  const changeEmitMultiple = (val, selected) => (e) => {
    e.stopPropagation();
    if (oneOption) {
      if (selected) setSelectedValues([]);
      else {
        setSelectedValues([val]);
        onToggle(e);
      }
    } else {
      if (selected) {
        setSelectedValues(selectedValues.filter((e) => e !== val));
      } else {
        setSelectedValues([...selectedValues, val]);
      }
    }
  };

  useEffect(() => {
    multiple && onChange([...selectedValues]);
  }, [selectedValues]);

  const renderOptions = () => {
    return _values.map((value, i) => {
      if (value) {
        const { label, iconHash, iconName, image } = value;
        return (
          <DocOptionsChips
            key={`${label}-${i}`}
            selected={selectedValues.includes(i)}
            onClick={changeEmitMultiple(i, selectedValues.includes(i))}
            token={token}
            iconHash={iconHash}
            iconName={iconName}
            image={image}
          >
            <GroupName>{label}</GroupName>
          </DocOptionsChips>
        );
      }
      return <Fragment key={`empty-${i}`} />;
    });
  };

  return (
    /* <ContainerFlex w={width} overflow="visible" zIndex={isOpen ? 1001 : 0}> */
    <ContainerFlex w={width} overflow="visible" zIndex={isOpen ? 90 : 0}>
      {oneOption ? <Label>{label}</Label> : null}
      <DocChipsWrapper
        arrowPosition={14}
        padding={"0px"}
        ref={wrapperRef}
        isInvestor={isInvestor}
        labelFsize={labelFsize}
        asSelect={multiple}
        arrow={`/assets/chevron-down${theme.greyFilter ? "-dark" : ""}.svg`}
        hidden={hiddenLabel}
        onClick={disabled ? console.log : onToggle}
        disabled={disabled}
        error={error}
        mb={mb}
      >
        {selectedValues.length ? (
          selectedValues.map((i) => {
            const selectedItem = values[i];
            return (
              <DocChips
                key={`${label}-${i}`}
                onClick={changeEmitMultiple(i, selectedValues.includes(i))}
                token={token}
                iconHash={selectedItem.iconHash}
                iconName={selectedItem.iconName}
                image={selectedItem.image}
                disabled={disabled}
              >
                {selectedItem.label}
              </DocChips>
            );
          })
        ) : (
          <Placeholder>{placeholder || label}</Placeholder>
        )}
      </DocChipsWrapper>
      <DocSelect
        padding={padding}
        borderColor={borderColor}
        height={selectHeight}
        fSize={fSize}
        ref={currentSelect}
        multiple={multiple}
        hidden={multiple}
        arrow={`/assets/chevron-down${theme.greyFilter ? "-dark" : ""}.svg`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onClick={disabled ? console.log : onToggle}
        disabled={disabled}
      />
      <DocOptionContainer
        borderColor={borderColor}
        show={isOpen}
        top={oneOption ? 65 : top}
        height={view === "filter" ? (_values.length ? "250px" : "fit-content") : ""}
        offScroll={view === "filter"}
      >
        {view === "filter" ? (
          <Fragment>
            <DocInput
              onClick={(e) => e.stopPropagation()}
              placeholder="Search by name"
              icon={SearchIcon}
              width="100%"
              bgPos="left"
              leftPos="15px"
              padding="0 0 0 45px"
              margin="0 0 10px 0"
              onInput={onChangeFilter}
              value={filterWord}
              disabled={disabled}
            />
          </Fragment>
        ) : null}
        {view === "filter" ? (
          <DocOptionContainer paddingRight="0" show={isOpen} maxHeight="180px" offBorder full={false}>
            {renderOptions()}
          </DocOptionContainer>
        ) : (
          renderOptions()
        )}
        {isOpen ? <HiddenLayout onClick={onToggle} /> : null}
      </DocOptionContainer>
    </ContainerFlex>
  );
};
