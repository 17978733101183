import { createAction } from "redux-act";

import ApiInv from "../../data-room-panel/helpers/investorApi";
import ApiAdmin from "../../data-room-panel/helpers/api";
import { isStateUpdate, isDeepStateUpdate } from "../../helpers/isStateUpdate";

export const setLegalDocomentStatus = createAction("SET LEGAL DOCUMENT STATUS");
export const setInvestorView = createAction("SET INVESTOR MAIN FOLDERS VIEW");
export const setAdminView = createAction("SET ADMIN MAIN FOLDERS VIEW");
export const setInvDealFiles = createAction("SET INVESTOR DEAL FILES");
export const setAdmDealFiles = createAction("SET ADMIN DEAL FILES");
export const setLastCreatedFolder = createAction("SET LAST CREATED FOLDER NAME");
export const updateInvestorFolders = createAction("UPDATE_INVESTOR_FOLDERS");

export const setDocInfo = (info, cb) => async (dispatch) => {
  await dispatch(setLegalDocomentStatus(info));

  cb();
};

export const getInvFoldersView =
  (token, errorCb = console.log) =>
  async (dispatch) => {
    const view = await ApiInv.getDealsInfo(token).catch(errorCb);
    if (view) {
      if (isStateUpdate("dataRoomReducer", "foldersViewInvestor", view)) {
        dispatch(setInvestorView(view));
      }
    }
  };

export const getAdminFoldersView =
  (token, errorCb = console.log) =>
  async (dispatch) => {
    const view = await ApiAdmin.getAllFiles(token).catch(errorCb);
    if (view) {
      if (isStateUpdate("dataRoomReducer", "foldersViewAdmin", view)) {
        dispatch(setAdminView(view));
      }
    }
  };

export const getInvDealFiles =
  (token, assetId, errorCb = console.log) =>
  async (dispatch) => {
    const files = await ApiInv.getDealDocuments(token, assetId).catch(errorCb);
    if (files) {
      const data = { assetId, files };
      if (isDeepStateUpdate("dataRoomReducer", "investorDeals", assetId, files)) {
        dispatch(setInvDealFiles(data));
      }
    }
  };

export const getAdmDealFiles =
  (token, assetId, errorCb = console.log) =>
  async (dispatch) => {
    const files = await ApiAdmin.getDealsFiles(token, assetId).catch(errorCb);
    if (files) {
      const data = { assetId, files };
      if (isDeepStateUpdate("dataRoomReducer", "adminDeals", assetId, files)) {
        dispatch(setAdmDealFiles(data));
      }
    }
  };

export const setDataRoomView = (view) => async (dispatch) => {
  if (view) dispatch(setAdminView(view));
};

export const setSubfolders = (assetId, folderId, data) => (dispatch, getState) => {
  try {
    const dealsFolders = getState().dataRoomReducer.investorDeals[assetId].folders;
    const folders = JSON.parse(JSON.stringify(dealsFolders));
    let find = false;
    const findFolder = (arr = []) => {
      for (let item of arr) {
        if (item.id === folderId) {
          Object.keys(data).forEach((k) => {
            item[k] = data[k];
          });
          find = true;
          item.loaded = true;
          break;
        } else if (item.folders) {
          findFolder(item.folders);
        }
      }
    };
    findFolder(folders);
    if (find) {
      dispatch(updateInvestorFolders({ assetId, folders }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const setFileReviewStatus = (assetId, fileId, status) => (dispatch, getState) => {
  try {
    const dealsFolders = getState().dataRoomReducer.investorDeals[assetId].folders;
    const folders = JSON.parse(JSON.stringify(dealsFolders));

    let find = false;

    const findFile = (fileId, data = []) => {
      function checkFolder(folders) {
        for (let i = 0; i < folders.length; i++) {
          const file = folders[i].files.find((f) => f.id === +fileId);
          if (file) {
            file.status = "OPENED";
            if (status !== undefined) file.reviewed = status;
            find = true;
            break;
          } else checkFolder(folders[i].folders);
        }
      }
      checkFolder(data);
    };

    findFile(fileId, folders);
    if (find) {
      dispatch(updateInvestorFolders({ assetId, folders }));
    }
  } catch (error) {
    console.log(error);
  }
};
