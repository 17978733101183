export default {
  primary: "#6280DA",
  secondary: "#6876A0",
  tertiary: "#4FB2D1",
  primaryLight: "#bfdcfc",
  primaryMiddle: "#6781D4",
  primaryDark: "#6454A3",
  secondaryLight: "#949DB9",
  tertiaryLight: "#f3fcff",
  tertiaryMiddle: "#4fb2d1",
  headerInvestor: "#1f263e",
  footerInvestor: "#1f263e",
  navLink: "#ffffff4d",
  navLinkActive: "#fff",
  textPrimary: "#181818",
  textSecondary: "#18181899",
  textTertiary: "#545454",
  existedLabel: "#8A94B1",
  headerAdmin: "#192036",
  sideBarAdmin: "#1f263e",
  navLinkAdmin: "#b6b6b699",
  mainBgAdmin: "#F7F9FB",
  mainBgInvestor: "#f4f5f6",
  green: "#55B17C",
  greenInvestor: "#019D52",
  red: "#FF4343",
  grey: "#BDBDBD",
  emptyCardBg: "#f1f3f8",
  quoteBgInvestor: "#f3f6ff",
  documentBgInvestor: "#F3F6FF",
  infoButtonBg: "#f0f3fd",
  navLinkBorder: "#6280DA",
  activeChatBG: "#bfdcfc5e",
  chartBgFrom: "#6781d433",
  chartBgTo: "#6781d400",
  adminTableRowGrey: "#F9FAFD",
  sideBarSepLine: "#29314b",
  greyFilter: 0,
  blurBg: "#6280da26",
  inputsAutoFillBg: "#dceaf9",
  disabledField: "#faf9f9",
  previewHeader: "#191f34",
  heatMap: "#A52525",
  uploaderBg: "#F6F8FB",
  enterprice: "#FFCC01",
  authSidebar: "#1f263ec7",
};
