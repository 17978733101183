import { css } from "styled-components";

export const flexSize = css`
  flex: ${({ flex = 1 }) => flex};
`;

export const border4 = css`
  border-radius: 4px;
`;

export const shadow = css`
  border: 1px solid #f9f9f9;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
`;

export const fullVwVh = css`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

export const borderBox = css`
  box-sizing: ${({ boxSizing = "border-box" }) => boxSizing};
`;

export const muliFont = css`
  font-size: ${({ fontSize = 14 }) => `${fontSize}px`};
  line-height: ${({ lineHeight = 135 }) => `${lineHeight}%`};
  font-style: normal;
  font-weight: normal;
`;

export const textToDot = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const column = css`
  display: flex;
  flex-direction: column;
`;

export const scroll = css`
  height: ${({ h = "100%" }) => h};
  overflow-y: scroll;
  overflow-x: hidden;
  ${borderBox}
  ${column}
  padding: ${({ p = "15px 0 5px" }) => p};
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: ${({ barWidth = 8 }) => `${barWidth}px`};
    height: ${({ barWidth = 8 }) => `${barWidth}px`};

    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const cardBase = css`
  width: 100%;
  display: flex;
  ${(border4, muliFont, borderBox)}
  padding: ${({ p = "20px 25px" }) => p};
  margin-bottom: ${({ mb = "15px" }) => mb};
  height: ${({ h = "60px" }) => h};
  background: #fff;
`;

export const valkButton = css`
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth }) => (width ? `${width}px` : fullWidth ? "100%" : "94px")};
  border: 1px solid
    ${({ black, hide, error, green, red, fullGreen, grey, theme: { primary } }) =>
      black
        ? "#20263C"
        : grey
        ? "#8A8A8A"
        : fullGreen
        ? "#019D52"
        : hide
        ? "#CACACA"
        : red
        ? "#FF4343"
        : error
        ? "#FF4343"
        : green
        ? "#55B17C"
        : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({ black, white, hide, transparent, error, green, red, fullGreen, grey, theme: { primary } }) =>
    black
      ? "#20263C"
      : grey
      ? "#F9F9F9"
      : red
      ? "#FF4343"
      : white || hide
      ? "#fff"
      : transparent || error || green
      ? "transparent"
      : fullGreen
      ? "#019D52"
      : primary};
  color: ${({ white, hide, transparent, error, green, red, grey, theme: { primary } }) =>
    grey
      ? "#8A8A8A"
      : white || transparent
      ? primary
      : hide
      ? "#CACACA"
      : red
      ? "#FFFFFF"
      : error
      ? "#FF4343"
      : green
      ? "#55B17C"
      : "#fff"};
  font-size: ${({ fs = 10 }) => `${fs}px`};
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: ${({ br = 4 }) => `${br}px`};
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.1s linear;
  > svg {
    margin: ${({ rightIcon, onlyIcon = false }) => (rightIcon ? "0 0 0 6px " : onlyIcon ? 0 : "0 6px 0 0")};
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;
    width: ${({ svgSize = "9px" }) => svgSize};
    height: ${({ svgSize = "9px" }) => svgSize};
    path {
      stroke: ${({ transparent, error, green, grey, hide, theme: { primary } }) =>
        grey ? "#8A8A8A" : transparent ? primary : error ? "#FF4343" : green ? "#55B17C" : hide ? "#CACACA" : "#fff"};
    }
  }
`;

export const lastBorder = css`
  &:last-child {
    border: none;
  }
`;

export const tooltipStyle = css`
  &:hover {
    > .tooltip-info {
      display: block;
    }
  }
  > .tooltip-info {
    font-weight: normal;
    display: none;
    position: absolute;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 15px;
    width: 230px;
    bottom: 32px;
    left: -148px;
    font-size: 12px;
    line-height: 135%;
    color: #181818;
    z-index: 1000;
    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      z-index: 11;
      position: absolute;
      transform: rotate(45deg);
      bottom: -10px;
      left: calc(50% + 32px);
      background: #fff;
      border-bottom: 1px solid #efefef;
      box-sizing: border-box;
    }
    &::after {
      content: "";
      display: block;
      width: 30px;
      height: 15px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: calc(50% - 20px);
      z-index: 12;
    }
  }
`;

export const planLabel = css`
  ${borderBox}
  ${flexCenter}
  width:fit-content;
  display: flex;
  height: 19px;
  background: ${({ theme: { primary, enterprice }, pro, ent }) => (pro ? primary : ent ? enterprice : "#e3e3e3")};
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  padding: 0 6px;
  border-radius: 2px;
`;

export const transition = css`
  transition: all 0.3s linear;
`;
