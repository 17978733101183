import styled from "styled-components";
import React from "react";

import { File as FileIcon } from "components/common/icons-primary";
import { ReactComponent as DelIcon } from "../../../assets/cross-del.svg";
import { ReactComponent as Check } from "../../../assets/check_box.svg";
import { flexCenter, textToDot, column, borderBox } from "components/common/mixins";

// TODO exceptions
export const ContainerFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ row = false }) => (row ? "row" : "column")};
  justify-content: ${({ justify = "flex-start" }) => justify};
  align-items: ${({ align = "flex-start" }) => align};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
  width: ${({ w = "100%" }) => w};
  ${({ h = null }) => (h ? `height: ${h};` : "")}

  padding: ${({ paddingSize = 0 }) => paddingSize};
  margin: ${({ marginSize = 0 }) => marginSize};
  background: ${({ bg = "transparent" }) => bg};
  border-radius: ${({ borderRadius = 0 }) => borderRadius};

  ${({ border = null }) => (border ? `border: ${border};` : "")}
  overflow: ${({ overflow = "hidden" }) => overflow};

  z-index: ${({ zIndex = 0 }) => zIndex};
  transition: height 0.3s linear;
  ${borderBox}
  &::before {
    display: ${({ content }) => (content ? "block" : "none")};
    content: ${({ content }) => (content ? `'${content}'` : "")};
    width: 10px;
    height: 10px;
    bottom: 22px;
    left: 8px;
    color: black;
    position: absolute;
    z-index: 100;
  }
  .edit-field-icon {
    visibility: hidden;
  }
  &:hover {
    .edit-field-icon {
      visibility: visible;
    }
  }
`;

export const ShadowCard = styled(ContainerFlex)`
  border-radius: 4px;
  background: ${({ bg = "#fff" }) => bg};
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
  border-top: 1px solid transparent;
`;

export const ShadowCardHover = styled(ShadowCard)`
  transition: box-shadow 0.3s linear;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

  &:hover {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.125);
    cursor: pointer;
  }
`;

export const FromContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-direction: column;
  padding: 10px 0;
`;

export const CardNew = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 25px;
  ${column}
  margin-bottom: 30px;
  ${borderBox}
`;

export const DropZone = styled.form`
  position: relative;
  width: 100%;
  height: 64px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${borderBox}
`;

export const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const SingleImgItem = styled.div`
  position: relative;
  width: 180px;
  height: 120px;
  margin-bottom: 20px;
  margin-right: 20px;
  > svg {
    position: absolute;
    display: block;
    top: 8px;
    cursor: pointer;
  }
`;

export const DocBlock = styled.div`
  ${column}
  padding-bottom: 25px;
  border-bottom: 1px solid #efefef;
  ${borderBox}
  margin-bottom: 25px;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ noMargin }) => (noMargin ? "0" : "12px")};
`;

export const BtnBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width = "270px" }) => width};
  height: ${({ h = "42px" }) => h};
  background: ${({ white, theme: { quoteBgInvestor } }) => (white ? "#fff" : quoteBgInvestor)};
  border-radius: ${({ white }) => (white ? "4px" : "2px")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  margin-top: ${({ mTop = "" }) => mTop};
  border: ${({ white }) => (white ? "1px solid #EFEFEF" : "")};
  ${borderBox}
`;

export const FileNameBlock = styled.div`
  width: 224px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DocInput = styled.input.attrs(() => ({ "data-lvl-tooltip-decoration-front": true }))`
  background: ${({ errorWithBG }) => (errorWithBG ? "rgba(255, 67, 67, 0.05)" : "#fff")};
  height: ${({ height }) => (height ? `${height}px` : "42px")};
  width: ${({ width }) => (width ? width : "calc(100% - 300px)")};
  border: 1px solid
    ${({ error, errorWithBG, edit, theme: { primary } }) =>
      error || errorWithBG ? "#FF4343" : edit ? primary : "#efefef"};
  ${borderBox}
  border-radius: 4px;
  outline: none;
  padding: ${({ padding }) => padding || "0 20px"};
  margin: ${({ margin = 0 }) => margin};

  font-weight: normal;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "12px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  position: relative;
  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator,
  &[type="date"]::-webkit-clear-button,
  &[type="time"]::-webkit-clear-button {
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none !important;
  }
  &[type="time"]::-webkit-inner-spin-button,
  &[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  background-image: ${({ icon }) => `url(${icon})`};
  background-position: ${({ bgPos = "right" }) => bgPos};
  background-position-x: ${({ arrowPosition, leftPos }) => {
    if (leftPos) return leftPos;
    return arrowPosition ? `calc(100% - ${arrowPosition}px)` : "calc(100% - 24px)";
  }};

  background-size: 16px;
  background-repeat: no-repeat;
  &::placeholder {
    color: rgba(24, 24, 24, 0.3);
  }
  &:disabled {
    background: ${({ errorWithBG = false, theme: { disabledField } }) =>
      errorWithBG ? "rgba(255, 67, 67, 0.05)" : disabledField};
  }
  @media (max-width: 768px) {
    height: 36px;
    font-size: 12px;
    padding: 0 12px;
  }
`;

export const Cont = styled.div`
  width: ${({ width }) => width};
  display: flex;
  position: relative;
  flex-direction: column;
  margin: ${({ margin = 0 }) => margin};
  &::before {
    z-index: 82;
    display: ${({ pseudo }) => (pseudo ? "block" : "none")};
    content: ${({ pseudoText }) => `"${pseudoText}"`};

    font-style: normal;
    font-weight: normal;
    font-size: ${({ pseudoSize }) => (pseudoSize ? `${pseudoSize}px` : "12px")};
    line-height: 135%;
    text-align: right;
    letter-spacing: -0.02em;
    width: ${({ isInvestor }) => (isInvestor ? "auto" : "25px")};
    color: rgba(24, 24, 24, 0.3);
    right: 20px;
    position: absolute;
    bottom: ${({ isInvestor }) => (isInvestor ? "12px" : "14px")};
    @media (max-width: 768px) {
      bottom: 8px;
    }
  }
  &::after {
    display: ${({ after }) => (after ? "block" : "none")};
    content: ${({ afterText }) => `"${afterText}"`};
    position: absolute;
    right: 0;
    top: 0;

    font-style: normal;
    font-weight: bold;
    font-size: ${({ labelFSize }) => (labelFSize ? `${labelFSize}px` : "12px")};
    line-height: 135%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(24, 24, 24, 0.3);
  }
`;

export const InfoBlock = styled.div`
  background: ${({ theme: { disabledField } }) => disabledField};
  border: 1px solid #efefef;
  ${borderBox}
  border-radius: 4px;
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
`;

export const DocTextArea = styled.textarea`
  min-height: ${({ areaHeight }) => (areaHeight ? `${areaHeight}px` : "106px")};
  height: ${({ areaHeight }) => `${areaHeight}px`};
  width: ${({ width }) => (width ? width : "100%")};
  border: 1px solid ${({ error }) => (error ? "#ff4343" : "#efefef")};
  ${borderBox}
  border-radius: 4px;
  outline: none;
  padding: 11px 20px;

  font-weight: normal;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  resize: none;
  &::placeholder {
    color: rgba(24, 24, 24, 0.3);
  }
`;

export const DocSelect = styled.select`
  ${({ hidden }) => {
    if (hidden) return "display: none!important;";
    return "display: block!important;";
  }}
  position: relative;
  height: ${({ height }) => (height ? `${height}px` : "42px")};
  width: ${({ width }) => (width ? width : "100%")};
  border: 1px solid
    ${({ borderColor = "#efefef", error, edit, theme: { primary } }) =>
      error ? "#FF4343" : edit ? primary : borderColor};
  ${borderBox}
  appearance: none;
  border-radius: ${({ br = "4px" }) => br};
  outline: none;
  padding: ${({ padding }) => (padding ? padding : "0 20px")};

  font-weight: normal;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "12px")};
  /* line-height: 135%; */
  letter-spacing: -0.02em;
  color: ${({ error, theme: { red } }) => (error ? red : "#181818")};
  background: #fff;
  background-image: ${({ arrow }) => `url(${arrow})`};
  background-position: right;
  background-position-x: ${({ arrowPosition }) =>
    arrowPosition ? `calc(100% - ${arrowPosition}px)` : "calc(100% - 14px)"};
  background-position-y: ${({ noLabel }) => (noLabel ? "13px" : "auto")};
  background-size: 16px;
  background-repeat: no-repeat;
  &::placeholder {
    color: rgba(24, 24, 24, 0.3);
  }
  &:disabled {
    background: ${({ errorWithBG = false, theme: { disabledField } }) =>
      errorWithBG ? "rgba(255, 67, 67, 0.05)" : disabledField};
    opacity: 1;
  }
  @media (max-width: 320px) {
    height: 36px;
    font-size: 12px;
  }
`;

export const DocOptionContainer = styled.div.attrs(() => ({ "data-lvl-tooltip-decoration-front": true }))`
  position: absolute;
  display: ${({ show }) => (show ? "block" : "none")};

  ${({ offScroll, full = true }) => {
    if (full) return `width: 100%;`;

    if (offScroll) {
      return `width: 100%;`;
    } else {
      return `width: calc(100% - 40px);`;
    }
  }}
  background: #ffffff;
  ${borderBox}

  ${({ offBorder, borderColor = "#efefef", padding = "12px 20px", paddingRight = "20px" }) => {
    if (offBorder) {
      return `
      padding-right: ${paddingRight};
      padding-bottom: 12px;`;
    } else {
      return `
      border: 1px solid ${borderColor};
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
      padding: ${padding};
      `;
    }
  }}

  border-radius: 0 0 4px 4px;
  top: ${({ top }) => (top ? `${top}px` : "65px")};
  max-height: ${({ maxHeight = "250px" }) => maxHeight};
  overflow-y: ${({ offScroll }) => (offScroll ? "hidden" : "auto")};

  ${({ height = "" }) => {
    if (height) return `height: ${height};`;
    else return "";
  }}

  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const DocOptionsBlock = styled.div`
  font-size: 12px;
  color: #181818;
  cursor: pointer;
  text-transform: none;
  padding: ${({ padding = "0px" }) => padding};
  background: ${({ bg = "transparent" }) => bg};
  border-radius: ${({ radius = 0 }) => radius};
  display: flex;

  opacity: ${({ selected }) => (selected ? 0.4 : 1)};
  pointer-events: ${({ selected }) => (selected ? "none" : "auto")};
  position: relative;
  user-select: none;
  &:after {
    display: ${({ pseudo = "" }) => (pseudo ? "block" : "none")};
    content: " (${({ pseudoContent }) => pseudoContent})";
    margin-left: 5px;
    color: #55b17c;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${({ disabled }) => {
    if (disabled) {
      return `
        color: rgba(24, 24, 24, 0.3);
        pointer-events: none;
      `;
    }
    return "";
  }}

  &:hover {
    ${({ selected = false, theme: { primary } }) => {
      if (selected) return "color: #181818;";
      else return `color:${primary} ;`;
    }}
  }
  @media (max-width: 768px) {
    padding: 0px;
    font-size: 10px;
  }
`;

const CheckboxCustom = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ selected, theme: { primary } }) => (selected ? primary : "#EFEFEF")};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  ${borderBox}
  border-radius: 2px;
  display: flex;
  ${flexCenter}
  margin-right: 15px;
`;

export const DocOptionsCheckbox = ({ children, onClick, selected }) => {
  return (
    <DocOptionsBlock onClick={onClick}>
      <CheckboxCustom selected={selected}>{selected ? <Check /> : null}</CheckboxCustom>
      {children}
    </DocOptionsBlock>
  );
};

export const DocOptions = styled.option`
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  ${({ hidden }) => (hidden ? "display: none;" : "")};
  &:disabled {
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const DateWrapper = styled.div`
  width: ${({ width }) => width};
  ${column}
  justify-content: space-between;
`;

export const ImageWrapper = styled.div`
  height: 82px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: 1px solid #efefef;
  ${borderBox}
  border-radius: 4px;
  width: 100%;
`;

export const Column = styled.div`
  ${column}
  margin-right: 18px;
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const NameCont = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 6px;
    width: 16px;
  }
`;

const Name = styled.div`
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  ${textToDot}
  display: block;
  max-width: 185px;
`;

export const StatusBlock = styled.div`
  ${borderBox}
  width: 240px;
  height: 42px;
  background: ${({ bg }) => (bg ? bg : "#019d52")};
  border-radius: 2px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #ffffff;
  text-transform: none;
`;

export const FileBlock = ({ name, onDelete }) => {
  return (
    <FileNameBlock>
      <NameCont>
        <FileIcon />
        <Name> {name}</Name>
      </NameCont>
      <DelIcon style={{ cursor: "pointer" }} onClick={onDelete} />
    </FileNameBlock>
  );
};
