import { createAction } from "redux-act";

import { isGeneral } from "helpers/envChecker";
import { isHTML } from "helpers";
import config from "config";
import Api from "helpers/api";

import theme from "models/themes";

const current = process.env.REACT_APP_CURRENT;

export const setCompanyInfo = createAction("SET_COMPANY_INFO");
export const setLoaderSvg = createAction("SET_LOADER_SVG");

export const getCompanyInfo = () => async (dispatch) => {
  const link = document.getElementById("favicon");
  link.setAttribute("href", config[current].favicon || "/favicon.png");
  if (!isGeneral) {
    document.title = config[current].title || "Asset Manager";
    link.setAttribute("href", config[current].favicon || "/favicon.png");
  } else {
    const company = await Api.getCompanyInfo();
    const companyColorSchema = await Api.getCompanyColorSchema().catch(() => theme);
    try {
      for (let key of Object.keys(companyColorSchema)) {
        theme[key] = companyColorSchema[key];
      }
    } catch (error) {
      console.log(error);
    }
    document.title = company.companyName;
    link.setAttribute("href", company.favicon || "/favicon.png");
    dispatch(setCompanyInfo({ companyInfo: company, theme: companyColorSchema }));
  }
};

export const getLoader = () => async (dispatch) => {
  const loader = await Api.getCompanyLoader().catch(console.log);
  const defaultLoader = `<svg width="156" height="156" viewBox="0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M78 156C121.078 156 156 121.078 156 78C156 34.9218 121.078 0 78 0C34.9218 0 0 34.9218 0 78C0 121.078 34.9218 156 78 156ZM83.719 113L113 47H98.634L78.1373 94.9242L57.8235 47H43L72.281 113H83.719Z"
    fill="white"
  />
</svg>`;

  dispatch(setLoaderSvg(loader && isHTML(loader) ? loader : defaultLoader));
};
