import * as setup from "./setupActions";
import { handleActions } from "helpers/immer";

const initialState = () => ({
  currentStep: "EMAIL_SETTINGS", // CREATE_DATA_ROOM_FOR_ASSET_BUTTON_MESSAGE SIGN_CONTRACT WEBSITE_SETUP EMAIL_SETTINGS
});

export const setupReducer = handleActions(
  {
    [setup.setStep]: (draftState, { payload }) => {
      draftState.currentStep = payload;
    },
  },
  initialState()
);
