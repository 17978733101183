import Api from "helpers/api";

export default class SetupPanelApi {
  static url = Api.url;

  static origin = Api.origin;

  static getStep(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${SetupPanelApi.url}/api/freemium/stepper/getSteps`, {
        headers: {
          OriginVoC: SetupPanelApi.origin,
          Authorization: token,
          "Content-type": "application/json",
        },
      });
      if (result.status === 200) {
        try {
          res(await result.json().catch(console.log));
        } catch (_) {
          res(null);
        }
      } else rej(await result.json());
    });
  }

  static setStep(token, step, done = true, skip = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${SetupPanelApi.url}/api/freemium/stepper/setStep`, {
        method: "post",
        headers: {
          OriginVoC: SetupPanelApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: step,
          done,
          skip,
        }),
      });
      result.status === 200 ? res(true) : rej(await result.json());
    });
  }

  static updateCompanyInfo(token, inputModel) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${SetupPanelApi.url}/api/freemium/updateCompany`, {
        method: "put",
        headers: {
          OriginVoC: SetupPanelApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputModel),
      });
      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }

  static getFinancialTemplates(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${SetupPanelApi.url}/api/admin/financialInformationTemplates`, {
        headers: {
          OriginVoC: SetupPanelApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      result.status === 200 ? res(await result.json()) : rej(await result.json());
    });
  }

  static setBasicTemplate(token, templateModel) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${SetupPanelApi.url}/api/admin/financialInformationTemplates`, {
        method: "post",
        headers: {
          OriginVoC: SetupPanelApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(templateModel),
      });
      result.status === 200 ? res("success") : rej(await result.json());
    });
  }

  static setOwnTemplate(token, name, templateModel) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${SetupPanelApi.url}/api/admin/financialInformationTemplates/addOwnTemplate`, {
        method: "post",
        headers: {
          OriginVoC: SetupPanelApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ [name]: JSON.stringify(templateModel) }),
      });
      result.status === 200 ? res(true) : rej(await result.json());
    });
  }

  static setSetupPassedAndRemoveStepper(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${SetupPanelApi.url}/api/freemium/stepper/setPassssed`, {
        method: "post",
        headers: {
          OriginVoC: SetupPanelApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      result.ok ? res(true) : rej(await result.json());
    });
  }

  static setSetupPassedFalse(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${SetupPanelApi.url}/api/freemium/stepper/setPassssedInFalse`, {
        method: "post",
        headers: {
          OriginVoC: SetupPanelApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      result.ok ? res(true) : rej(await result.json());
    });
  }

  static createStepper(token, amName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${SetupPanelApi.url}/api/freemium/stepper/createStepper/${amName}`, {
        method: "post",
        headers: {
          OriginVoC: SetupPanelApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      result.ok ? res(true) : rej(await result.json());
    });
  }

  static uploadAttachment = Api.uploadAttachment;
}
