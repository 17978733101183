import React, { Component, Fragment, lazy } from "react";

import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import "./App.css";
import { getUserInfo } from "./modules/userRedux/userAction";
import { getCompanyInfo, getLoader } from "modules/companyInfoRedux/companyInfoActions";
import history from "./modules/history";
import { PortalLoader } from "./components/investor-layout/ActionMessages";
import { ErrorMessagePortal } from "components/admin-layout/errorMessage";

import { NotFound } from "./pages/NotFound";
import { isGeneral } from "helpers/envChecker";
import { Loader } from "auth-panel/styled";
import { factorySingleTon } from "./helpers/interceptor";
import theme from "models/themes";

const AuthPlatform = lazy(() => import("auth-panel/auth.app"));
const AdminPlatform = lazy(() => import("admin-panel/admin.app"));
const InvestorPlatform = lazy(() => import("investor-panel/investor.app"));
const SetupPlatform = lazy(() => import("setup-panel/setup.app"));

class App extends Component {
  interceptor = null;
  state = { messageError: "" };

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.token &&
      (nextProps.role === "AM" || nextProps.role === "ADMIN") &&
      this.props.role === "" &&
      window &&
      window.location &&
      !window.location.href.includes("admin") &&
      !window.location.href.includes("createpassword") &&
      !window.location.href.includes("/info/asset/")
    ) {
      history.push("/admin/full-dashboard");
    } else if (
      nextProps.token &&
      (nextProps.role === "INVESTOR" || nextProps.role === "FACILITATOR") &&
      this.props.role === "" &&
      window &&
      window.location &&
      !window.location.href.includes("investor") &&
      !window.location.href.includes("createpassword") &&
      !window.location.href.includes("/info/asset/")
    ) {
      history.push("/investor/offerings/all");
    }
    return true;
  }

  componentDidMount() {
    this.props.getLoader();

    this.props.getCompanyInfo();

    if (this.props.token) this.props.getUserInfo(this.props.token);

    this.interceptor = factorySingleTon(
      ["auth", "login", "is2faEnabled", "createpassword", "freemium/colorScheme", "/api/public/asset/"], // игнорировать путь если в нем есть одно из слов (403)
      [".png", ".jpg"], // игнорировать путь если в нем есть одно из слов (500)
      ["createpassword", "freemium/colorScheme"], // игнорировать все запросы с любым статусом на 403
      [], // игнорировать путь если в нем есть одно из слов (404)
      // функция вернет текст ответа если сможет  this.setState({ messageError })
      (messageError, url, pageUrl) => {
        console.log(messageError, url, pageUrl);
        if (pageUrl.includes("setup") && !pageUrl.includes("comunication-setup")) {
          this.setState({ messageError });
        }
      }
    );
  }

  render() {
    const { messageError } = this.state;

    if (!this.props.loaderSvg) return null;
    else if (isGeneral && !this.props.companyInfo) return <Loader />;
    else
      return (
        <ThemeProvider theme={this.props.theme || theme}>
          <Fragment>
            {this.props.isAppNotReady ? <PortalLoader /> : null}
            {messageError ? (
              <ErrorMessagePortal
                er={{ message: messageError, error: "Error" }}
                onClose={() => this.setState({ messageError: "" })}
              />
            ) : null}
            <Switch>
              <Route path="/admin" component={AdminPlatform} />
              <Route path="/investor" component={InvestorPlatform} />
              <Route path="/setup" component={SetupPlatform} />
              <Route path="/" component={AuthPlatform} />
              <Route component={NotFound} />
            </Switch>
          </Fragment>
        </ThemeProvider>
      );
  }
}

function mapStateToProps({
  userReducer: { token, role, isAppNotReady },
  companyInfoReducer: { companyInfo, theme, loaderSvg },
}) {
  return {
    token,
    role,
    isAppNotReady,
    companyInfo,
    theme,
    loaderSvg,
  };
}

export default connect(mapStateToProps, { getUserInfo, getCompanyInfo, getLoader })(App);
