import { requestToken } from "modules/notification";
import { clearStore } from "./localstore";
import ApiNotification from "./apiNotification";

export async function checkVersion(token) {
  if (process.env.REACT_APP_BUILD_DATE) {
    if (localStorage.getItem("last-version") !== process.env.REACT_APP_BUILD_DATE) {
      requestToken().then((tokenNotification) => {
        if (tokenNotification) ApiNotification.removeToken(token, tokenNotification).catch(console.log);
      });
      clearStore();
      localStorage.setItem("last-version", process.env.REACT_APP_BUILD_DATE);

      window.location.replace(`${window.location.origin}/`);
    }
  }
}
