import logo from "assets/logos/asset-manager-logo.svg";

export default {
  origin: "",
  url: "https://valk-api-prod.cf",
  logo: logo,
  logoStyles: {
    auth: `width: 310px;height: 42px;margin-bottom: 30px;> img {width: 100%;}`,
    admin: ``,
    investor: `>img{@media(max-width:1280px){width:160px}}`,
  },
};
